import {
  enhancedApi,
  useBillingActionCredit,
  useBillingActionDebit,
} from 'shared/api/services/billing/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Баланс обновлен';
const ERROR_MSG = 'Не удалось обновить баланс';

type Props = {
  closeModal: () => void;
};

export const useManageBalance = ({ closeModal }: Props) => {
  const [creditTrigger, { isLoading: isCreditLoading }] =
    useBillingActionCredit();
  const [debitTrigger, { isLoading: isDebitLoading }] = useBillingActionDebit();

  const dispatch = useAppDispatch();

  const handleManage = async ({
    data,
    balanceId,
    userId,
  }: {
    data: FormOutput;
    balanceId: string;
    userId: string;
  }) => {
    const { type, value, ...rest } = data;

    try {
      const req = {
        balanceId,
        value,
      };

      if (type === 'credit') {
        await creditTrigger(req).unwrap();
      } else {
        await debitTrigger(req).unwrap();
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BillingBalances', id: userId },
        ])
      );

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return {
    handleManage,
    isLoading: isCreditLoading || isDebitLoading,
  };
};
