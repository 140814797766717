export const ROOT = 'root';

export const EDIT = 'edit';

export const SESSIONS = 'sessions';
export const RESERVES = 'reserves';
export const PERMISSIONS = 'rights';
export const STATUSES = 'statuses';
export const EVENTS = 'events';
export const MANUAL_COMMANDS = 'manual-commands';
export const GROUPS = 'groups';
export const CHARGE_POINTS = 'charge-points';
export const PUSH_NOTIFICATIONS = 'push-notifications';
export const MOBILE_APPS = 'mobile-apps';
export const HUBS = 'hubs';
export const COMPANIES = 'companies';
export const COUNTRIES = 'countries';
export const BANKS = 'banks';
export const CPOS = 'cpos';
export const FIRMWARES = 'firmwares';
export const BILLINGS = 'billings';
export const USERS = 'users';
export const BONUSES = 'bonuses';
export const TRANSACTIONS = 'transactions';
export const SUPPORTS = 'supports';
export const CAR_BRANDS = 'car-brands';
export const CAR_MODELS = 'models';
export const DISCOUNTS = 'discounts';
export const OCPI_HUBS = 'ocpi-hubs';
export const REPORTS = 'reports';

export const IDENTITY_USERS = 'users';
export const IDENTITY_GROUPS = 'groups';
export const IDENTITY_ROLES = 'roles';

export const CHARGE_POINT_RIGHTS = 'charge-point-rights';
export const CONTROLLER_CLAIMS = 'controller-claims';

export const ADD_PREFIX = 'create';

export const SETTINGS = 'settings';
export const SECURITY = 'security';
