import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getIdentity } from '../../orval/axios/client';
import { enhancedApi as api } from '../generated';

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      // Юзеры
      getApiIdentityV1Users: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      getApiIdentityV1UsersByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUsers', id: arg },
        ];
      },
      postApiIdentityV1Users: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      patchApiIdentityV1Users: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityUsers', id: 'LIST' },
          {
            type: 'IdentityUsers',
            id: arg.userName,
          },
        ];
      },
      deleteApiIdentityV1UsersByUserName: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      //
      getApiIdentityV1UserRolesByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUserRoles', id: arg },
        ];
      },
      getApiIdentityV1UserRightsByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUserRights', id: arg },
        ];
      },
      postApiIdentityV1SetUserRights: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityUserRights', id: arg.userName },
        ];
      },
      //
      // Роли
      //
      getApiIdentityV1Roles: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      getApiIdentityV1RolesByRoleName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityRoles', id: arg },
        ];
      },
      postApiIdentityV1Roles: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      patchApiIdentityV1Roles: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityRoles', id: 'LIST' },
          {
            type: 'IdentityRoles',
            id: arg.name,
          },
        ];
      },
      deleteApiIdentityV1RolesByRoleName: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      getApiIdentityV1RoleRightsByRoleName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityRoleRights', id: arg },
        ];
      },
      postApiIdentityV1SetRoleRights: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityRoleRights', id: arg.roleName },
        ];
      },
      // Группы
      getApiIdentityV1Groups: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
      getApiIdentityV1GroupsByGroupName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityGroups', id: arg },
        ];
      },
      postApiIdentityV1Groups: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
      patchApiIdentityV1Groups: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityGroups', id: 'LIST' },
          {
            type: 'IdentityGroups',
            id: arg.name,
          },
        ];
      },
      deleteApiIdentityV1Groups: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRolesRights: builder.query<
        Record<string, { read: boolean; write: boolean; execute: boolean }>,
        string[]
      >({
        queryFn: async (roles, { dispatch }, _extraOptions, baseQuery) => {
          try {
            const res = await Promise.all(
              roles.map((role) =>
                dispatch(
                  api.endpoints.getApiIdentityV1RoleRightsByRoleName.initiate(
                    role
                  )
                )
              )
            );

            const error = res.find(
              (el) => el.error && el.status === 'rejected'
            );

            if (error) {
              throw error;
            }

            const ROLES_RIGHTS = res
              .map((promiseRes) => promiseRes.data?.rights)
              .reduce((acc, el) => {
                if (!el) {
                  return acc;
                }

                el?.forEach((right) => {
                  const { controllerName, ...claims } = right;

                  const current = acc[controllerName as string]; //

                  const setTrueClaims = (
                    Object.keys(claims) as unknown as Array<keyof typeof claims>
                  ).filter((claim) => Boolean(right[claim]));

                  if (current) {
                    setTrueClaims.map((claim) => (current[claim] = true));
                  } else {
                    acc[controllerName as string] = {
                      read: false,
                      write: false,
                      execute: false,
                    };

                    setTrueClaims.map(
                      (claim) => (acc[controllerName as string][claim] = true)
                    );
                  }
                });

                return acc;
              }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

            return { data: ROLES_RIGHTS };
          } catch (err) {
            if (isFetchBaseQueryError(err)) {
              return { error: err };
            } else if (isErrorWithMessage(err)) {
              const { message } = err;

              return {
                error: {
                  error: message,
                  status: 'FETCH_ERROR',
                },
              };
            }

            return {
              error: {
                error: 'Неизвестная ошибка',
                status: 'CUSTOM_ERROR',
              },
            };
          }
        },
      }),
      // getRolesRights: builder.query<
      //   {
      //     [k: string]: {
      //       read: boolean;
      //       write: boolean;
      //       execute: boolean;
      //     };
      //   },
      //   string[]
      // >({
      //   queryFn: async (roles, _queryApi, _extraOptions, baseQuery) => {
      //     const { getApiIdentityRoleRightsRoleName } = getIdentity();

      //     try {
      //       const res = await Promise.all(
      //         roles.map((role) => getApiIdentityRoleRightsRoleName(role))
      //       );

      //       const rolesRightsObject = res.reduce((acc, item) => {
      //         // TODO: добавить throw error
      //         if (!item || item.status === 'ERROR') return acc;

      //         item.rights?.forEach(
      //           ({ controllerName, execute, read, write }) => {
      //             const currentObj = acc[controllerName];

      //             if (currentObj) {
      //               if (read === true) {
      //                 currentObj.read = true;
      //               }

      //               if (write === true) {
      //                 currentObj.write = true;
      //               }

      //               if (execute === true) {
      //                 currentObj.execute = true;
      //               }
      //             } else {
      //               acc[controllerName] = {
      //                 read: false,
      //                 write: false,
      //                 execute: false,
      //               };

      //               if (read === true) {
      //                 acc[controllerName].read = true;
      //               }

      //               if (write === true) {
      //                 acc[controllerName].write = true;
      //               }

      //               if (execute === true) {
      //                 acc[controllerName].execute = true;
      //               }
      //             }
      //           }
      //         );

      //         return acc;
      //       }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

      //       return { data: rolesRightsObject };
      //     } catch (err) {
      //       return {
      //         error: {
      //           error: `Не удалось получить права для роли`,
      //           status: `CUSTOM_ERROR`, // Не менять
      //         },
      //       };
      //     }
      //   },
      // }),
    }),
  });

export const {
  // Юзеры
  useGetApiIdentityV1UsersQuery: useGetIdentityUsers,
  useGetApiIdentityV1UsersByUserNameQuery: useGetIdentityUser,
  usePostApiIdentityV1UsersMutation: useAddIdentityUser,
  usePatchApiIdentityV1UsersMutation: useUpdateIdentityUser,
  useDeleteApiIdentityV1UsersByUserNameMutation: useDeleteIdentityUser,
  usePostApiIdentityV1SetUserRightsMutation: useUpdateUserRights,
  //
  useGetApiIdentityV1UserRolesByUserNameQuery: useGetUserRoles,
  useGetApiIdentityV1UserRightsByUserNameQuery: useGetUserRights,
  useGetRolesRightsQuery: useGetRolesRights,
  // Роли
  useGetApiIdentityV1RolesQuery: useGetIdentityRoles,
  useGetApiIdentityV1RolesByRoleNameQuery: useGetIdentityRole,
  usePostApiIdentityV1RolesMutation: useAddIdentityRole,
  usePatchApiIdentityV1RolesMutation: useUpdateIdentityRole,
  useDeleteApiIdentityV1RolesByRoleNameMutation: useDeleteIdentityRole,
  useGetApiIdentityV1RoleRightsByRoleNameQuery: useGetRoleRights,
  usePostApiIdentityV1SetRoleRightsMutation: useUpdateRoleRights,
  // Группы
  useGetApiIdentityV1GroupsQuery: useGetIdentityGroups,
  useGetApiIdentityV1GroupsByGroupNameQuery: useGetIdentityGroup,
  usePostApiIdentityV1GroupsMutation: useAddIdentityGroup,
  usePatchApiIdentityV1GroupsMutation: useUpdateIdentityGroup,
  useDeleteApiIdentityV1GroupsMutation: useDeleteIdentityGroup,
  //
  usePostApiIdentityV1UsersLoginMutation: useLogin,
} = enhancedApi;
