export function ActionIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#23C990" fill-opacity="0.2" />
      <path
        d="M19.8184 10.8135L10.8184 19.8135M19.8184 19.8134L10.8184 10.8135"
        stroke="black"
        stroke-opacity="0.5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
