import { FormatUrl } from 'shared/api/services/chargepoint/rtk/generated/firmwares';
import { z } from 'zod';

export const MAX_FILE_SIZE = 30 * 1024 * 1024;

export const FORMAT_URL: Record<FormatUrl, string> = {
  SOAP: 'Soap',
  JSON: 'Json',
};

export const formatUrl = z
  .enum(['SOAP', 'JSON'])
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  });
