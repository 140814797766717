// import { useNavigate } from 'react-router-dom';
// import { RcFile } from 'antd/lib/upload';
// import {
//   isErrorWithMessage,
//   openErrorNotification,
//   openSuccessNotification,
// } from 'shared/lib';
// import { useCustomUpdateFirmware } from 'shared/api/services/chargepoint/rtk/enhanced/firmwares';

// import { FormOutput } from '../consts/schema';
// import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

// export function useUpdateFirmware() {
//   const [trigger, { isLoading }] = useCustomUpdateFirmware();

//   const navigate = useNavigate();

//   const handleUpdate = async (data: FormOutput, id: string) => {
//     const { description, file, formatUrl, name } = data;

//     const formData = new FormData();

//     formData.append('FormUrl', formatUrl);
//     formData.append('Name', name);

//     if (file) {
//       formData.append('File', file as RcFile);
//     }

//     if (description) {
//       formData.append('Description', description);
//     }

//     try {
//       const res = await trigger({ body: formData, id }).unwrap();

//       openSuccessNotification(EDIT_SUCCESS);
//     } catch (err) {
//       const hasErrorMessage = isErrorWithMessage(err);

//       const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

//       openErrorNotification(errorText);
//     }
//   };

//   return {
//     handleUpdate,
//     isMutationLoading: isLoading,
//   };
// }

import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useCustomUpdateFirmwareMutation } from 'shared/api/services/chargepoint/rtk/enhanced/firmwares';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Прошивка обновлена';
const ERROR_MSG = 'Не удалось обновить прошивку ';

export const useUpdateFirmware = () => {
  const [progress, setProgress] = useState(0);

  const setUploadProgress = (val: number) => setProgress(val);

  const navigate = useNavigate();

  const [trigger, { isLoading }] = useCustomUpdateFirmwareMutation();

  const handleUpdate = async ({
    data,
    id,
  }: {
    id: string;
    data: FormOutput;
  }) => {
    try {
      const { file, formatUrl, name, description } = data;

      const res = await trigger({
        id,
        body: {
          File: file,
          FormatUrl: formatUrl,
          Name: name,
          Description: description,
        },
        setUploadProgress,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      if (res.data?.id) {
        navigate(`${FIRMWARE_ROUTES.FIRMWARES}/${res.data.id}`);
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return {
    progress,
    isLoading,
    handleUpdate,
  };
};
