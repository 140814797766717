import { Col, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { BalanceDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { FormRow } from 'shared/ui/form';
import { UiInput } from 'shared/ui/ui-kit';
import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';

import { ManageBillingBalanceButton } from 'features/user/manage-balances';

import { StyledLabel, StyledLayout } from './styles';
import { useToggleFreeze } from '../../hooks/use-toggle-freeze';

type Props = {
  balance: BalanceDto;
  countries: GetCountryVm[];
};

export function BillingBalance({ balance, countries }: Props) {
  const { lastUpdate, id, countryId, value, isFrozen, userId } = balance;

  const country = countries.find((el) => el.id === countryId);

  const { currencyText = '' } = country || {};

  const { isLoading, toggleFreeze } = useToggleFreeze(id, userId);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    toggleFreeze(e.target.checked);
  };

  return (
    <StyledLayout>
      <div>
        <StyledLabel>{`Баланс, (${currencyText}, физ.лицо)`}</StyledLabel>
        <FormRow gutter={10}>
          <Col span={6}>
            <UiInput value={value} disabled style={{ height: '100%' }} />
          </Col>
          <Col>
            <ManageBillingBalanceButton balanceId={id} userId={userId} />
          </Col>
        </FormRow>
      </div>
      <StyledLabel>{`Обновлено: ${showTimeString(
        lastUpdate,
        FORMAT_TO_SECONDS
      )}`}</StyledLabel>
      <Spin spinning={isLoading}>
        <UiCheckbox
          checked={isFrozen}
          onChange={onCheckboxChange}
          style={{ backgroundColor: '#F5F5F5', padding: '6px' }}
        >
          Заблокирован
        </UiCheckbox>
      </Spin>
    </StyledLayout>
  );
}
