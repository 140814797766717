import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1PhotosById: build.query<
      GetApiChargepointV1PhotosByIdApiResponse,
      GetApiChargepointV1PhotosByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/chargepoint/V1/Photos/${queryArg}` }),
    }),
    patchApiChargepointV1PhotosById: build.mutation<
      PatchApiChargepointV1PhotosByIdApiResponse,
      PatchApiChargepointV1PhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Photos/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteApiChargepointV1PhotosById: build.mutation<
      DeleteApiChargepointV1PhotosByIdApiResponse,
      DeleteApiChargepointV1PhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Photos/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1Photos: build.query<
      GetApiChargepointV1PhotosApiResponse,
      GetApiChargepointV1PhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Photos`,
        params: {
          ChargePointId: queryArg,
        },
      }),
    }),
    postApiChargepointV1Photos: build.mutation<
      PostApiChargepointV1PhotosApiResponse,
      PostApiChargepointV1PhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Photos`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1PhotosCount: build.query<
      GetApiChargepointV1PhotosCountApiResponse,
      GetApiChargepointV1PhotosCountApiArg
    >({
      query: () => ({ url: `/api/chargepoint/V1/Photos/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1PhotosByIdApiResponse =
  /** status 200 Success */ GetPhotoVmBaseResponse;
export type GetApiChargepointV1PhotosByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargepointV1PhotosByIdApiResponse =
  /** status 200 Success */ GetPhotoVmBaseResponse;
export type PatchApiChargepointV1PhotosByIdApiArg = {
  /** Идентификатор */
  id: string;
  body: {
    ChargePointId?: string;
    Priority?: number;
    File?: Blob;
  };
};
export type DeleteApiChargepointV1PhotosByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1PhotosByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1PhotosApiResponse =
  /** status 200 Success */ GetPhotoVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1PhotosApiArg = string | undefined;
export type PostApiChargepointV1PhotosApiResponse =
  /** status 200 Success */ GetPhotoVmIReadOnlyCollectionBaseResponse;
export type PostApiChargepointV1PhotosApiArg = {
  ChargePointId: string;
  FirstPriority: number;
  Files: Blob[];
};
export type GetApiChargepointV1PhotosCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1PhotosCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetPhotoVm = {
  id: string;
  url: string;
  objectStorageName: string;
  chargePointId: string;
  priority: number;
};
export type GetPhotoVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetPhotoVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetPhotoVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetPhotoVm[] | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargepointV1PhotosByIdQuery,
  usePatchApiChargepointV1PhotosByIdMutation,
  useDeleteApiChargepointV1PhotosByIdMutation,
  useGetApiChargepointV1PhotosQuery,
  usePostApiChargepointV1PhotosMutation,
  useGetApiChargepointV1PhotosCountQuery,
} = injectedRtkApi;
