type ErrorWithMessage = {
  status: number;
  data: {
    status: string;
    statusMessage: string;
  };
};

export const isErrorWithMessage = (
  error: unknown
): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    (error as Record<string, unknown>).data !== null &&
    typeof (error as Record<string, unknown>).data === 'object'
  );
};

// to validateStatus
type ResponseWithErrorStatus = {
  status: 'ERROR';
  statusMessage?: string | null;
};

export const isResponseStatusError = (
  res: unknown
): res is ResponseWithErrorStatus => {
  return (
    typeof res === 'object' &&
    res !== null &&
    'status' in res &&
    res.status === 'ERROR'
  );
};
