import Excel from 'exceljs';

import { applyWorksheetStyles } from './apply-worksheet-styles';
import { downloadReport } from './download-report';
import { setColumnsAutoWidth } from './set-columns-autowidth';
import { setReportMetadata } from './set-report-metadata';

type Props = {
  tableRef: React.MutableRefObject<null>;
  title: string;
};

export const exportInExcel = async ({ title, tableRef }: Props) => {
  const table = tableRef.current as unknown as HTMLElement;

  const trsLength = Array.from(table.querySelectorAll('tr')).length;

  const thead = table.querySelector('thead') as HTMLTableSectionElement;
  const tbody = table.querySelector('tbody') as HTMLTableSectionElement;
  const tfoot = table.querySelector('tfoot') as HTMLTableSectionElement;

  const theadTrs = thead.querySelectorAll('tr');
  const tbodyTrs = tbody.querySelectorAll('tr');
  const tfootTrs = tfoot.querySelectorAll('tr');

  const wb = new Excel.Workbook();

  setReportMetadata(wb);

  const ws = wb.addWorksheet(title);

  Array.from(theadTrs).forEach((tr) => {
    const ths = Array.from(tr.querySelectorAll('th'));

    ws.addRow(ths.slice().map((th) => th.innerText));
  });

  Array.from(tbodyTrs).forEach((tr) => {
    const tds = Array.from(tr.querySelectorAll('td'));

    ws.addRow(tds.slice().map((td) => td.innerText));
  });

  Array.from(tfootTrs).forEach((tr) => {
    const tds = Array.from(tr.querySelectorAll('td'));

    ws.addRow(
      tds.slice().map((td, idx) => {
        return td.innerText;
      })
    );

    // TODO: extract logic
    ws.mergeCells(`D${trsLength}:F${trsLength}`);
    ws.getCell(`D${trsLength}`).value = ws.getCell(`C${trsLength}`).value;

    ws.getCell(`C${trsLength}`).value = ws.getCell(`B${trsLength}`).value;

    ws.mergeCells(`A${trsLength}:B${trsLength}`);
  });

  applyWorksheetStyles(ws, 1);

  setColumnsAutoWidth(ws, 1);

  const reportFileName = title;

  downloadReport(wb, reportFileName);
};
