import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { MANUAL_ACTION_TYPE } from 'entities/bonus-program';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { useManageBonuses } from '../hooks/use-manage-bonuses';

type Props = {
  closeModal: () => void;
  countries: GetCountryVm[];
  userId: string;
};

export function Form({ closeModal, countries, userId }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}, //
  });

  const { handleManage, isLoading } = useManageBonuses({
    closeModal,
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    handleManage({ data, userId });
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields countries={countries} />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

type FieldsProps = Pick<Props, 'countries'>;

Form.Fields = function Fields({ countries }: FieldsProps) {
  const { setValue, watch } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="sum"
            label="Количество баллов"
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="type"
            label="Операция"
            options={Object.entries(MANUAL_ACTION_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="reason" label="Описание" />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
