import { BalanceDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import { BillingBalance } from '../balance';
import { StyledLayout } from './styles';

type Props = {
  balances: BalanceDto[];
  countries: GetCountryVm[];
};

export function ListLayout({ balances, countries }: Props) {
  return (
    <StyledLayout>
      {balances.map((el) => (
        <BillingBalance balance={el} key={el.id} countries={countries} />
      ))}
    </StyledLayout>
  );
}
