import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import {
  useDeleteService,
  useDeleteStage,
} from 'shared/api/services/billing/rtk/enhanced';
import { useAppDispatch } from 'shared/redux/types';
import { baseApi } from 'shared/api/base';

export const SUCCESS_MSG = 'Услуга удалена';
export const ERROR_MSG = 'Не удалось удалить услугу';

type Props = {
  serviceId: string;
  servicesListId: string;
};

export const Modal = NiceModal.create(
  ({ serviceId, servicesListId }: Props) => {
    const modal = useModal();

    const dispatch = useAppDispatch();

    const [trigger, { isLoading }] = useDeleteService();

    const handleDelete = async () => {
      try {
        const res = await trigger(serviceId).unwrap();

        openSuccessNotification(SUCCESS_MSG);

        dispatch(
          baseApi.util.invalidateTags([
            { type: 'ServicesLists', id: servicesListId },
          ])
        );
      } catch (err) {
        const hasErrorMessage = isErrorWithMessage(err);

        const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

        openErrorNotification(errorText);
      } finally {
        modal.hide();
      }
    };

    return (
      <UiModal
        title={`Удалить услугу?`}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
      >
        <ConfirmDialog
          onCancel={modal.hide}
          onConfirm={() => handleDelete()}
          loading={isLoading}
        />
      </UiModal>
    );
  }
);
