import {
  BillingStatus,
  FiscalStatus,
  PaymentStatus,
  ServiceType,
  StageEndType,
  TariffType,
} from 'shared/api/services/billing/rtk/generated';

export const TARIFF_TYPE: Record<TariffType, string> = {
  ENERGY_KWH: 'за кВт*ч',
  TIME_MINUTE: 'в минуту',
  FLAT_FEE: 'разово',
};

export const STAGE_END_TYPE: Record<StageEndType, string> = {
  ENERGY_PERCENT: 'По достижению % заряда',
  MANUAL: 'Вручную',
  TIME_MINUTE: 'Истекло по времени',
  RIGHT_NOW: 'Разово',
  POWER_LOSS_KW: 'При снижении мощности',
  // EVENT: 'По получению события от станции',
};

export const PAYMENT_STATUS: Record<PaymentStatus, string> = {
  // DEPOSITED: 'Оплачено',
  ERROR: 'Ошибка',
  ERROR_PAYMENT: 'Платеж не прошел',
  // HOLD: 'Захолдировано',
  IN_PROCESS: 'В процессе',
  NOT_PAID: 'Не оплачено',
  NOT_REQUIRED: 'Не требует оплаты',
  PAID: 'Оплачено',
};

export const BILLING_STATUS: Record<BillingStatus, string> = {
  COMPLETED: 'Завершено',
  ERROR: 'Ошибка',
  IN_PROCESS: 'В процессе',
};

export const FISCAL_STATUS: Record<FiscalStatus, string> = {
  ERROR: 'Ошибка',
  FISCALIZED: 'Фискализировано',
  IN_PROCESS: 'В процессе',
  NOT_FISCALIZED: 'Не фискализировано',
  NOT_REQUIRED: 'Не требуется',
  TIME_OUT_ERROR: 'TimeOut',
};

export const SERVICE_TYPE: Record<ServiceType, string> = {
  CHARGING: 'Заряд',
  PARKING: '',
  RESERVING: 'Бронирование',
};

export const BILLING_DEFAULT_SEARCH_PARAMS =
  'orderingField=createdDate&orderingType=DESC';

export type BillingActionType = 'debit' | 'credit';

export const BILLING_ACTION_TYPE: Record<BillingActionType, string> = {
  credit: 'Пополнение',
  debit: 'Списание',
};
