import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';

import { useDeletePhoto } from '../hooks/use-delete-photo';

type Props = {
  photoId: string;
  chargePointId: string;
};

export const Modal = NiceModal.create(({ chargePointId, photoId }: Props) => {
  const modal = useModal();

  const { handleDelete, isLoading } = useDeletePhoto();

  const closeModal = () => modal.hide();

  return (
    <UiModal
      title={`Удалить фотографию?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={closeModal}
        onConfirm={() =>
          handleDelete({
            chargePointId,
            id: photoId,
            closeModal,
          })
        }
        loading={isLoading}
      />
    </UiModal>
  );
});
