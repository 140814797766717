import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

import { StageResultSchema, StageSchema } from 'entities/billing';

const FormResultSchema = z.object({
  name: z.string().min(1),
  reservingStages: z.array(StageResultSchema).optional(),
  chargingStages: z.array(StageResultSchema).optional(),
  chargePointIds: z.array(z.string().uuid()),
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    reservingStages: z.array(StageSchema).optional(),
    chargingStages: z.array(StageSchema).optional(),
    chargePointIds: z.array(z.string().uuid()),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
