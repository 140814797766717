import { createBonusProgramApiBonusV1BonusProgramPostBody } from 'shared/api/services/bonus/orval/zod/schemas';
import {
  BalanceTransaction,
  BonusProgram,
} from 'shared/api/services/bonus/rtk/generated';

export type BonusProgramType = Exclude<BonusProgram['type'], undefined>;

export const BONUS_PROGRAM_TYPE: Record<BonusProgramType, string> = {
  ChargedByCapacity: 'Заряжено кВт',
  ChargedByStations: 'Заряжено на разных станциях',
  SpendMoney: 'Потрачено денег',
};

export type OperationType = BalanceTransaction['operation'];

export const OPERATION_TYPE: Record<OperationType, string> = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  UNDEFINED: 'UNDEFINED',
};

export type BonusProgramFrequency = Exclude<
  BonusProgram['frequency'],
  undefined
>;

export const BONUS_PROGRAM_FREQUENCY: Record<BonusProgramFrequency, string> = {
  DAY: 'день',
  MONTH: 'месяц',
  WEEK: 'неделя',
};

export type AwardType = 'percent' | 'money';

export const AWARD_TYPE: Record<AwardType, string> = {
  money: 'Сумма',
  percent: '%',
};

export type ManualActionType = 'debit' | 'credit';

export const MANUAL_ACTION_TYPE: Record<ManualActionType, string> = {
  credit: 'Начисление',
  debit: 'Списание',
};

export const bonusProgramType =
  createBonusProgramApiBonusV1BonusProgramPostBody.shape.type;
export const bonusProgramFrequency =
  createBonusProgramApiBonusV1BonusProgramPostBody.shape.frequency;
