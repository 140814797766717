import { Spin } from 'antd';
import { Link } from 'react-router-dom';

import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { StyledText } from 'shared/ui';

import {
  BILLING_STATUS,
  FISCAL_STATUS,
  PAYMENT_STATUS,
} from 'entities/billing';
import { CHECK_STATUS } from 'entities/fiscal-check';

import {
  BodyCell,
  CardTitle,
  FlexContainer,
  GridLayout,
  GridRow,
  HeaderCell,
  StyledCard,
  StyledLink,
} from './styles';
import { useQueries } from './use-queries';
import { ActionRetryPayButton } from 'features/billing/retrypay';
import { ActionCloseButton } from 'features/billing/close';

type Props = {
  closeModal: () => void;
  billing: BillingDto;
};

export function OperationInfo({ billing, closeModal }: Props) {
  const {
    userId,
    providedServices,
    id,
    paymentStatus,
    chargePointId,
    status,
    fiscalStatus,
  } = billing;
  const service = providedServices.find((el) => el.sessionId);

  const { sessionId, serviceType, serviceId } = service ?? {};

  const {
    mobileApps,
    user,
    fiscalCheck,
    servicesList,
    connectorId,
    chargePoint,
    isUserLoading,
    isMobileAppsLoading,
    isServicesListLoading,
    isFiscalCheckLoading,
    isConnectorLoading,
    isChargePointLoading,
  } = useQueries({
    sessionId,
    userId,
    serviceType,
    billingId: id,
    serviceId,
    chargePointId,
  });

  const renderPaymentStatus = () => {
    return (
      <FlexContainer>
        <span>{PAYMENT_STATUS[paymentStatus]}</span>
        {paymentStatus !== 'PAID' ? (
          <ActionRetryPayButton billingId={id} />
        ) : null}
      </FlexContainer>
    );
  };

  const renderBillingStatus = () => {
    return (
      <FlexContainer>
        <span>{BILLING_STATUS[status]}</span>
        {status !== 'COMPLETED' ? <ActionCloseButton billingId={id} /> : null}
      </FlexContainer>
    );
  };

  const renderServicesList = () => {
    if (!servicesList) {
      return '';
    }

    const { id, name } = servicesList;

    return (
      <Link to={`${BILLING_ROUTES.LIST}/${id}`}>
        <StyledLink onClick={closeModal}>{name}</StyledLink>
      </Link>
    );
  };

  const renderFiscalCheck = () => {
    const { receiptUrl } = fiscalCheck || {};

    const fiscalStatusName = FISCAL_STATUS[fiscalStatus];

    if (!receiptUrl) {
      return fiscalStatusName;
    }

    return (
      <StyledLink>
        <a href={receiptUrl} target="_blank">
          {fiscalStatusName}
        </a>
      </StyledLink>
    );
  };

  const renderMobileApp = () => {
    if (!mobileApps || !billing) {
      return '';
    }

    const mobileApp = mobileApps.find((el) => el.id === billing.mobileAppId);

    if (mobileApp) {
      return mobileApp.name;
    }
  };

  const renderUser = () => {
    if (!user) {
      return '';
    }

    const { groupId, id, login } = user;

    return (
      <Link to={`${USER_ROUTES.PERSONS_LIST}/${groupId ?? null}/${id}/profile`}>
        <StyledLink onClick={closeModal}>{login}</StyledLink>
      </Link>
    );
  };

  const renderConnector = () => {
    if (!connectorId || !chargePoint) {
      return '';
    }

    if (connectorId === chargePoint.id) {
      return '';
    }

    const connectorType = chargePoint.connectors?.find(
      (el) => el.id === connectorId
    )?.type;

    return connectorType ?? '';
  };

  return (
    <StyledCard>
      <CardTitle>Об операции</CardTitle>

      <GridLayout>
        <HeaderCell>Статус оплаты</HeaderCell>
        <HeaderCell>Статус услуги</HeaderCell>
        <HeaderCell>Чек</HeaderCell>
        {/* <HeaderCell>ID транзакции банка</HeaderCell> */}
        <HeaderCell>Тариф (биллинг)</HeaderCell>
        <HeaderCell>Клиент</HeaderCell>
        <HeaderCell>Коннектор</HeaderCell>
        <HeaderCell>Мобильное приложение</HeaderCell>
        <GridRow>
          <BodyCell>
            <StyledText value={renderPaymentStatus()} />
          </BodyCell>
          <BodyCell>
            <StyledText value={renderBillingStatus()} />
          </BodyCell>
          <BodyCell>
            <Spin spinning={isFiscalCheckLoading}>
              <StyledText value={renderFiscalCheck()} />
            </Spin>
          </BodyCell>
          {/* <BodyCell>
            <StyledText value="" />
          </BodyCell> */}
          <BodyCell>
            <Spin spinning={isServicesListLoading}>
              <StyledText value={renderServicesList()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <Spin spinning={isUserLoading}>
              <StyledText value={renderUser()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <Spin spinning={isConnectorLoading || isChargePointLoading}>
              <StyledText value={renderConnector()} />
            </Spin>
          </BodyCell>
          <BodyCell>
            <Spin spinning={isMobileAppsLoading}>
              <StyledText value={renderMobileApp()} />
            </Spin>
          </BodyCell>
        </GridRow>
      </GridLayout>
    </StyledCard>
  );
}
