import { enhancedApi as api } from '../generated/hubs';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1Hubs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    getApiChargepointV1HubsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Hubs', id: arg },
      ];
    },
    postApiChargepointV1Hubs: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    patchApiChargepointV1HubsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Hubs', id: 'LIST' },
        {
          type: 'Hubs',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiChargepointV1HubsQuery: useGetHubs,
  useGetApiChargepointV1HubsByIdQuery: useGetHub,
  usePostApiChargepointV1HubsMutation: useAddHub,
  usePatchApiChargepointV1HubsByIdMutation: useUpdateHub,
} = enhancedApi;
