import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './base-query-with-reauth';

export const baseApi = createApi({
  reducerPath: 'newApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Billings',
    'Banks',
    'BankCards',
    'BonusPrograms',
    'BonusProgramLevels',
    'BonusProgramAchievements',
    'BonusBalances',
    'BillingBalances',
    'Cars',
    'CarBrands',
    'CarModels',
    'ChargePoints',
    'ChargePointGroups',
    'ChargePointPhotos',
    'Companies',
    'Countries',
    'IdentityGroups',
    'IdentityUsers',
    'IdentityRoles',
    'Events',
    'Hubs',
    'Users',
    'UserGroups',
    'MobileApps',
    'ReserveSessions',
    'IdentityUserRoles',
    'IdentityUserRights',
    'IdentityRoleRights',
    'Sessions',
    'Reserves',
    'Tariffs',
    'ServicesLists',
    'ServicesListLinks',
    'TargetServicesLists',
    'Batches',
    'Templates',
    'Supports',
    'Firmwares',
    'Transactions',
  ],
  endpoints: (builder) => ({}),
});
