import {
  enhancedApi as api,
  PostApiBillingV1TariffsApiArg,
} from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // Тариф (стоимость)
    getApiBillingV1Tariffs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Tariffs', id: 'LIST' }];
    },
    postApiBillingV1Tariffs: (endpoint) => {
      // endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
      endpoint.onQueryStarted = async (
        tariff: PostApiBillingV1TariffsApiArg,
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data } = await queryFulfilled;

          const { data: tariff } = data;

          if (tariff) {
            const pathResult = dispatch(
              enhancedApi.util.updateQueryData(
                'getApiBillingV1Tariffs',
                {},
                (draft) => {
                  draft.data?.push(tariff);
                }
              )
            );
          }
        } catch {}
      };
    },
    deleteApiBillingV1TariffsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
    },
    // Списки услуг (биллинг)
    getApiBillingV1ServicesLists: (endpoint) => {
      endpoint.providesTags = [{ type: 'ServicesLists', id: 'LIST' }];
    },
    getApiBillingV1ServicesListsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ServicesLists', id: arg },
      ];
    },
    // Стадии
    deleteApiBillingV1StagesById: (endpoint) => {
      // Принимает только id. Но инвалидировать надо еще ServiceList
      // TODO:
      // endpoint.invalidatesTags = [{ type: 'ServicesLists', id: 'LIST' }];
    },
    // Биллинг (оплаты)
    getApiBillingV1Billings: (endpoint) => {
      endpoint.providesTags = [{ type: 'Billings', id: 'LIST' }];
    },
    //
    getApiBillingV1ServicesListLinks: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ServicesListLinks', id: arg.servicesListId },
      ];
    },

    // Привязанные списки услуг для сущностей
    // getApiBillingV1TargetsServicesLists: (endpoint) => {
    //   endpoint.providesTags = (result, error, arg) => [
    //     { type: 'TargetServicesLists', id: JSON.stringify(arg) },
    //   ];
    // },

    // Балансы
    getApiBillingV1Balances: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'BillingBalances', id: arg.userId },
      ];
    },
  },
});

export const {
  useGetApiBillingV1ServicesListsQuery: useGetServicesLists,
  useGetApiBillingV1ServicesListsByIdQuery: useGetServiceList,
  usePostApiBillingV1ServicesListsMutation: useCreateServicesListMutation,
  // Тарифы
  useGetApiBillingV1TariffsQuery: useGetTariffs,
  usePostApiBillingV1TariffsMutation: useAddTariff,
  useDeleteApiBillingV1TariffsByIdMutation:
    useDeleteApiBillingTariffsByIdMutation,
  // Стадии
  useDeleteApiBillingV1StagesByIdMutation: useDeleteStage,
  // Услуги
  useDeleteApiBillingV1ServicesByIdMutation: useDeleteService,
  // Биллинги
  useGetApiBillingV1BillingsQuery: useGetBillings,
  useGetApiBillingV1BillingsCountQuery: useGetBillingsCount,
  // Привязанные списки услуг для сущности
  useGetApiBillingV1ServicesListLinksQuery: useGetServicesListLinks,
  usePutApiBillingV1ServicesListLinksMutation: usePutServicesListLinks,
  useGetApiBillingV1TargetsServicesListsQuery: useGetTargetsServicesLists,
  useDeleteApiBillingV1ServicesListLinksByTargetIdMutation:
    useDeleteServicesListLinksByTargetId,
  //
  usePostApiBillingV1ServicesMutation: useAddServiceMutation,
  usePatchApiBillingV1ServicesListsByIdMutation: useUpdateServicesListMutation,
  usePostApiBillingV1StagesMutation: useAddStageMutation,
  //
  usePostApiBillingV1BillingsActionCloseMutation: useBillingActionClose,
  usePostApiBillingV1BillingsActionRetrypayMutation: useBillingActionRetryPay,
  // Балансы
  useGetApiBillingV1BalancesQuery: useGetBillingBalances,
  usePostApiBillingV1BalancesActionCreditMutation: useBillingActionCredit,
  usePostApiBillingV1BalancesActionDebitMutation: useBillingActionDebit,
  usePostApiBillingV1BalancesActionFreezeMutation: useBillingActionFreeze,
  usePostApiBillingV1BalancesActionUnfreezeMutation: useBillingActionUnfreeze,
} = enhancedApi;
