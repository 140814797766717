import { isErrorWithMessageField } from './is-error-with-message-field';
import { isFetchBaseQueryError } from './is-fetch-base-query-error';

export const getErrorMessage = (err: unknown): string => {
  if (isFetchBaseQueryError(err)) {
    if ('error' in err) {
      return err.error;
    } else if (err.data) {
      // TODO:
      return err.data.statusMessage as string;
    }
  } else if (isErrorWithMessageField(err)) {
    return err.message;
  } else {
    return 'Неизвестная ошибка';
  }
};
