import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiBillingV1AuxClearInMemoryCache: build.query<
      GetApiBillingV1AuxClearInMemoryCacheApiResponse,
      GetApiBillingV1AuxClearInMemoryCacheApiArg
    >({
      query: () => ({ url: `/Api/Billing/V1/Aux/ClearInMemoryCache` }),
    }),
    getApiBillingV1Balances: build.query<
      GetApiBillingV1BalancesApiResponse,
      GetApiBillingV1BalancesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          CountryId: queryArg.countryId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1BalancesCount: build.query<
      GetApiBillingV1BalancesCountApiResponse,
      GetApiBillingV1BalancesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/count`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiBillingV1BalancesTransactions: build.query<
      GetApiBillingV1BalancesTransactionsApiResponse,
      GetApiBillingV1BalancesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/transactions`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          Operation: queryArg.operation,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1BalancesTransactionsCount: build.query<
      GetApiBillingV1BalancesTransactionsCountApiResponse,
      GetApiBillingV1BalancesTransactionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/transactions/count`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          Operation: queryArg.operation,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiBillingV1BalancesActionCreate: build.mutation<
      PostApiBillingV1BalancesActionCreateApiResponse,
      PostApiBillingV1BalancesActionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/create`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionSet: build.mutation<
      PostApiBillingV1BalancesActionSetApiResponse,
      PostApiBillingV1BalancesActionSetApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/set`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionCredit: build.mutation<
      PostApiBillingV1BalancesActionCreditApiResponse,
      PostApiBillingV1BalancesActionCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/credit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionDebit: build.mutation<
      PostApiBillingV1BalancesActionDebitApiResponse,
      PostApiBillingV1BalancesActionDebitApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/debit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionReset: build.mutation<
      PostApiBillingV1BalancesActionResetApiResponse,
      PostApiBillingV1BalancesActionResetApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/reset`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionFreeze: build.mutation<
      PostApiBillingV1BalancesActionFreezeApiResponse,
      PostApiBillingV1BalancesActionFreezeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/freeze`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BalancesActionUnfreeze: build.mutation<
      PostApiBillingV1BalancesActionUnfreezeApiResponse,
      PostApiBillingV1BalancesActionUnfreezeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Balances/action/unfreeze`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1BillingsByBillingId: build.query<
      GetApiBillingV1BillingsByBillingIdApiResponse,
      GetApiBillingV1BillingsByBillingIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/V1/Billings/${queryArg}` }),
    }),
    getApiBillingV1Billings: build.query<
      GetApiBillingV1BillingsApiResponse,
      GetApiBillingV1BillingsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings`,
        params: {
          BalanceId: queryArg.balanceId,
          SessionId: queryArg.sessionId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ServiceType: queryArg.serviceType,
          ChargePointsIds: queryArg.chargePointsIds,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1BillingsCount: build.query<
      GetApiBillingV1BillingsCountApiResponse,
      GetApiBillingV1BillingsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings/count`,
        params: {
          BalanceId: queryArg.balanceId,
          SessionId: queryArg.sessionId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ServiceType: queryArg.serviceType,
          ChargePointsIds: queryArg.chargePointsIds,
        },
      }),
    }),
    postApiBillingV1BillingsActionClose: build.mutation<
      PostApiBillingV1BillingsActionCloseApiResponse,
      PostApiBillingV1BillingsActionCloseApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings/action/close`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BillingsActionCloseactive: build.mutation<
      PostApiBillingV1BillingsActionCloseactiveApiResponse,
      PostApiBillingV1BillingsActionCloseactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings/action/closeactive`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BillingsActionCalculatelast: build.mutation<
      PostApiBillingV1BillingsActionCalculatelastApiResponse,
      PostApiBillingV1BillingsActionCalculatelastApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings/action/calculatelast`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1BillingsActionRetrypay: build.mutation<
      PostApiBillingV1BillingsActionRetrypayApiResponse,
      PostApiBillingV1BillingsActionRetrypayApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Billings/action/retrypay`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1MetricsGraphTotalRevenue: build.query<
      GetApiBillingV1MetricsGraphTotalRevenueApiResponse,
      GetApiBillingV1MetricsGraphTotalRevenueApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Metrics/Graph/TOTAL_REVENUE`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          GroupByPeriod: queryArg.groupByPeriod,
        },
      }),
    }),
    getApiBillingV1ReportsUsersPaymentSum: build.query<
      GetApiBillingV1ReportsUsersPaymentSumApiResponse,
      GetApiBillingV1ReportsUsersPaymentSumApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Reports/usersPaymentSum`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          BalancesIds: queryArg.balancesIds,
          UsersIds: queryArg.usersIds,
          CountryId: queryArg.countryId,
        },
      }),
    }),
    getApiBillingV1ReportsUsersInvoiceSum: build.query<
      GetApiBillingV1ReportsUsersInvoiceSumApiResponse,
      GetApiBillingV1ReportsUsersInvoiceSumApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Reports/usersInvoiceSum`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          CountryId: queryArg.countryId,
        },
      }),
    }),
    postApiBillingV1ServicesActionBegin: build.mutation<
      PostApiBillingV1ServicesActionBeginApiResponse,
      PostApiBillingV1ServicesActionBeginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services/action/begin`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1ServicesActionEnd: build.mutation<
      PostApiBillingV1ServicesActionEndApiResponse,
      PostApiBillingV1ServicesActionEndApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services/action/end`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingV1ServicesActionEndactive: build.mutation<
      PostApiBillingV1ServicesActionEndactiveApiResponse,
      PostApiBillingV1ServicesActionEndactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services/action/endactive`,
        method: 'POST',
        params: {
          userId: queryArg,
        },
      }),
    }),
    postApiBillingV1Services: build.mutation<
      PostApiBillingV1ServicesApiResponse,
      PostApiBillingV1ServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1Services: build.query<
      GetApiBillingV1ServicesApiResponse,
      GetApiBillingV1ServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1ServicesById: build.query<
      GetApiBillingV1ServicesByIdApiResponse,
      GetApiBillingV1ServicesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/V1/Services/${queryArg}` }),
    }),
    deleteApiBillingV1ServicesById: build.mutation<
      DeleteApiBillingV1ServicesByIdApiResponse,
      DeleteApiBillingV1ServicesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingV1ServicesCount: build.query<
      GetApiBillingV1ServicesCountApiResponse,
      GetApiBillingV1ServicesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Services/count`,
        params: {
          requestDto: queryArg,
        },
      }),
    }),
    putApiBillingV1ServicesListLinks: build.mutation<
      PutApiBillingV1ServicesListLinksApiResponse,
      PutApiBillingV1ServicesListLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesListLinks`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    getApiBillingV1ServicesListLinks: build.query<
      GetApiBillingV1ServicesListLinksApiResponse,
      GetApiBillingV1ServicesListLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesListLinks`,
        params: {
          ServicesListId: queryArg.servicesListId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiBillingV1ServicesListLinksCount: build.query<
      GetApiBillingV1ServicesListLinksCountApiResponse,
      GetApiBillingV1ServicesListLinksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesListLinks/count`,
        params: {
          ServicesListId: queryArg,
        },
      }),
    }),
    deleteApiBillingV1ServicesListLinksByTargetId: build.mutation<
      DeleteApiBillingV1ServicesListLinksByTargetIdApiResponse,
      DeleteApiBillingV1ServicesListLinksByTargetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesListLinks/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingV1ServicesListsById: build.query<
      GetApiBillingV1ServicesListsByIdApiResponse,
      GetApiBillingV1ServicesListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists/${queryArg}`,
      }),
    }),
    patchApiBillingV1ServicesListsById: build.mutation<
      PatchApiBillingV1ServicesListsByIdApiResponse,
      PatchApiBillingV1ServicesListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateServicesListRequestDto,
      }),
    }),
    deleteApiBillingV1ServicesListsById: build.mutation<
      DeleteApiBillingV1ServicesListsByIdApiResponse,
      DeleteApiBillingV1ServicesListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingV1ServicesLists: build.query<
      GetApiBillingV1ServicesListsApiResponse,
      GetApiBillingV1ServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists`,
        params: {
          ServiceId: queryArg.serviceId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    postApiBillingV1ServicesLists: build.mutation<
      PostApiBillingV1ServicesListsApiResponse,
      PostApiBillingV1ServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1ServicesListsCount: build.query<
      GetApiBillingV1ServicesListsCountApiResponse,
      GetApiBillingV1ServicesListsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/ServicesLists/count`,
        params: {
          ServiceId: queryArg,
        },
      }),
    }),
    postApiBillingV1Stages: build.mutation<
      PostApiBillingV1StagesApiResponse,
      PostApiBillingV1StagesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Stages`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1Stages: build.query<
      GetApiBillingV1StagesApiResponse,
      GetApiBillingV1StagesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Stages`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1StagesById: build.query<
      GetApiBillingV1StagesByIdApiResponse,
      GetApiBillingV1StagesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/V1/Stages/${queryArg}` }),
    }),
    deleteApiBillingV1StagesById: build.mutation<
      DeleteApiBillingV1StagesByIdApiResponse,
      DeleteApiBillingV1StagesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Stages/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingV1StagesCount: build.query<
      GetApiBillingV1StagesCountApiResponse,
      GetApiBillingV1StagesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Stages/count`,
        params: {
          requestDto: queryArg,
        },
      }),
    }),
    getApiBillingV1TargetsServicesLists: build.query<
      GetApiBillingV1TargetsServicesListsApiResponse,
      GetApiBillingV1TargetsServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/TargetsServicesLists`,
        params: {
          TargetsIds: queryArg,
        },
      }),
    }),
    postApiBillingV1Tariffs: build.mutation<
      PostApiBillingV1TariffsApiResponse,
      PostApiBillingV1TariffsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Tariffs`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingV1Tariffs: build.query<
      GetApiBillingV1TariffsApiResponse,
      GetApiBillingV1TariffsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Tariffs`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingV1TariffsById: build.query<
      GetApiBillingV1TariffsByIdApiResponse,
      GetApiBillingV1TariffsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/V1/Tariffs/${queryArg}` }),
    }),
    deleteApiBillingV1TariffsById: build.mutation<
      DeleteApiBillingV1TariffsByIdApiResponse,
      DeleteApiBillingV1TariffsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Tariffs/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingV1TariffsCount: build.query<
      GetApiBillingV1TariffsCountApiResponse,
      GetApiBillingV1TariffsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/V1/Tariffs/count`,
        params: {
          requestDto: queryArg,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiBillingV1AuxClearInMemoryCacheApiResponse =
  /** status 200 Success */ ResponseBase;
export type GetApiBillingV1AuxClearInMemoryCacheApiArg = void;
export type GetApiBillingV1BalancesApiResponse =
  /** status 200 Success */ BalanceDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1BalancesApiArg = {
  balanceId?: string;
  userId?: string;
  countryId?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1BalancesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1BalancesCountApiArg = {
  balanceId?: string;
  userId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiBillingV1BalancesTransactionsApiResponse =
  /** status 200 Success */ BalanceTransactionDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1BalancesTransactionsApiArg = {
  balanceId?: string;
  userId?: string;
  operation?: Operation;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1BalancesTransactionsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1BalancesTransactionsCountApiArg = {
  balanceId?: string;
  userId?: string;
  operation?: Operation;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiBillingV1BalancesActionCreateApiResponse =
  /** status 200 Success */ GuidResponseBase;
export type PostApiBillingV1BalancesActionCreateApiArg =
  CreateBalanceRequestDto;
export type PostApiBillingV1BalancesActionSetApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionSetApiArg = SetBalanceRequestDto;
export type PostApiBillingV1BalancesActionCreditApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionCreditApiArg =
  CreditBalanceRequestDto;
export type PostApiBillingV1BalancesActionDebitApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionDebitApiArg = DebitBalanceRequestDto;
export type PostApiBillingV1BalancesActionResetApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionResetApiArg = ResetBalanceRequestDto;
export type PostApiBillingV1BalancesActionFreezeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionFreezeApiArg =
  FreezeBalanceRequestDto;
export type PostApiBillingV1BalancesActionUnfreezeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BalancesActionUnfreezeApiArg =
  UnfreezeBalanceRequestDto;
export type GetApiBillingV1BillingsByBillingIdApiResponse =
  /** status 200 Success */ BillingDtoResponseBase;
export type GetApiBillingV1BillingsByBillingIdApiArg = string;
export type GetApiBillingV1BillingsApiResponse =
  /** status 200 Success */ BillingDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1BillingsApiArg = {
  balanceId?: string;
  sessionId?: string;
  userId?: string;
  status?: BillingStatus;
  dateFrom?: string;
  dateTo?: string;
  serviceType?: ServiceType;
  chargePointsIds?: string[];
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1BillingsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1BillingsCountApiArg = {
  balanceId?: string;
  sessionId?: string;
  userId?: string;
  status?: BillingStatus;
  dateFrom?: string;
  dateTo?: string;
  serviceType?: ServiceType;
  chargePointsIds?: string[];
};
export type PostApiBillingV1BillingsActionCloseApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BillingsActionCloseApiArg = CloseBillingRequestDto;
export type PostApiBillingV1BillingsActionCloseactiveApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BillingsActionCloseactiveApiArg =
  CloseActiveBillingRequestDto;
export type PostApiBillingV1BillingsActionCalculatelastApiResponse =
  /** status 200 Success */ BillingDtoResponseBase;
export type PostApiBillingV1BillingsActionCalculatelastApiArg =
  CalculateUserLastBillingPaymentRequestDto;
export type PostApiBillingV1BillingsActionRetrypayApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1BillingsActionRetrypayApiArg =
  RetryPayBillingRequestDto;
export type GetApiBillingV1MetricsGraphTotalRevenueApiResponse =
  /** status 200 Success */ TotalRevenueDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1MetricsGraphTotalRevenueApiArg = {
  dateFrom?: string;
  dateTo?: string;
  groupByPeriod?: GroupByPeriod;
};
export type GetApiBillingV1ReportsUsersPaymentSumApiResponse =
  /** status 200 Success */ UsersPaymentSumReportDtoResponseBase;
export type GetApiBillingV1ReportsUsersPaymentSumApiArg = {
  dateFrom?: string;
  dateTo?: string;
  balancesIds?: string[];
  usersIds?: string[];
  countryId?: string;
};
export type GetApiBillingV1ReportsUsersInvoiceSumApiResponse =
  /** status 200 Success */ UsersInvoiceSumReportDtoResponseBase;
export type GetApiBillingV1ReportsUsersInvoiceSumApiArg = {
  dateFrom?: string;
  dateTo?: string;
  countryId?: string;
};
export type PostApiBillingV1ServicesActionBeginApiResponse =
  /** status 200 Success */ GuidResponseBase;
export type PostApiBillingV1ServicesActionBeginApiArg =
  BeginProvidedServiceRequestDto;
export type PostApiBillingV1ServicesActionEndApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1ServicesActionEndApiArg =
  CompleteProvidedServiceRequestDto;
export type PostApiBillingV1ServicesActionEndactiveApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingV1ServicesActionEndactiveApiArg = string | undefined;
export type PostApiBillingV1ServicesApiResponse =
  /** status 200 Success */ ServiceDtoResponseBase;
export type PostApiBillingV1ServicesApiArg = CreateServiceRequestDto;
export type GetApiBillingV1ServicesApiResponse =
  /** status 200 Success */ ServiceDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1ServicesApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1ServicesByIdApiResponse =
  /** status 200 Success */ ServiceDtoResponseBase;
export type GetApiBillingV1ServicesByIdApiArg = string;
export type DeleteApiBillingV1ServicesByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingV1ServicesByIdApiArg = string;
export type GetApiBillingV1ServicesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1ServicesCountApiArg =
  | GetServicesCountByFilterRequestDto
  | undefined;
export type PutApiBillingV1ServicesListLinksApiResponse =
  /** status 200 Success */ ServicesListLinkDtoIReadOnlyCollectionResponseBase;
export type PutApiBillingV1ServicesListLinksApiArg =
  CreateOrUpdateServicesListsLinksRequestDto;
export type GetApiBillingV1ServicesListLinksApiResponse =
  /** status 200 Success */ ServicesListLinkDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1ServicesListLinksApiArg = {
  servicesListId?: string;
  limit?: number;
  offset?: number;
};
export type GetApiBillingV1ServicesListLinksCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1ServicesListLinksCountApiArg = string | undefined;
export type DeleteApiBillingV1ServicesListLinksByTargetIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingV1ServicesListLinksByTargetIdApiArg = string;
export type GetApiBillingV1ServicesListsByIdApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type GetApiBillingV1ServicesListsByIdApiArg = string;
export type PatchApiBillingV1ServicesListsByIdApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type PatchApiBillingV1ServicesListsByIdApiArg = {
  id: string;
  updateServicesListRequestDto: UpdateServicesListRequestDto;
};
export type DeleteApiBillingV1ServicesListsByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingV1ServicesListsByIdApiArg = string;
export type GetApiBillingV1ServicesListsApiResponse =
  /** status 200 Success */ ServicesListDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1ServicesListsApiArg = {
  serviceId?: string;
  limit?: number;
  offset?: number;
};
export type PostApiBillingV1ServicesListsApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type PostApiBillingV1ServicesListsApiArg = CreateServicesListRequestDto;
export type GetApiBillingV1ServicesListsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1ServicesListsCountApiArg = string | undefined;
export type PostApiBillingV1StagesApiResponse =
  /** status 200 Success */ StageDtoResponseBase;
export type PostApiBillingV1StagesApiArg = CreateStageRequestDto;
export type GetApiBillingV1StagesApiResponse =
  /** status 200 Success */ StageDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1StagesApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1StagesByIdApiResponse =
  /** status 200 Success */ StageDtoResponseBase;
export type GetApiBillingV1StagesByIdApiArg = string;
export type DeleteApiBillingV1StagesByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingV1StagesByIdApiArg = string;
export type GetApiBillingV1StagesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1StagesCountApiArg =
  | GetStagesCountByFilterRequestDto
  | undefined;
export type GetApiBillingV1TargetsServicesListsApiResponse =
  /** status 200 Success */ TargetsServicesListsDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1TargetsServicesListsApiArg = string[];
export type PostApiBillingV1TariffsApiResponse =
  /** status 200 Success */ TariffDtoResponseBase;
export type PostApiBillingV1TariffsApiArg = CreateTariffRequestDto;
export type GetApiBillingV1TariffsApiResponse =
  /** status 200 Success */ TariffDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingV1TariffsApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingV1TariffsByIdApiResponse =
  /** status 200 Success */ TariffDtoResponseBase;
export type GetApiBillingV1TariffsByIdApiArg = string;
export type DeleteApiBillingV1TariffsByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingV1TariffsByIdApiArg = string;
export type GetApiBillingV1TariffsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingV1TariffsCountApiArg =
  | GetTariffsCountByFilterRequestDto
  | undefined;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BalanceName = 'PERSONAL' | 'BUSINESS';
export type BalanceDto = {
  id: string;
  name: BalanceName;
  userId: string;
  value: number;
  countryId: string;
  isFrozen: boolean;
  createdDate: string;
  lastUpdate: string;
};
export type BalanceDtoIReadOnlyCollectionResponseBase = {
  data?: BalanceDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type Operation = 'UNDEFINED' | 'DEBIT' | 'CREDIT' | 'RESET' | 'SET';
export type BalanceTransactionDto = {
  id: string;
  balanceId: string;
  operation: Operation;
  value: number;
  reason: string;
  createdDate: string;
};
export type BalanceTransactionDtoIReadOnlyCollectionResponseBase = {
  data?: BalanceTransactionDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GuidResponseBase = {
  data?: string;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateBalanceRequestDto = {
  userId: string;
  countryId: string;
};
export type SetBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type CreditBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type DebitBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type ResetBalanceRequestDto = {
  balanceId: string;
};
export type FreezeBalanceRequestDto = {
  balanceId: string;
};
export type UnfreezeBalanceRequestDto = {
  balanceId: string;
};
export type BillingStatus = 'ERROR' | 'IN_PROCESS' | 'COMPLETED';
export type PaymentStatus =
  | 'NOT_PAID'
  | 'ERROR'
  | 'IN_PROCESS'
  | 'NOT_REQUIRED'
  | 'PAID'
  | 'ERROR_PAYMENT';
export type FiscalStatus =
  | 'NOT_FISCALIZED'
  | 'ERROR'
  | 'TIME_OUT_ERROR'
  | 'IN_PROCESS'
  | 'NOT_REQUIRED'
  | 'FISCALIZED';
export type ServiceType = 'RESERVING' | 'CHARGING' | 'PARKING';
export type ServiceStatus = 'ERROR' | 'RUNNING' | 'COMPLETED';
export type StageEndType =
  | 'MANUAL'
  | 'RIGHT_NOW'
  | 'ENERGY_PERCENT'
  | 'TIME_MINUTE'
  | 'POWER_LOSS_KW';
export type TariffType = 'ENERGY_KWH' | 'FLAT_FEE' | 'TIME_MINUTE';
export type ProvidedStageDto = {
  id: string;
  providedServiceId: string;
  stageId: string;
  stageName: string;
  stageEndType: StageEndType;
  tariffId: string;
  tariffName: string;
  tariffType: TariffType;
  tariffPrice: number;
  beginDate: string;
  endDate?: string | null;
  value?: number | null;
  payment: number;
  discount: number;
  benefitPayment: number;
  endConditionValue: number;
  reachedEndConditionDate?: string | null;
  lastUpdate: string;
};
export type ProvidedServiceDto = {
  id: string;
  serviceId: string;
  serviceName: string;
  serviceType: ServiceType;
  billingId: string;
  sessionId: string;
  status: ServiceStatus;
  beginDate: string;
  endDate?: string | null;
  payment: number;
  discount: number;
  benefitPayment: number;
  lastUpdate: string;
  providedStages: ProvidedStageDto[];
};
export type BillingDto = {
  id: string;
  userId: string;
  balanceId: string;
  status: BillingStatus;
  paymentStatus: PaymentStatus;
  fiscalStatus: FiscalStatus;
  createdDate: string;
  closedDate?: string | null;
  balancePayment: number;
  discount: number;
  payment: number;
  bonusesPayment: number;
  benefitPayment: number;
  invoiceSum: number;
  chargePointId: string;
  mobileAppId: string;
  balanceName: BalanceName;
  countryId: string;
  lastUpdate: string;
  providedServices: ProvidedServiceDto[];
};
export type BillingDtoResponseBase = {
  data?: BillingDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BillingDtoIReadOnlyCollectionResponseBase = {
  data?: BillingDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CloseBillingRequestDto = {
  billingId: string;
};
export type CloseActiveBillingRequestDto = {
  userId: string;
};
export type CalculateUserLastBillingPaymentRequestDto = {
  userId: string;
};
export type RetryPayBillingRequestDto = {
  billingId: string;
};
export type TotalRevenueDto = {
  date: string;
  value: number;
};
export type TotalRevenueDtoIReadOnlyCollectionResponseBase = {
  data?: TotalRevenueDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GroupByPeriod = 'DAY' | 'MONTH';
export type UserCalculatedValueDto = {
  userId: string;
  calculatedValue: number;
};
export type UsersPaymentSumReportDto = {
  usersCalculatedValues: UserCalculatedValueDto[];
};
export type UsersPaymentSumReportDtoResponseBase = {
  data?: UsersPaymentSumReportDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UsersInvoiceSumReportDto = {
  usersCalculatedValues: UserCalculatedValueDto[];
};
export type UsersInvoiceSumReportDtoResponseBase = {
  data?: UsersInvoiceSumReportDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ServiceUserSettings = {
  reserveTimeMinute?: number | null;
};
export type BeginProvidedServiceRequestDto = {
  userId: string;
  sessionId: string;
  balanceId: string;
  serviceId: string;
  chargePointId: string;
  mobileAppId: string;
  serviceUserSettings?: ServiceUserSettings;
};
export type CompleteProvidedServiceRequestDto = {
  providedServiceId: string;
};
export type TariffDto = {
  id: string;
  name: string;
  countryId: string;
  type: TariffType;
  price: number;
  createdDate: string;
};
export type StageWithTariffDto = {
  id: string;
  name: string;
  serviceId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
  createdDate: string;
  tariff: TariffDto;
};
export type ServiceDto = {
  id: string;
  name: string;
  type: ServiceType;
  createdDate: string;
  stages?: StageWithTariffDto[] | null;
};
export type ServiceDtoResponseBase = {
  data?: ServiceDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateServiceRequestDto = {
  type: ServiceType;
  name: string;
};
export type ServiceDtoIReadOnlyCollectionResponseBase = {
  data?: ServiceDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetServicesCountByFilterRequestDto = object;
export type TargetName = 'CHARGE_POINT' | 'CONNECTOR';
export type ServicesListLinkDto = {
  targetId: string;
  targetName: TargetName;
  servicesListId: string;
  lastUpdate: string;
};
export type ServicesListLinkDtoIReadOnlyCollectionResponseBase = {
  data?: ServicesListLinkDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateOrUpdateServicesListLinkDataDto = {
  targetId: string;
  targetName: TargetName;
  servicesListId: string;
};
export type CreateOrUpdateServicesListsLinksRequestDto = {
  servicesListsLinks?: CreateOrUpdateServicesListLinkDataDto[] | null;
};
export type ServicesListDto = {
  id: string;
  name: string;
  lastUpdate: string;
  services?: ServiceDto[] | null;
};
export type ServicesListDtoResponseBase = {
  data?: ServicesListDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateServicesListRequestDto = {
  name?: string | null;
  servicesIds?: string[] | null;
};
export type ServicesListDtoIReadOnlyCollectionResponseBase = {
  data?: ServicesListDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateServicesListRequestDto = {
  name: string;
  servicesIds?: string[] | null;
};
export type StageDto = {
  id: string;
  name: string;
  serviceId: string;
  tariffId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
  createdDate: string;
};
export type StageDtoResponseBase = {
  data?: StageDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateStageRequestDto = {
  name: string;
  serviceId: string;
  tariffId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
};
export type StageDtoIReadOnlyCollectionResponseBase = {
  data?: StageDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetStagesCountByFilterRequestDto = object;
export type TargetsServicesListsDto = {
  targetId: string;
  targetName: TargetName;
  servicesListId: string;
  name: string;
  lastUpdate: string;
  services: ServiceDto[];
};
export type TargetsServicesListsDtoIReadOnlyCollectionResponseBase = {
  data?: TargetsServicesListsDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type TariffDtoResponseBase = {
  data?: TariffDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateTariffRequestDto = {
  name: string;
  countryId: string;
  type: TariffType;
  price: number;
};
export type TariffDtoIReadOnlyCollectionResponseBase = {
  data?: TariffDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetTariffsCountByFilterRequestDto = object;
export const {
  useGetApiBillingV1AuxClearInMemoryCacheQuery,
  useGetApiBillingV1BalancesQuery,
  useGetApiBillingV1BalancesCountQuery,
  useGetApiBillingV1BalancesTransactionsQuery,
  useGetApiBillingV1BalancesTransactionsCountQuery,
  usePostApiBillingV1BalancesActionCreateMutation,
  usePostApiBillingV1BalancesActionSetMutation,
  usePostApiBillingV1BalancesActionCreditMutation,
  usePostApiBillingV1BalancesActionDebitMutation,
  usePostApiBillingV1BalancesActionResetMutation,
  usePostApiBillingV1BalancesActionFreezeMutation,
  usePostApiBillingV1BalancesActionUnfreezeMutation,
  useGetApiBillingV1BillingsByBillingIdQuery,
  useGetApiBillingV1BillingsQuery,
  useGetApiBillingV1BillingsCountQuery,
  usePostApiBillingV1BillingsActionCloseMutation,
  usePostApiBillingV1BillingsActionCloseactiveMutation,
  usePostApiBillingV1BillingsActionCalculatelastMutation,
  usePostApiBillingV1BillingsActionRetrypayMutation,
  useGetApiBillingV1MetricsGraphTotalRevenueQuery,
  useGetApiBillingV1ReportsUsersPaymentSumQuery,
  useGetApiBillingV1ReportsUsersInvoiceSumQuery,
  usePostApiBillingV1ServicesActionBeginMutation,
  usePostApiBillingV1ServicesActionEndMutation,
  usePostApiBillingV1ServicesActionEndactiveMutation,
  usePostApiBillingV1ServicesMutation,
  useGetApiBillingV1ServicesQuery,
  useGetApiBillingV1ServicesByIdQuery,
  useDeleteApiBillingV1ServicesByIdMutation,
  useGetApiBillingV1ServicesCountQuery,
  usePutApiBillingV1ServicesListLinksMutation,
  useGetApiBillingV1ServicesListLinksQuery,
  useGetApiBillingV1ServicesListLinksCountQuery,
  useDeleteApiBillingV1ServicesListLinksByTargetIdMutation,
  useGetApiBillingV1ServicesListsByIdQuery,
  usePatchApiBillingV1ServicesListsByIdMutation,
  useDeleteApiBillingV1ServicesListsByIdMutation,
  useGetApiBillingV1ServicesListsQuery,
  usePostApiBillingV1ServicesListsMutation,
  useGetApiBillingV1ServicesListsCountQuery,
  usePostApiBillingV1StagesMutation,
  useGetApiBillingV1StagesQuery,
  useGetApiBillingV1StagesByIdQuery,
  useDeleteApiBillingV1StagesByIdMutation,
  useGetApiBillingV1StagesCountQuery,
  useGetApiBillingV1TargetsServicesListsQuery,
  usePostApiBillingV1TariffsMutation,
  useGetApiBillingV1TariffsQuery,
  useGetApiBillingV1TariffsByIdQuery,
  useDeleteApiBillingV1TariffsByIdMutation,
  useGetApiBillingV1TariffsCountQuery,
} = injectedRtkApi;
