import NiceModal from '@ebay/nice-modal-react';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { ConnectorVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { PopoverContentLayout } from 'shared/ui/ui-popover/content-layout';
import { EditIcon } from 'shared/icons/edit';

import { EditConnectorModal } from 'features/connector/edit';
import { DeleteConnectorModal } from 'features/connector/delete';
import { CommandModal } from 'features/command';

import { AVAILABLE_COMMAND_TYPE } from '../../../consts';
import { AvailableCommandType } from '../../../model';
import { IconContainer } from './styles';

type Props = {
  chargePointId: string;
  connector: ConnectorVm;
};

export function Actions({ chargePointId, connector }: Props) {
  const ability = useAbility();

  if (
    !ability.can('Execute', 'PrivateMethods') &&
    !ability.can('Write', 'Connector')
  ) {
    return null;
  }

  const showEditConnectorModal = () => {
    NiceModal.show(EditConnectorModal, {
      chargePointId,
      connector,
    });
  };

  const showDeleteConnectorModal = () => {
    NiceModal.show(DeleteConnectorModal, {
      chargePointId,
      connectorId: connector.id,
    });
  };

  const showCommandModal = (type: AvailableCommandType) => {
    NiceModal.show(CommandModal, {
      commandType: type,
      chargePointId,
      connectorId: connector.id,
      innerConnectorId: String(connector.innerConnectorId),
    });
  };

  const commands = ability.can('Execute', 'PrivateMethods') ? (
    <>
      {(
        Object.keys(AVAILABLE_COMMAND_TYPE) as unknown as Array<
          keyof typeof AVAILABLE_COMMAND_TYPE
        >
      ).map((type) => {
        return (
          <button
            key={AVAILABLE_COMMAND_TYPE[type]}
            onClick={() => {
              showCommandModal(type);
            }}
          >
            {AVAILABLE_COMMAND_TYPE[type]}
          </button>
        );
      })}
    </>
  ) : null;

  const connectorActions = ability.can('Write', 'Connector') ? (
    <>
      <button onClick={showEditConnectorModal}>Редактировать</button>
      <button onClick={showDeleteConnectorModal}>Удалить</button>
    </>
  ) : null;

  const content = (
    <PopoverContentLayout contentGroups={[commands, connectorActions]} />
  );

  return (
    <UiPopover content={content}>
      <IconContainer>
        <EditIcon />
      </IconContainer>
    </UiPopover>
  );
}
