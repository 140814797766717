import useWindowFocus from 'use-window-focus';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText, openErrorNotification } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';
import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { ChargePointInfoLayout } from './layout';

type Props = {
  chargePointId: string;
};

export function ChargePointInfoContainer({ chargePointId }: Props) {
  const isWindowFocused = useWindowFocus();

  const chargePointQuery = useGetChargePoint(chargePointId, {
    pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
  });
  const companiesQuery = useGetCompanies(undefined);
  const countriesQuery = useGetCountries(undefined);

  const { isFetching, error, data, currentData, isSuccess } = useGetChargePoint(
    chargePointId,
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  if (chargePointQuery.isLoading) {
    return <div>Загрузка данных...</div>;
  }

  if (companiesQuery.error) {
    openErrorNotification(getServerErrorText(companiesQuery.error));
  }

  if (companiesQuery.data?.status === 'ERROR') {
    openErrorNotification(
      companiesQuery.data.statusMessage ??
        'Не удалось загрузить список компаний'
    );
  }

  if (countriesQuery.error) {
    openErrorNotification(getServerErrorText(countriesQuery.error));
  }

  if (countriesQuery.data?.status === 'ERROR') {
    openErrorNotification(
      countriesQuery.data.statusMessage ?? 'Не удалось загрузить список стран'
    );
  }

  if (chargePointQuery.error) {
    return <ErrorMessage text={getServerErrorText(chargePointQuery.error)} />;
  }

  if (chargePointQuery.data?.status === 'ERROR') {
    return <ErrorMessage text={chargePointQuery.data.statusMessage} />;
  }

  if (!chargePointQuery.data?.data) {
    return <EmptyData />;
  }

  return (
    <ChargePointInfoLayout
      chargePoint={chargePointQuery.data.data}
      companies={companiesQuery.data?.data ?? []}
      countries={countriesQuery.data?.data ?? []}
    />
  );
}
