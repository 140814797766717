import { Col, Progress, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { UploadProps } from 'antd/es/upload/interface';
import { useEffect, useMemo, useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { CustomUploadDragger } from 'shared/ui/form/custom-upload-dragger';
import { GetFirmwareVm } from 'shared/api/services/chargepoint/rtk/generated/firmwares';

import { FORMAT_URL } from 'entities/firmware';

//
import { UploadedFile } from 'features/firmware/add/ui/uploaded-file';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';

import { useUpdateFirmware } from '../hooks/use-update-firmware';
import { UploadDraggerButton } from 'shared/ui/ui-kit/upload-dragger/button';

const getDefaultValues = (firmware: GetFirmwareVm): FormInput => {
  const { description, name, formatUrl } = firmware;

  return {
    name,
    description: description ?? '',
    formatUrl,
  };
};

type Props = {
  firmware: GetFirmwareVm;
};

export function Form({ firmware }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(() => {
      return getDefaultValues(firmware);
    }, [firmware]),
  });

  useEffect(() => {
    form.reset({ ...getDefaultValues(firmware) });
  }, [firmware]);

  const { handleUpdate, isLoading, progress } = useUpdateFirmware();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, id: firmware.id });
  });

  return (
    <UiCard>
      <UiCard.Header>{firmware.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields isDisabled={isDisabled} percent={progress} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  isDisabled: boolean;
  percent: number;
};

Form.Fields = function Fields({ isDisabled, percent }: FieldsProps) {
  // console.log('percent', percent);

  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  console.log('errors', errors);

  const props: UploadProps<File> = {
    maxCount: 1,
    multiple: false,
    beforeUpload: (_file, _list) => {
      return false;
    },
    listType: 'picture',
    itemRender: (originNode, file, _fileList, actions) => {
      return (
        <>
          <UploadedFile
            fileName={file.name}
            handleDelete={() => actions.remove()}
          />
          {/* {percent > 0 ? <Progress percent={percent} /> : null} */}
        </>
      );
    },
  };

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="formatUrl"
            options={Object.entries(FORMAT_URL).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            })}
            label="formatUrl"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomUploadDragger<FormInput>
            {...props}
            name="file"
            label="Файл прошивки"
            disabled={isDisabled}
          >
            <UploadDraggerButton disabled={isDisabled} />
          </CustomUploadDragger>
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
