import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  enhancedApi,
  useAddServiceMutation,
  useAddStageMutation,
  useCreateServicesListMutation,
  usePutServicesListLinks,
  useUpdateServicesListMutation,
} from 'shared/api/services/billing/rtk/enhanced';
import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Список услуг добавлен';
const ERROR_MSG = 'Не удалось добавить список услуг';

export const useCreateServicesList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addServicesList] = useCreateServicesListMutation();
  const [addService] = useAddServiceMutation();
  const [updateServicesList] = useUpdateServicesListMutation();
  const [addStage] = useAddStageMutation();
  const [setServicesListLinks] = usePutServicesListLinks();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  let servicesIds: string[] = [];

  const handleCreate = async (data: FormOutput) => {
    const { chargingStages, reservingStages, name, chargePointIds } = data;

    try {
      setIsLoading(true);

      // 1.Создание списка услуг
      //
      const addServicesListRes = await addServicesList({ name }).unwrap();

      const servicesListId = addServicesListRes.data?.id;

      if (!servicesListId) {
        throw 'Неизвестная ошибка';
      }

      // 2. Услуга "Заряд"
      //

      if (chargingStages.length) {
        // Создание услуги
        //
        const addServiceRes = await addService({
          name: 'Услуга Заряд',
          type: 'CHARGING',
        }).unwrap();

        const serviceId = addServiceRes.data?.id;

        if (!serviceId) {
          throw 'Неизвестная ошибка';
        }

        servicesIds.push(serviceId);

        // Добавление стадий к услуге
        //
        const addStagesRes = await Promise.all(
          chargingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      // 3. Услуга "Бронирование"
      //

      if (reservingStages.length) {
        // Создание услуги
        //
        const addServiceRes = await addService({
          name: 'Услуга Бронирование',
          type: 'RESERVING',
        }).unwrap();

        const serviceId = addServiceRes.data?.id;

        if (!serviceId) {
          throw 'Неизвестная ошибка';
        }

        servicesIds.push(serviceId);

        // Добавление стадий к услуге
        //
        const addStagesRes = await Promise.all(
          reservingStages.map((stage) =>
            addStage({ ...stage, serviceId }).unwrap()
          )
        );
      }

      // 4. Добавление услуг в список услуг
      //

      const updateServicesListRes = await updateServicesList({
        id: servicesListId,
        updateServicesListRequestDto: {
          servicesIds,
        },
      }).unwrap();

      // 5. Привязка списка услуг к выбранным ЭЗС
      //

      if (chargePointIds?.length) {
        const setServicesListLinksRes = await setServicesListLinks({
          servicesListsLinks: chargePointIds.map((el) => ({
            servicesListId: servicesListId,
            targetName: 'CHARGE_POINT',
            targetId: el,
          })),
        });
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'ServicesLists', id: 'LIST' }])
      );

      navigate(BILLING_ROUTES.LIST);
    } catch (err) {
      // console.log('err', err);

      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreate,
    isLoading,
  };
};
