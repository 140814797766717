import {
  useGetBalances,
  useGetBonusProgramAchievements,
} from 'shared/api/services/bonus/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { getErrorMessage } from 'shared/lib/http';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { BonusesSummaryLayout } from './layout';

type Props = {
  userId: string;
};

export function Container({ userId }: Props) {
  const achievementsQuery = useGetBonusProgramAchievements(userId);
  const countriesQuery = useGetCountries(undefined);
  const balancesQuery = useGetBalances({ userId });

  const isLoading =
    achievementsQuery.isLoading ||
    countriesQuery.isLoading ||
    balancesQuery.isLoading;
  const error =
    achievementsQuery.error || countriesQuery.error || balancesQuery.error;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getErrorMessage(error)} />;
  }

  if (
    !achievementsQuery.data?.data ||
    !countriesQuery.data?.data ||
    !balancesQuery.data?.data
  ) {
    return <EmptyData />;
  }

  return (
    <BonusesSummaryLayout
      achievement={achievementsQuery.data.data}
      countries={countriesQuery.data.data}
      balances={balancesQuery.data.data}
      userId={userId}
    />
  );
}
