import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { BankDto } from 'shared/api/services/payment/rtk/generated';
import { CustomRangePicker } from 'shared/ui/form/custom-range-picker';
import { getQueryParams } from 'shared/lib/router/get-query-params';
import { DATE_FORMAT_REVERSE } from 'shared/lib';

import { FormContainer } from './styles';
import { FormInput, FormOutput, FormSchema } from '../../consts';

const getDefaultValues = ({
  bankId,
  dateFrom,
  dateTo,
}: {
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  bankId?: string | undefined;
}): FormInput => {
  return {
    bankId,
    dateRange: [
      dateFrom === undefined ? null : dayjs(dateFrom),
      dateTo === undefined ? null : dayjs(dateTo),
    ],
  };
};

type Props = {
  banks: BankDto[];
  isLoading: boolean;
  dateFrom?: string | undefined;
  dateTo?: string | undefined;
  bankId?: string | undefined;
};

export function Form({ banks, isLoading, bankId, dateFrom, dateTo }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues({ bankId, dateFrom, dateTo }),
  });

  useEffect(() => {
    form.reset({ ...getDefaultValues({ bankId, dateFrom, dateTo }) });
  }, [bankId, dateFrom, dateTo]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = form.handleSubmit(async (data) => {
    const queryParams = getQueryParams();

    const { bankId, dateRange } = data;

    if (!bankId) {
      delete queryParams['bankId'];
    } else {
      queryParams['bankId'] = bankId;
    }

    if (dateRange === null || dateRange === undefined) {
      delete queryParams['dateFrom'];
      delete queryParams['dateTo'];
    } else {
      const from = dateRange[0];
      const to = dateRange[1];

      if (from) {
        queryParams['dateFrom'] = dayjs(from).format(DATE_FORMAT_REVERSE);
      }

      if (to) {
        queryParams['dateTo'] = dayjs(to).format(DATE_FORMAT_REVERSE);
      }
    }

    setSearchParams({
      ...queryParams,
    });

    console.log('data', data);
    // set url params
  });

  return (
    <FormContainer>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields banks={banks} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </FormContainer>
  );
}

type FieldsProps = Pick<Props, 'banks'>;

Form.Fields = function Fields({ banks }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={15}>
        <Col span={8}>
          <CustomRangePicker<FormInput>
            name="dateRange"
            label="Период от и до"
            placeholder={['Дата начала', 'Дата конца']}
          />
        </Col>
        <Col span={8}>
          <CustomSelect<FormInput>
            name="bankId"
            options={banks.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Банк"
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton text="Получить отчет" />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
