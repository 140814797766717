import NiceModal from '@ebay/nice-modal-react';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { Modal } from '../modal';
import { ButtonLabel, IconContainer, StyledButton } from './styles';

type Props = {
  chargePointId: string;
};

export function Button({ chargePointId }: Props) {
  const onClick = () => {
    NiceModal.show(Modal, { chargePointId });
  };

  return (
    <StyledButton onClick={onClick} type="button">
      <ButtonLabel>Добавить фотографии</ButtonLabel>
      <IconContainer>
        <PlusInCircleIcon />
      </IconContainer>
    </StyledButton>
  );
}
