import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledContainer = styled.div``;

export const PhotoContainer = styled.div<{
  isError: boolean;
}>`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.isError ? COLOR.red : COLOR.grey}`};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const StyledImage = styled.div`
  border-radius: 2px;
  width: 60px;
  height: 60px;

  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ErrorText = styled.p`
  color: ${COLOR.red};
  font-size: 12px;
  line-height: 15px;
`;

export const Filename = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
`;

export const ControlsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;
