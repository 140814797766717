import NiceModal from '@ebay/nice-modal-react';

import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { UiPrimaryButton } from 'shared/ui/buttons';

import { Modal } from '../modal';

type Props = {
  userId: string;
  countries: GetCountryVm[];
};

export function ManageButton(props: Props) {
  const onClick = () => {
    NiceModal.show(Modal, { ...props });
  };

  return (
    <UiPrimaryButton onClick={onClick} style={{ padding: '7px 28px' }}>
      Изменить
    </UiPrimaryButton>
  );
}
