/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Identity
 * Сервис авторизации и прав пользователя
 * OpenAPI spec version: v1
 */
import type {
  DeleteApiIdentityV1GroupsParams,
  WebApiMiddlewareResponseBase,
  WebApiRequestAddGroupRequest,
  WebApiRequestAddRoleClaimRequest,
  WebApiRequestAddRoleRequest,
  WebApiRequestAddUserClaimRequest,
  WebApiRequestAddUserGroupRequest,
  WebApiRequestAddUserRequest,
  WebApiRequestAddUserRoleRequest,
  WebApiRequestDeleteRoleClaimRequest,
  WebApiRequestDeleteUserClaimRequest,
  WebApiRequestDeleteUserGroupRequest,
  WebApiRequestDeleteUserRoleRequest,
  WebApiRequestLoginRequest,
  WebApiRequestSetUserRightsRequest,
  WebApiRequestUpdateGroupRequest,
  WebApiRequestUpdateRoleRequest,
  WebApiRequestUpdateUserRequest,
  WebApiRequestUpdateUserRoleRequest,
  WebApiResponseAddRoleResponse,
  WebApiResponseGetGroupResponse,
  WebApiResponseGetGroupsResponse,
  WebApiResponseGetRightsResponse,
  WebApiResponseGetRoleClaimsResponse,
  WebApiResponseGetRoleResponse,
  WebApiResponseGetRolesResponse,
  WebApiResponseGetUserResponse,
  WebApiResponseGetUserRolesResponse,
  WebApiResponseGetUsersResponse,
  WebApiResponseLoginResponse,
  WebApiResponseRefreshTokensResponse,
  WebApiResponseUpdateGroupResponse,
  WebApiResponseUpdateRoleResponse,
} from './schemas';
import { customInstance } from '../../../../../axios';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getIdentity = () => {
  /**
   * @summary Получить токен доступа
   */
  const postApiIdentityV1UsersLogin = (
    webApiRequestLoginRequest: WebApiRequestLoginRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseLoginResponse>(
      {
        url: `/Api/Identity/V1/Users/Login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestLoginRequest,
      },
      options
    );
  };

  /**
   * @summary Получить новый токен доступа с помощью refresh token(в хедере)
   */
  const postApiIdentityV1UsersRefreshTokens = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseRefreshTokensResponse>(
      { url: `/Api/Identity/V1/Users/RefreshTokens`, method: 'POST' },
      options
    );
  };

  /**
   * @summary Заблокировать пользователя
   */
  const postApiIdentityV1UsersLoginLockoutUser = (
    login: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/Users/${login}/LockoutUser`, method: 'POST' },
      options
    );
  };

  /**
   * @summary Разблокировать пользователя
   */
  const postApiIdentityV1UsersLoginUnlockUser = (
    login: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/Users/${login}/UnlockUser`, method: 'POST' },
      options
    );
  };

  /**
   * @summary Получить пользоватлея
   */
  const getApiIdentityV1UsersUserName = (
    userName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetUserResponse>(
      { url: `/Api/Identity/V1/Users/${userName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Удалить пользователя
   */
  const deleteApiIdentityV1UsersUserName = (
    userName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/Users/${userName}`, method: 'DELETE' },
      options
    );
  };

  /**
   * @summary Получить всех пользователей
   */
  const getApiIdentityV1Users = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetUsersResponse>(
      { url: `/Api/Identity/V1/Users`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить пользователя
   */
  const postApiIdentityV1Users = (
    webApiRequestAddUserRequest: WebApiRequestAddUserRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/Users`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddUserRequest,
      },
      options
    );
  };

  /**
   * @summary Обновить пользователя
   */
  const patchApiIdentityV1Users = (
    webApiRequestUpdateUserRequest: WebApiRequestUpdateUserRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/Users`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestUpdateUserRequest,
      },
      options
    );
  };

  /**
   * @summary Добавить роли пользователю
   */
  const postApiIdentityV1UserRoles = (
    webApiRequestAddUserRoleRequest: WebApiRequestAddUserRoleRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserRoles`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddUserRoleRequest,
      },
      options
    );
  };

  /**
   * @summary Обновить роли пользователю
   */
  const putApiIdentityV1UserRoles = (
    webApiRequestUpdateUserRoleRequest: WebApiRequestUpdateUserRoleRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserRoles`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestUpdateUserRoleRequest,
      },
      options
    );
  };

  /**
   * @summary Удалить роли пользователю
   */
  const deleteApiIdentityV1UserRoles = (
    webApiRequestDeleteUserRoleRequest: WebApiRequestDeleteUserRoleRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserRoles`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestDeleteUserRoleRequest,
      },
      options
    );
  };

  /**
   * @summary Получить роли пользователя
   */
  const getApiIdentityV1UserRolesUserName = (
    userName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetUserRolesResponse>(
      { url: `/Api/Identity/V1/UserRoles/${userName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить группу пользователю
   */
  const postApiIdentityV1UserGroups = (
    webApiRequestAddUserGroupRequest: WebApiRequestAddUserGroupRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserGroups`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddUserGroupRequest,
      },
      options
    );
  };

  /**
   * @summary Удалить роль пользователю
   */
  const deleteApiIdentityV1UserGroups = (
    webApiRequestDeleteUserGroupRequest: WebApiRequestDeleteUserGroupRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserGroups`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestDeleteUserGroupRequest,
      },
      options
    );
  };

  /**
   * @summary Получить все утверждения пользователя
   */
  const getApiIdentityV1UserClaimsUserName = (
    userName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRoleClaimsResponse>(
      { url: `/Api/Identity/V1/UserClaims/${userName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить утверждение пользователю
   */
  const postApiIdentityV1UserClaims = (
    webApiRequestAddUserClaimRequest: WebApiRequestAddUserClaimRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserClaims`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddUserClaimRequest,
      },
      options
    );
  };

  /**
   * @summary Удалить утверждение пользователя
   */
  const deleteApiIdentityV1UserClaims = (
    webApiRequestDeleteUserClaimRequest: WebApiRequestDeleteUserClaimRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserClaims`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestDeleteUserClaimRequest,
      },
      options
    );
  };

  /**
   * @summary Получить роль системы
   */
  const getApiIdentityV1RolesRoleName = (
    roleName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRoleResponse>(
      { url: `/Api/Identity/V1/Roles/${roleName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Удалить роль системы
   */
  const deleteApiIdentityV1RolesRoleName = (
    roleName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/Roles/${roleName}`, method: 'DELETE' },
      options
    );
  };

  /**
   * @summary Получить все роли системы
   */
  const getApiIdentityV1Roles = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRolesResponse>(
      { url: `/Api/Identity/V1/Roles`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить роль в систему
   */
  const postApiIdentityV1Roles = (
    webApiRequestAddRoleRequest: WebApiRequestAddRoleRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseAddRoleResponse>(
      {
        url: `/Api/Identity/V1/Roles`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddRoleRequest,
      },
      options
    );
  };

  /**
   * @summary Обновить роль системы
   */
  const patchApiIdentityV1Roles = (
    webApiRequestUpdateRoleRequest: WebApiRequestUpdateRoleRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseUpdateRoleResponse>(
      {
        url: `/Api/Identity/V1/Roles`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestUpdateRoleRequest,
      },
      options
    );
  };

  /**
   * @summary Получить все утверждения роли
   */
  const getApiIdentityV1RoleClaimsRoleName = (
    roleName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRoleClaimsResponse>(
      { url: `/Api/Identity/V1/RoleClaims/${roleName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить утверждения для роли в систему
   */
  const postApiIdentityV1RoleClaims = (
    webApiRequestAddRoleClaimRequest: WebApiRequestAddRoleClaimRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddRoleClaimRequest,
      },
      options
    );
  };

  /**
   * @summary Удалить утверждение роли
   */
  const deleteApiIdentityV1RoleClaims = (
    webApiRequestDeleteRoleClaimRequest: WebApiRequestDeleteRoleClaimRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestDeleteRoleClaimRequest,
      },
      options
    );
  };

  /**
   * @summary Получить все права пользователя (только личные права пользователя, права его ролей не выдаются)
   */
  const getApiIdentityV1UserRightsUserName = (
    userName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRightsResponse>(
      { url: `/Api/Identity/V1/UserRights/${userName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Получить все права роли
   */
  const getApiIdentityV1RoleRightsRoleName = (
    roleName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetRightsResponse>(
      { url: `/Api/Identity/V1/RoleRights/${roleName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Автоматически устанавливает или удаляет все необходимые утверждения пользователю для заданных прав
   */
  const postApiIdentityV1UserRights = (
    webApiRequestSetUserRightsRequest: WebApiRequestSetUserRightsRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/UserRights`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestSetUserRightsRequest,
      },
      options
    );
  };

  /**
   * @summary Получить группу системы
   */
  const getApiIdentityV1GroupsGroupName = (
    groupName: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetGroupResponse>(
      { url: `/Api/Identity/V1/Groups/${groupName}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Получить все группы системы
   */
  const getApiIdentityV1Groups = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseGetGroupsResponse>(
      { url: `/Api/Identity/V1/Groups`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Добавить группу в систему
   */
  const postApiIdentityV1Groups = (
    webApiRequestAddGroupRequest: WebApiRequestAddGroupRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      {
        url: `/Api/Identity/V1/Groups`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestAddGroupRequest,
      },
      options
    );
  };

  /**
   * @summary Обновить группу в системе
   */
  const patchApiIdentityV1Groups = (
    webApiRequestUpdateGroupRequest: WebApiRequestUpdateGroupRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiResponseUpdateGroupResponse>(
      {
        url: `/Api/Identity/V1/Groups`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestUpdateGroupRequest,
      },
      options
    );
  };

  /**
   * @summary Удалить группу из системы
   */
  const deleteApiIdentityV1Groups = (
    params?: DeleteApiIdentityV1GroupsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/Groups`, method: 'DELETE', params },
      options
    );
  };

  /**
   * @summary Разлогинить всех пользователей(Сделать невалидными все выпущенные токены).
   */
  const headApiIdentityV1SystemActionsLogOutAllUsers = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<WebApiMiddlewareResponseBase>(
      { url: `/Api/Identity/V1/System/Actions/LogOutAllUsers`, method: 'HEAD' },
      options
    );
  };

  /**
   * @summary Получить ключ подписи
   */
  const postApiIdentityV1SystemActionsGetSicretKey = (
    webApiRequestLoginRequest: WebApiRequestLoginRequest,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<string>(
      {
        url: `/Api/Identity/V1/System/Actions/GetSicretKey`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: webApiRequestLoginRequest,
      },
      options
    );
  };

  return {
    postApiIdentityV1UsersLogin,
    postApiIdentityV1UsersRefreshTokens,
    postApiIdentityV1UsersLoginLockoutUser,
    postApiIdentityV1UsersLoginUnlockUser,
    getApiIdentityV1UsersUserName,
    deleteApiIdentityV1UsersUserName,
    getApiIdentityV1Users,
    postApiIdentityV1Users,
    patchApiIdentityV1Users,
    postApiIdentityV1UserRoles,
    putApiIdentityV1UserRoles,
    deleteApiIdentityV1UserRoles,
    getApiIdentityV1UserRolesUserName,
    postApiIdentityV1UserGroups,
    deleteApiIdentityV1UserGroups,
    getApiIdentityV1UserClaimsUserName,
    postApiIdentityV1UserClaims,
    deleteApiIdentityV1UserClaims,
    getApiIdentityV1RolesRoleName,
    deleteApiIdentityV1RolesRoleName,
    getApiIdentityV1Roles,
    postApiIdentityV1Roles,
    patchApiIdentityV1Roles,
    getApiIdentityV1RoleClaimsRoleName,
    postApiIdentityV1RoleClaims,
    deleteApiIdentityV1RoleClaims,
    getApiIdentityV1UserRightsUserName,
    getApiIdentityV1RoleRightsRoleName,
    postApiIdentityV1UserRights,
    getApiIdentityV1GroupsGroupName,
    getApiIdentityV1Groups,
    postApiIdentityV1Groups,
    patchApiIdentityV1Groups,
    deleteApiIdentityV1Groups,
    headApiIdentityV1SystemActionsLogOutAllUsers,
    postApiIdentityV1SystemActionsGetSicretKey,
  };
};
export type PostApiIdentityV1UsersLoginResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1UsersLogin']>
  >
>;
export type PostApiIdentityV1UsersRefreshTokensResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['postApiIdentityV1UsersRefreshTokens']
    >
  >
>;
export type PostApiIdentityV1UsersLoginLockoutUserResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['postApiIdentityV1UsersLoginLockoutUser']
    >
  >
>;
export type PostApiIdentityV1UsersLoginUnlockUserResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['postApiIdentityV1UsersLoginUnlockUser']
    >
  >
>;
export type GetApiIdentityV1UsersUserNameResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['getApiIdentityV1UsersUserName']>
  >
>;
export type DeleteApiIdentityV1UsersUserNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['deleteApiIdentityV1UsersUserName']
    >
  >
>;
export type GetApiIdentityV1UsersResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityV1Users']>>
>;
export type PostApiIdentityV1UsersResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1Users']>>
>;
export type PatchApiIdentityV1UsersResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityV1Users']>>
>;
export type PostApiIdentityV1UserRolesResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1UserRoles']>
  >
>;
export type PutApiIdentityV1UserRolesResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['putApiIdentityV1UserRoles']>
  >
>;
export type DeleteApiIdentityV1UserRolesResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityV1UserRoles']>
  >
>;
export type GetApiIdentityV1UserRolesUserNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1UserRolesUserName']
    >
  >
>;
export type PostApiIdentityV1UserGroupsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1UserGroups']>
  >
>;
export type DeleteApiIdentityV1UserGroupsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityV1UserGroups']>
  >
>;
export type GetApiIdentityV1UserClaimsUserNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1UserClaimsUserName']
    >
  >
>;
export type PostApiIdentityV1UserClaimsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1UserClaims']>
  >
>;
export type DeleteApiIdentityV1UserClaimsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityV1UserClaims']>
  >
>;
export type GetApiIdentityV1RolesRoleNameResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['getApiIdentityV1RolesRoleName']>
  >
>;
export type DeleteApiIdentityV1RolesRoleNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['deleteApiIdentityV1RolesRoleName']
    >
  >
>;
export type GetApiIdentityV1RolesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityV1Roles']>>
>;
export type PostApiIdentityV1RolesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1Roles']>>
>;
export type PatchApiIdentityV1RolesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityV1Roles']>>
>;
export type GetApiIdentityV1RoleClaimsRoleNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1RoleClaimsRoleName']
    >
  >
>;
export type PostApiIdentityV1RoleClaimsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1RoleClaims']>
  >
>;
export type DeleteApiIdentityV1RoleClaimsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityV1RoleClaims']>
  >
>;
export type GetApiIdentityV1UserRightsUserNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1UserRightsUserName']
    >
  >
>;
export type GetApiIdentityV1RoleRightsRoleNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1RoleRightsRoleName']
    >
  >
>;
export type PostApiIdentityV1UserRightsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1UserRights']>
  >
>;
export type GetApiIdentityV1GroupsGroupNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getIdentity>['getApiIdentityV1GroupsGroupName']
    >
  >
>;
export type GetApiIdentityV1GroupsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityV1Groups']>>
>;
export type PostApiIdentityV1GroupsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityV1Groups']>>
>;
export type PatchApiIdentityV1GroupsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityV1Groups']>
  >
>;
export type DeleteApiIdentityV1GroupsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityV1Groups']>
  >
>;
export type HeadApiIdentityV1SystemActionsLogOutAllUsersResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getIdentity
      >['headApiIdentityV1SystemActionsLogOutAllUsers']
    >
  >
>;
export type PostApiIdentityV1SystemActionsGetSicretKeyResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getIdentity
      >['postApiIdentityV1SystemActionsGetSicretKey']
    >
  >
>;
