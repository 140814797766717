import {
  SESSIONS,
  ADD_PREFIX,
  GROUPS,
  RESERVES,
  TRANSACTIONS,
  USERS,
} from '../names';
import { GROUP } from './common';

export const PERSON = ':userId';
export const BINDINGS = 'bindings';
export const BINDING_LOGS = 'binding-logs';
export const PROFILE = 'profile';

export const PERSONS_LIST = 'persons-list';

const ROOT = `/${USERS}`;

export const USER_ROUTES = {
  PERSONS_LIST: ROOT,
  PERSONS_GROUPS_LIST: `${ROOT}/${GROUPS}`,
  PERSONS_SESSION_LOG: `${ROOT}/${SESSIONS}`,
  PERSONS_RESERVES: `${ROOT}/${RESERVES}`,
  // Группа клиентов
  EDIT_PERSONS_GROUP: `${ROOT}/${GROUP}`,
  PERSONS_GROUP_PERSON_LIST: `${ROOT}/${GROUP}/${PERSONS_LIST}`,
  PERSONS_GROUP_SESSION_LOG: `${ROOT}/${GROUP}/${SESSIONS}`,
  PERSONS_GROUP_RESERVES: `${ROOT}/${GROUP}/${RESERVES}`,

  // Клиент
  PERSON_SESSION_LOG: `${ROOT}/${GROUP}/${PERSON}`,
  PERSON_RESERVES: `${ROOT}/${GROUP}/${RESERVES}`,
  PERSON_BINDINGS: `${ROOT}/${GROUP}/${BINDINGS}`,
  PERSON_BINDING_LOGS: `${ROOT}/${GROUP}/${BINDING_LOGS}`,
  PERSON_PROFILE: `${ROOT}/${GROUP}/${PROFILE}`,
  PERSON_TRANSACTIONS: `${ROOT}/${GROUP}/${TRANSACTIONS}`,
  // Созданиен клиента
  ADD_PERSON: `${ROOT}/${ADD_PREFIX}`,
  // Создание/обновление группы клиентов
  // PERSONS_GROUP: `${ROOT}/${GROUPS}/${GROUP}`,
  ADD_PERSONS_GROUP: `${ROOT}/${GROUPS}/${ADD_PREFIX}`,
};
