import { getRtkFromAxiosError } from 'shared/lib/http/get-rtk-error-from-axios-error';

import { getChargePointServiceWebApi } from '../../orval/axios/client';
import {
  enhancedApi as api,
  PatchApiChargepointV1PhotosByIdApiArg,
  PatchApiChargepointV1PhotosByIdApiResponse,
  PostApiChargepointV1PhotosApiArg,
  PostApiChargepointV1PhotosApiResponse,
} from '../generated/photos';

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiChargepointV1Photos: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'ChargePointPhotos', id: arg },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // addPhotos: builder.mutation<
      //   PostApiChargePointV1PhotosApiResponse,
      //   PostApiChargePointV1PhotosApiArg
      // >({
      //   query: (queryArg) => ({
      //     url: `/Api/ChargePoint/V1/Photos`,
      //     method: 'POST',
      //     body: queryArg,
      //     formData: true,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: 'ChargePointPhotos', id: arg.ChargePointId },
      //   ],
      // }),
      // updatePhoto: builder.mutation<
      //   PatchApiChargePointV1PhotosByIdApiResponse,
      //   PatchApiChargePointV1PhotosByIdApiArg
      // >({
      //   query: (queryArg) => ({
      //     url: `/Api/ChargePoint/V1/Photos/${queryArg.id}`,
      //     method: 'PATCH',
      //     body: queryArg.body,
      //     formData: true,
      //   }),
      // }),
      addPhotos: builder.mutation<
        PostApiChargepointV1PhotosApiResponse,
        {
          body: PostApiChargepointV1PhotosApiArg;
          setUploadProgress: (val: number) => void;
        }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { postApiChargePointV1Photos } = getChargePointServiceWebApi();

          const { body, setUploadProgress } = args;

          try {
            const res = await postApiChargePointV1Photos(body, {
              onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total!) * 100); // TODO: typing for total

                setUploadProgress(percent);
              },
            });

            return { data: res };
          } catch (err) {
            return getRtkFromAxiosError(err);
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'ChargePointPhotos', id: arg.body.ChargePointId },
        ],
      }),
      updatePhoto: builder.mutation<
        PatchApiChargepointV1PhotosByIdApiResponse,
        {
          data: PatchApiChargepointV1PhotosByIdApiArg;
          setUploadProgress: (val: number) => void;
        }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { patchApiChargePointV1PhotosId } =
            getChargePointServiceWebApi();

          const { data, setUploadProgress } = args;
          const { body, id } = data;

          try {
            const res = await patchApiChargePointV1PhotosId(id, body, {
              onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total!) * 100); // TODO: typing for total

                setUploadProgress(percent);
              },
            });

            return { data: res };
          } catch (err) {
            return getRtkFromAxiosError(err);
          }
        },
      }),
    }),
  });

export const {
  useGetApiChargepointV1PhotosQuery: useGetChargePointPhotos,
  useAddPhotosMutation: useAddPhotosMutation,
  useUpdatePhotoMutation: useUpdatePhotoMutation,
  useDeleteApiChargepointV1PhotosByIdMutation: useDeletePhotoMutation,
} = enhancedApi;
