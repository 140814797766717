import { Input } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledInput = styled(Input)`
  padding: 7px;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};

  height: 29px; //

  &[disabled] {
    color: ${COLOR.black};
  }
`;
