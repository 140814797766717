import NiceModal from '@ebay/nice-modal-react';

import { ActionIcon } from '../icon';
import { StyledButton } from './styles';
import { Modal } from '../modal';

type Props = {
  billingId: string;
};

export function ActionButton({ billingId }: Props) {
  const handleClick = () => {
    NiceModal.show(Modal, { billingId });
  };

  return (
    <StyledButton onClick={handleClick}>
      <ActionIcon />
    </StyledButton>
  );
}
