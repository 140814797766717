import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1HubsById: build.query<
      GetApiChargepointV1HubsByIdApiResponse,
      GetApiChargepointV1HubsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/chargepoint/V1/Hubs/${queryArg}` }),
    }),
    patchApiChargepointV1HubsById: build.mutation<
      PatchApiChargepointV1HubsByIdApiResponse,
      PatchApiChargepointV1HubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Hubs/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateHubRequest,
      }),
    }),
    deleteApiChargepointV1HubsById: build.mutation<
      DeleteApiChargepointV1HubsByIdApiResponse,
      DeleteApiChargepointV1HubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Hubs/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1Hubs: build.query<
      GetApiChargepointV1HubsApiResponse,
      GetApiChargepointV1HubsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Hubs`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    postApiChargepointV1Hubs: build.mutation<
      PostApiChargepointV1HubsApiResponse,
      PostApiChargepointV1HubsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Hubs`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1HubsCount: build.query<
      GetApiChargepointV1HubsCountApiResponse,
      GetApiChargepointV1HubsCountApiArg
    >({
      query: () => ({ url: `/api/chargepoint/V1/Hubs/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1HubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type GetApiChargepointV1HubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargepointV1HubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PatchApiChargepointV1HubsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateHubRequest: UpdateHubRequest;
};
export type DeleteApiChargepointV1HubsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1HubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargepointV1HubsApiResponse =
  /** status 200 Success */ GetHubVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1HubsApiArg = {
  limit?: number;
  offset?: number;
};
export type PostApiChargepointV1HubsApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PostApiChargepointV1HubsApiArg = /** Параметры */ AddHubRequest;
export type GetApiChargepointV1HubsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1HubsCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetHubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type GetHubVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm;
};
export type UpdateHubRequest = {
  name?: string | null;
  description?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetHubVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm[] | null;
};
export type AddHubRequest = {
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargepointV1HubsByIdQuery,
  usePatchApiChargepointV1HubsByIdMutation,
  useDeleteApiChargepointV1HubsByIdMutation,
  useGetApiChargepointV1HubsQuery,
  usePostApiChargepointV1HubsMutation,
  useGetApiChargepointV1HubsCountQuery,
} = injectedRtkApi;
