import { Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';

type Props = DraggerProps & {
  children: JSX.Element;
};

export function UiUploadDragger(props: Props) {
  return <Upload.Dragger {...props}>{props.children}</Upload.Dragger>;
}
