import { pickFromObject } from 'shared/lib';

import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { COMMAND_TYPE } from 'entities/command';

import { LightningIcon } from '../icons/lightning';
import { TurtleIcon } from '../icons/turtle';

export const CHARGE_POINT_TYPE_ICON: Record<
  Exclude<Exclude<GetChargePointVm['speedType'], undefined>, 'UNDEFINED'>,
  JSX.Element
> = {
  HIGH_SPEED_DC: <TurtleIcon />,
  LOW_SPEED_AC: <LightningIcon />,
};

export const AVAILABLE_COMMAND_TYPE = pickFromObject(
  COMMAND_TYPE,
  'RESTART',
  'LOAD_FIRMWARE',
  'CONFIGURE',
  'STOP',
  'CHANGE_AVAILABILITY'
);
