import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 14px 0px rgba(24, 30, 28, 0.15);
`;

export const CardTitle = styled.h2`
  color: #000;

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
`;

export const GridColumn = styled.div`
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 16px;

  overflow: hidden; /* NEW */
  min-width: 0;

  &:not(.first-row) {
    padding-top: 16px;
  }
`;

export const Label = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 6px;
`;

export const Value = styled.p`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  min-height: 21px;
`;

export const StyledLink = styled.span`
  color: #23c990;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  margin-top: 6px;
`;
