import { useState } from 'react';

import {
  useUpdatePhotoMutation,
  enhancedApi,
} from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { getErrorMessage } from 'shared/lib/http';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Фотография обновлена';

export const useUpdatePhoto = () => {
  const [progress, setProgress] = useState(0);

  const setUploadProgress = (val: number) => setProgress(val);

  const [trigger, { isLoading }] = useUpdatePhotoMutation();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    photoId,
    chargePointId,
    data,
    closeModal,
  }: {
    photoId: string;
    data: FormOutput;
    chargePointId: string;
    closeModal: () => void;
  }) => {
    try {
      const { priority, file } = data;

      const res = await trigger({
        data: {
          body: {
            ChargePointId: chargePointId,
            File: file,
            Priority: priority,
          },
          id: photoId,
        },
        setUploadProgress,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'ChargePointPhotos', id: chargePointId },
        ])
      );

      closeModal();
    } catch (err) {
      const errorText = getErrorMessage(err);

      openErrorNotification(errorText);
    }
  };

  return {
    progress,
    isLoading,
    handleUpdate,
  };
};
