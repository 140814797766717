import { getRuFormatDateString } from 'shared/lib';

import {
  ParamsContainer,
  ReportInfoContainer,
  ReportParam,
  ReportTitle,
} from './styles';

type Props = {
  title: string;
  dateFromParam: string | undefined;
  dateToParam: string | undefined;
};

export function ReportInfo({ title, dateFromParam, dateToParam }: Props) {
  const currentDate = getRuFormatDateString();

  const dateFrom =
    dateFromParam === undefined
      ? currentDate
      : getRuFormatDateString(dateFromParam);
  const dateTo =
    dateToParam === undefined
      ? currentDate
      : getRuFormatDateString(dateToParam);

  return (
    <ReportInfoContainer>
      <ReportTitle>{title}</ReportTitle>
      <ParamsContainer>
        <ReportParam>{`Период от и до: ${dateFrom} - ${dateTo}`}</ReportParam>
        <ReportParam>{`Дата отчёта: ${currentDate}`}</ReportParam>
      </ParamsContainer>
    </ReportInfoContainer>
  );
}
