import { z } from 'zod';
import { UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { requiredNumber } from 'shared/lib/schema';

import { MAX_FILE_SIZE_MB } from 'entities/charge-point/consts';

export const FormSchema = z.object({
  firstPriority: requiredNumber,
  files: z
    .array(z.custom<UploadFile>())
    .refine((files) => {
      return files.length > 0;
    }, 'File is required.')
    .refine((files) => {
      return files.every((file) => file.originFileObj instanceof File);
    })
    .transform((val, ctx) => {
      const origFiles: RcFile[] = [];

      val.forEach((el) => {
        if (!el.originFileObj) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Missing original files',
          });

          return z.NEVER;
        }

        origFiles.push(el.originFileObj);
      });

      return origFiles;
    })
    .superRefine((val, ctx) => {
      val.forEach((file, idx) => {
        const { size, uid } = file;

        if (size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          ctx.addIssue({
            code: 'custom',
            message: `Max file size is ${MAX_FILE_SIZE_MB}MB`,
            path: [uid],
          });
        }
      });
    }),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  files: [],
};
