import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useMemo } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { TariffDto } from 'shared/api/services/billing/rtk/generated';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { DeleteIcon } from 'shared/icons/delete';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import {
  AddServiceButton,
  AddStageButton,
  STAGE_END_TYPE,
  getTariffOptionsList,
} from 'entities/billing';

import { AddTariffButton } from 'features/billing/add-tariff';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';

import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  FormFieldsGroupHeader,
  ServiceTitle,
} from './styles';
import { useCreateServicesList } from '../hooks/use-create-services-list';

const FORM_TITLE = 'Создание списка услуг';

type Props = {
  tariffs: TariffDto[];
  countries: GetCountryVm[];
  chargePoints: GetChargePointVm[];
};

export function Form({ tariffs, countries, chargePoints }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const { handleCreate, isLoading } = useCreateServicesList();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate(data);
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                tariffs={tariffs}
                countries={countries}
                chargePoints={chargePoints}
              />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Pick<Props, 'chargePoints' | 'countries' | 'tariffs'>;

Form.Fields = function Fields({
  tariffs,
  countries,
  chargePoints,
}: FieldsProps) {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<FormInput, void, FormOutput>();

  const tariffOptions = useMemo(() => {
    return getTariffOptionsList(tariffs, countries);
  }, [tariffs, countries]);

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  const renderReservingService = () => {
    if (!reservingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Бронирование"
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber: String(reservingFieldArray.fields.length),
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      );
    }

    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Услуга Бронирование</ServiceTitle>
        </FormFieldsGroupHeader>

        {reservingFieldArray.fields.map((field, index) => {
          const endType = watch(`reservingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => reservingFieldArray.remove(index)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber: String(reservingFieldArray.fields.length),
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      </>
    );
  };

  const renderChargingService = () => {
    if (!chargingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Заряд"
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: String(chargingFieldArray.fields.length),
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      );
    }

    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Услуга Заряд</ServiceTitle>
        </FormFieldsGroupHeader>

        {chargingFieldArray.fields.map((field, index) => {
          const endType = watch(`chargingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={tariffOptions}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton countries={countries} />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => chargingFieldArray.remove(index)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: String(chargingFieldArray.fields.length),
              tariffId: '',
              endConditionValue: '0',
            })
          }
        />
      </>
    );
  };

  const renderChargePoints = () => {
    return (
      <>
        <FormFieldsGroupHeader>
          <ServiceTitle>Выбрать ЭЗС</ServiceTitle>
        </FormFieldsGroupHeader>

        <FormRow>
          <Col span={18}>
            <CustomSelect<FormInput>
              name="chargePointIds"
              options={chargePoints.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              label="ЭЗС"
              mode="multiple"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              allowClear
            />
          </Col>
        </FormRow>
      </>
    );
  };

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название списка услуг"
              required
            />
          </Col>
        </FormRow>
      </FormFieldsGroup>

      <FormFieldsGroup>{renderReservingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargePoints()}</FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
