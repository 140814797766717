import { z } from 'zod';

import { optionalZodDayToISO, requiredString } from 'shared/lib/schema';
import { patchApiUserUsersUserIdBody } from 'shared/api/services/user/orval/zod/schemas';

export const FormSchema = patchApiUserUsersUserIdBody
  .pick({
    email: true,
    firstName: true,
    lastName: true,
    middleName: true,
    sex: true,
    groupId: true,
    notesRelatedWithUser: true,
    isDebug: true,
    userAgreementAccepted: true,
  })
  .extend({
    login: requiredString,
    birthDate: optionalZodDayToISO,
    countryId: requiredString,
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
