import { GetPhotoVm } from 'shared/api/services/chargepoint/rtk/generated/photos';

import { PhotoListItem } from '../list-item';
import { StyledContainer } from './styles';

type Props = { photos: GetPhotoVm[]; chargePointId: string };

export function PhotoList({ photos, chargePointId }: Props) {
  return (
    <StyledContainer>
      {photos.map((el) => (
        <PhotoListItem key={el.id} chargePointId={chargePointId} photo={el} />
      ))}
    </StyledContainer>
  );
}
