import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetBindingLogs,
  useGetBindingLogsCount,
} from 'shared/api/services/payment/rtk/enhanced';

import { TableInstance } from './table';
import { useGetMobileApps } from 'shared/api/services/information/rtk';
import { getMobileAppsMapping } from 'entities/mobile-app';

const queryParamsSchema = z.object({
  bankId: z.string().optional(),
  bankClientId: z.string().optional(),
  status: z
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  orderNumber: z.string().optional(),
  bankOrderId: z.string().optional(),
  errorReason: z
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
    ])
    .optional(),
  //
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
});

const countQueryParamsSchema = queryParamsSchema.omit({
  page: true,
  limit: true,
  orderingField: true,
  orderingType: true,
});

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const bindigLogsQueryParams = useTypedQueryParamsCustom(queryParamsSchema);
  const bindigLogsCountQueryParams = useTypedQueryParamsCustom(
    countQueryParamsSchema
  );

  const bindingLogsQuery = useGetBindingLogs({
    ...bindigLogsQueryParams,
    offset:
      Math.max(bindigLogsQueryParams.page - 1, 0) * bindigLogsQueryParams.limit,
    userId,
  });
  const bindingLogsCountQuery = useGetBindingLogsCount({
    ...bindigLogsCountQueryParams,
    userId,
  });
  const mobileAppsQuery = useGetMobileApps();

  const isLoading =
    bindingLogsQuery.isLoading ||
    bindingLogsCountQuery.isLoading ||
    mobileAppsQuery.isLoading;
  const isFetching =
    bindingLogsQuery.isFetching ||
    bindingLogsCountQuery.isFetching ||
    mobileAppsQuery.isFetching;

  const apiResponseError = [
    bindingLogsQuery.data,
    bindingLogsCountQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [bindingLogsQuery.error, bindingLogsCountQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !bindingLogsQuery.data?.data ||
    !bindingLogsCountQuery ||
    bindingLogsCountQuery.data?.data === undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={bindingLogsQuery.data?.data || []}
      totalCount={bindingLogsCountQuery.data.data}
      mobileAppsMapping={getMobileAppsMapping(mobileAppsQuery.data?.data || [])}
    />
  );
}
