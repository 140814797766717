import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useUpdateCompany } from 'shared/api/services/company/rtk/enhanced';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { EMPLOYEES_BALANCE_PERIOD } from 'entities/company';

import { FormSchema, FormSchemaType } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  company: CompanyDto;
};

export function Form({ company }: Props) {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(() => {
      return company;
    }, [company]),
  });

  useEffect(() => {
    form.reset({ ...company });
  }, [company]);

  const [trigger, { isLoading }] = useUpdateCompany();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        companyId: company.id,
        updateCompanyRequestDto: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{company.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields isDisabled={isDisabled} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  isDisabled: boolean;
};

Form.Fields = function Fields({ isDisabled }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormSchemaType>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="legalName"
            label="Юридическое наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="email"
            label="Email"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="contractData"
            label="Договор"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="address"
            label="Адрес"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="phoneNumber"
            label="Телефонный номер"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="logoUrl"
            label="URL логотипа"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="inn"
            label="ИНН"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="kpp"
            label="КПП"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="companyBalanceForPeriod"
            label="Величина баланса компании на период в рублях"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="employeeBalanceForPeriod"
            label="Величина установки баланса сотрудникам за переод времени в рублях"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={6}>
          <CustomSelect<FormSchemaType>
            name="balanceRefreshPeriod"
            label="Переод времени за который устанавливается новый баланс сотруднику и компании"
            options={Object.entries(EMPLOYEES_BALANCE_PERIOD).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormSchemaType>
            name="isBalanceRefreshLocked"
            label="isBalanceRefreshLocked"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormSchemaType>
            name="isDefault"
            label="isDefault"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
