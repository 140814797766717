import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useCommandDiagnostic } from 'shared/api/services/chargepoint/rtk/enhanced/commands';
import {
  ButtonsContainer,
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';
import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { FormRow } from 'shared/ui/form';

type Props = {
  chargePointId?: string;
  closeModal: () => void;
};

export function Form({ closeModal, chargePointId }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      chargePointId,
    },
  });

  const [trigger, { isLoading }] = useCommandDiagnostic();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="chargePointId" label="ЭЗС" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="uploadUrl"
            label="Ссылка отправки файла диагностики"
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
