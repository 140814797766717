import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';
import { LATITUDE_PATTERN, LONGITUDE_PATTERN } from 'shared/consts/regex';

import {
  ADDRESS_MAX_LENGTH,
  chargePointProtocol,
  CITY_MIN_LENGTH,
  countryCode,
  speedType,
  status,
} from 'entities/charge-point';

const FormResultSchema = z.object({
  //
  // Base info
  //
  name: z.string().min(1),
  coordinates: z.tuple([z.number(), z.number()]),
  address: z.string().max(ADDRESS_MAX_LENGTH),
  maxPower: z.number().gte(0),
  city: z.string().min(CITY_MIN_LENGTH),
  status: status,
  groupId: z.string().nullish(),
  companyId: z.string().nullish(),
  hubId: z.string().nullish(),
  servicesListId: z.string().nullish(),
  infrastructureInfo: z.array(z.string()),
  // "Макс. % оплаты баллами"
  workingTime: z.string().nullish(),
  // "Доступны скидки"
  publicDescription: z.string().nullish(),
  countryCode: z.string().min(1),
  //
  // Tech info
  //
  speedType: speedType,
  simNumber: z.string().nullish(),
  manufacturer: z.string().nullish(),
  commentary: z.string().nullish(),
  number: z.number().nullish(),
  chargeBoxIdentity: z.string().min(1),
  chargePointProtocol: chargePointProtocol,
  ipAddress: z.string().min(1),
  softwareRevision: z.string().nullish(),
  userLocationCheckDistance: z.number().nullish(),
  ocppConfig: z.string().nullish(),
  warningText: z.string().nullish(),
  isVisible: z.boolean(),
  isTestCp: z.boolean(),
  multiconnectorSupport: z.boolean(),
});

export const FormSchema = z
  .object({
    //
    // Base info
    //
    name: z.string().transform(emptyStringToUndefined),
    coordinates: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value, ctx) => {
        if (!value) {
          return undefined;
        }

        if (!value.includes(', ')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Формат: широта, долгота',
          });

          return z.NEVER;
        }

        const [latitude, longitude] = value.split(', ');

        if (!LATITUDE_PATTERN.test(latitude)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Широта от -90 до 90',
          });

          return z.NEVER;
        }

        if (!LONGITUDE_PATTERN.test(longitude)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Долгота от -180 до 180',
          });

          return z.NEVER;
        }

        return [Number(latitude), Number(longitude)];
      }),
    address: z.string().transform(emptyStringToUndefined),
    maxPower: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    city: z.string().transform(emptyStringToUndefined),
    status: z.string().transform(emptyStringToUndefined),
    groupId: z.string().transform(emptyStringToUndefined).nullish(),
    companyId: z.string().transform(emptyStringToUndefined).nullish(),
    hubId: z.string().transform(emptyStringToUndefined).nullish(),
    servicesListId: z.string().transform(emptyStringToUndefined).nullish(),
    infrastructureInfo: z.array(z.string()),
    // "Макс. % оплаты баллами"
    workingTime: z.string().nullish(),
    // "Доступны скидки"
    publicDescription: z.string().nullish(),
    countryCode: z.string().transform(emptyStringToUndefined),
    //
    // Tech info
    //
    speedType: z.string().transform(emptyStringToUndefined),
    simNumber: z.string().nullish(),
    manufacturer: z.string().nullish(),
    commentary: z.string().nullish(),
    number: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    chargeBoxIdentity: z.string().transform(emptyStringToUndefined),
    chargePointProtocol: z.string().transform(emptyStringToUndefined),
    ipAddress: z.string().transform(emptyStringToUndefined),
    softwareRevision: z.string().nullish(),
    userLocationCheckDistance: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    ocppConfig: z.string().nullish(),
    warningText: z.string().nullish(),
    isVisible: z.boolean(),
    isTestCp: z.boolean(),
    multiconnectorSupport: z.boolean(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  coordinates: '',
  address: '',
  maxPower: '',
  city: '',
  status: '',
  groupId: '',
  companyId: '',
  hubId: '',
  servicesListId: '',
  infrastructureInfo: [],
  workingTime: '',
  publicDescription: '',
  countryCode: '',
  speedType: '',
  simNumber: '',
  manufacturer: '',
  commentary: '',
  number: '',
  chargeBoxIdentity: '',
  chargePointProtocol: 'OCPP16J',
  ipAddress: '',
  softwareRevision: '',
  userLocationCheckDistance: '',
  ocppConfig: '',
  warningText: '',
  isVisible: false,
  isTestCp: false,
  multiconnectorSupport: false,
};
