import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
`;

export const CardTitle = styled.h3`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  margin-bottom: 22px;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
`;
