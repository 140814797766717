import {
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';

import { useColumns } from './use-columns';

type Props = {
  data: GetChargePointVm[];
  loading: boolean;
  totalCount: number;
  companiesMapping: Record<string, CompanyDto>;
};

export function TableInstance({
  data,
  loading,
  totalCount,
  companiesMapping,
}: Props) {
  const columns = useColumns({ companiesMapping });

  const { limit, page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: limit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / limit),
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
    />
  );
}
