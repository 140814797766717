import { Col, Row } from 'antd';

import {
  Balance,
  BonusProgramAchievement,
} from 'shared/api/services/bonus/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { UiInput } from 'shared/ui/ui-kit';

import { ManageBonusesButton } from 'features/user/manage-bonuses';

import { BonusProgramAchievements } from '../bonus-program-achievements';
import { StyledLabel } from './styles';

type Props = {
  achievement: BonusProgramAchievement;
  userId: string;
  countries: GetCountryVm[];
  balances: Balance[];
};

export function BonusesSummaryLayout({
  achievement,
  userId,
  countries,
  balances,
}: Props) {
  const { bonus_program: bonusProgram, current_sum = 0 } = achievement;

  const { countryId } = bonusProgram;

  const country = countries.find((el) => el.id === countryId);

  if (!country) {
    return <div>Нет данных о стране</div>;
  }

  // TODO
  const bonusesSum = balances[0].value;

  const { currencySymbol, currencyText } = country;

  return (
    <>
      <StyledLabel>{`Бонусов на счете (${currencyText})`}</StyledLabel>
      <Row gutter={10}>
        <Col span={12}>
          <UiInput value={bonusesSum} disabled style={{ height: '100%' }} />
        </Col>
        <Col>
          <ManageBonusesButton userId={userId} countries={countries} />
        </Col>
      </Row>
      <BonusProgramAchievements
        currentSum={current_sum}
        currencySymbol={currencySymbol}
        bonusProgram={bonusProgram}
      />
    </>
  );
}
