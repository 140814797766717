import { UploadProps } from 'antd';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiUploadDragger } from 'shared/ui/ui-kit/upload-dragger';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  showSingleErrorMessage?: boolean;
} & React.ComponentProps<typeof UiUploadDragger>;

export function CustomUploadDragger<T extends FieldValues>({
  label,
  required,
  disabled,
  errorMessage,
  name,
  showSingleErrorMessage = true,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { value, onChange } = field;

        const { error } = fieldState;

        const handleChange: UploadProps['onChange'] = (info) => {
          onChange(info.fileList);
        };

        return (
          <FormItemContainer>
            <FormItemLabel required={required}>{label}</FormItemLabel>
            <UiUploadDragger
              {...field}
              {...rest}
              disabled={disabled}
              fileList={value?.fileList}
              onChange={handleChange}
              multiple
            />
            {showSingleErrorMessage && (
              <FieldErrorContainer>
                {error?.message || errorMessage}
              </FieldErrorContainer>
            )}
          </FormItemContainer>
        );
      }}
    />
  );
}
