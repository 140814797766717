import {
  AvailabilityChangeType,
  ResetType,
} from 'shared/api/services/chargepoint/rtk/generated/commands';
import { pickFromObject } from 'shared/lib';

import { CommandType } from 'entities/command/model';

import { AvailabilityType } from '../../manual-command/model';
import { MANUAL_COMMAND_TYPE } from 'entities/manual-command/consts';

export const COMMAND_TYPE: Record<CommandType, string> = {
  CONFIGURE: 'ЭЗС Обновить конфигурацию',
  DIAGNOSTICS: 'ЭЗС Загрузить логи',
  LOAD_FIRMWARE: 'ЭЗС Обновить ПО',
  RESTART: 'ЭЗС Перезагрузка',
  STOP: 'ЭЗС Завершить зарядку',
  CHANGE_AVAILABILITY: 'ЭЗС Изменить доступность',
};

export const RESET_TYPE: Record<ResetType, string> = {
  HARD: 'HARD',
  SOFT: 'SOFT',
};

export const CHANGE_AVAILABILITY_TYPE: Record<AvailabilityChangeType, string> =
  {
    INOPERATIVE: 'INOPERATIVE',
    OPERATIVE: 'OPERATIVE',
  };

export const SESSION_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  0,
  6,
  7
);

export const CONNECTOR_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  0,
  1,
  2
);

export const MANUAL_COMMAND_STATUS: Record<
  Exclude<AvailabilityType, '-1'>,
  string
> = {
  0: 'заблокировано',
  1: 'работает',
};

// export const MANUAL_COMMAND_RESET_TYPE: Record<
//   Exclude<ResetType, '-1'>,
//   string
// > = {
//   0: 'soft',
//   1: 'hard',
// };

//
