import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import {
  useGetSessions,
  useGetSessionsCount,
} from 'shared/api/services/chargepoint/rtk';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';
import { SessionStatus } from 'shared/api/services/chargepoint/rtk/generated/sessions';

import { TableInstance } from './table';

const status: z.ZodType<SessionStatus> = z.enum([
  'NEW',
  'PREPARING',
  'CHARGING',
  'COMPLETED_WITH_SUCCESS',
  'COMPLETED_WITH_ERROR',
]);

const pageQueryParamsSchema = z.object({
  // chargePointId: z.string().optional(),
  connectorId: z.string().optional(),
  // userId: z.string().optional(),
  status: status.optional(),
  transactionId: z.string().optional(),
  dateCreateFrom: z.string().optional(),
  dateCreateTo: z.string().optional(),
  endDateFrom: z.string().optional(),
  endDateTo: z.string().optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),

  // Щас нет на бэке
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  //
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(), // catch?
});

type Props = {
  chargePointId?: string;
  userId?: string;
  chargePointGroupId?: string;
};

export function TableContainer({
  chargePointId,
  userId,
  chargePointGroupId,
}: Props) {
  const isWindowFocused = useWindowFocus();

  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    isLoading: isSessionsLoading,
    isFetching: isSessionsFetching,
    error: sessionsError,
    data: sessions,
    currentData: currentSessions,
  } = useGetSessions(
    {
      ...queryParams,
      chargePointId,
      userId,
      chargePointGroupId,
      offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const {
    isLoading: isSessionsCountLoading,
    isFetching: isSessionsCountFetching,
    error: sessionsCountError,
    data: sessionsCount,
    currentData: currentSessionsCount,
  } = useGetSessionsCount(
    {
      ...queryParams,
      chargePointId,
      userId,
      chargePointGroupId,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const isLoading = isSessionsLoading || isSessionsCountLoading;
  const isFetching =
    (isSessionsFetching || isSessionsCountFetching) &&
    (!currentSessions || !currentSessionsCount);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [sessions, sessionsCount].find(
    (el) => el && el.status === 'ERROR'
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  const error = [sessionsError, sessionsCountError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!sessions?.data || !sessionsCount || sessionsCount.data == undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={sessions.data}
      totalCount={sessionsCount?.data}
    />
  );
}
