import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

const FormResultSchema = z.object({
  name: z.string().min(1),
  comment: z.string().nullish(),
  parentId: z
    .string()
    .nullish()
    .transform((val) => {
      if (!val) {
        // На бэке еще не реализовано
        return '00000000-0000-0000-0000-000000000000';
      }

      return val;
    }),
  isDefault: z.boolean(),
  mobileAppIds: z.array(z.string().uuid()).nullish(),
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    isDefault: z.boolean(),
    comment: z.string().transform(emptyStringToUndefined).nullish(),
    parentId: z.string().transform(emptyStringToUndefined).nullish(),
    mobileAppIds: z.array(z.string().uuid()).nullish(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
