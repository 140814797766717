import { useState } from 'react';

import {
  useAddPhotosMutation,
  enhancedApi,
} from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { getErrorMessage } from 'shared/lib/http';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Фотографии добавлены';

// export const useAddPhotos = () => {
//   const [trigger, { isLoading }] = useAddPhotosMutation();

//   const handleCreate = async ({
//     chargePointId,
//     data,
//     closeModal,
//   }: {
//     data: FormOutput;
//     chargePointId: string;
//     closeModal: () => void;
//   }) => {
//     try {
//       const { files, firstPriority } = data;

//       const res = await trigger({
//         ChargePointId: chargePointId,
//         FirstPriority: firstPriority,
//         Files: files,
//       }).unwrap();

//       openSuccessNotification(SUCCESS_MSG);

//       closeModal();
//     } catch (err) {
//       const errorText = getErrorMessage(err);

//       openErrorNotification(errorText);
//     }
//   };

//   return {
//     isLoading,
//     handleCreate,
//   };
// };

export const useAddPhotos = () => {
  const [progress, setProgress] = useState(0);

  const setUploadProgress = (val: number) => setProgress(val);

  const [trigger, { isLoading }] = useAddPhotosMutation();

  const dispatch = useAppDispatch();

  const handleCreate = async ({
    chargePointId,
    data,
    closeModal,
  }: {
    data: FormOutput;
    chargePointId: string;
    closeModal: () => void;
  }) => {
    try {
      const { files, firstPriority } = data;

      const res = await trigger({
        body: {
          ChargePointId: chargePointId,
          FirstPriority: firstPriority,
          Files: files,
        },
        setUploadProgress,
      }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'ChargePointPhotos', id: chargePointId },
        ])
      );

      closeModal();
    } catch (err) {
      const errorText = getErrorMessage(err);

      openErrorNotification(errorText);
    }
  };

  return {
    progress,
    isLoading,
    handleCreate,
  };
};
