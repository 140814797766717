import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 116px;
`;

export const ImageContainer = styled.div`
  border-radius: 2px;
  width: 116px;
  height: 101px;

  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;

  margin-bottom: 4px;
`;

export const StyledFooter = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 33px;
`;

export const StyledButton = styled.button`
  height: 15px;
  aspect-ratio: 1;

  cursor: pointer;
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 4px;
`;
