import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { GetPhotoVm } from 'shared/api/services/chargepoint/rtk/generated/photos';
import { UiModal } from 'shared/ui';

import { Form } from './form';

const FORM_TITLE = 'Редактирование фотографии ЭЗС';

type Props = {
  chargePointId: string;
  photo: GetPhotoVm;
};

export const Modal = NiceModal.create(({ chargePointId, photo }: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title={FORM_TITLE}
    >
      <Form
        chargePointId={chargePointId}
        photo={photo}
        closeModal={modal.hide}
      />
    </UiModal>
  );
});
