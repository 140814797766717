import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAnalyticsReportAcquiring: build.query<
      GetApiAnalyticsReportAcquiringApiResponse,
      GetApiAnalyticsReportAcquiringApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Analytics/Report/ACQUIRING`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          BankId: queryArg.bankId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiAnalyticsReportUploadBilling: build.mutation<
      PostApiAnalyticsReportUploadBillingApiResponse,
      PostApiAnalyticsReportUploadBillingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Analytics/Report/UploadBilling`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiAnalyticsReportAcquiringApiResponse =
  /** status 200 Success */ GetAcquiringVmResponseBase;
export type GetApiAnalyticsReportAcquiringApiArg = {
  limit?: number;
  offset?: number;
  bankId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiAnalyticsReportUploadBillingApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiAnalyticsReportUploadBillingApiArg = string;
export type GetAcquiringBillingVm = {
  userLogin?: string | null;
  companyINN?: string | null;
  companyAgentReceipt?: string | null;
  createdDate?: string;
  chargePointName?: string | null;
  chargePointAddress?: string | null;
  invoiceSum?: number;
  paymentDate?: string | null;
  bonus?: number;
};
export type GetAcquiringVm = {
  billings?: GetAcquiringBillingVm[] | null;
  count?: number;
};
export type Status = 0 | 1 | 2;
export type UnavailableServices =
  | 'ChargePointService'
  | 'CpWsController'
  | 'CpSoapController'
  | 'BillingService'
  | 'PaymentService'
  | 'FiscalService'
  | 'SberPay'
  | 'ArmeniaPay'
  | 'EPay'
  | 'FiscalRussia'
  | 'FiscalArmenia'
  | 'BonusService'
  | 'DiscountService'
  | 'MobileApiBff'
  | 'UserService'
  | 'ReserveService'
  | 'OcpiService'
  | 'SmsService'
  | 'PushNotificationService'
  | 'SystemInformationService'
  | 'IdentityService'
  | 'AnalyticsService';
export type GetAcquiringVmResponseBase = {
  data?: GetAcquiringVm;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiAnalyticsReportAcquiringQuery,
  usePostApiAnalyticsReportUploadBillingMutation,
} = injectedRtkApi;
