import { ExtendedBonusProgram } from 'shared/api/services/bonus/rtk/generated';

import { getNextLevelHint } from 'entities/bonus-program';

import { Milestone } from '../milestone';
import { ProgressBar } from '../progress-bar';
import { AchievementsContainter, NextLevelHint } from './styles';

type Props = {
  currencySymbol: string;
  bonusProgram: ExtendedBonusProgram;
  currentSum: number;
};

export function BonusProgramAchievements({
  currencySymbol,
  bonusProgram,
  currentSum,
}: Props) {
  const { programLevels, type, frequency } = bonusProgram;

  const activeLevels = programLevels.filter(
    (level) => level.awardSum > 0 || level.awardPercent > 0
  );

  const finalCondition = activeLevels[activeLevels.length - 1]['condition'];

  const progress = Math.floor((currentSum / finalCondition) * 100);

  const milestones = activeLevels.map((level, idx, arr) => {
    const { awardPercent, awardSum, condition } = level;

    const gained = currentSum >= condition;

    //
    const value = awardPercent > 0 ? `${awardPercent}%` : `${awardSum}`;

    const isLastMilestone = idx === arr.length - 1;

    let offset;

    if (isLastMilestone) {
      offset = 0;
    } else {
      offset = Math.floor((condition / finalCondition) * 100);
    }

    return (
      <Milestone
        gained={gained}
        value={value}
        offset={offset}
        isLastMilestone={isLastMilestone}
      />
    );
  });

  const currentLevelIndex = activeLevels.findIndex((level, idx, arr) => {
    if (idx === arr.length - 1) {
      return undefined;
    }

    return currentSum > arr[idx + 1].condition;
  });

  const nextLevel =
    currentLevelIndex === -1
      ? activeLevels[0]
      : activeLevels[currentLevelIndex + 1];

  const hint = getNextLevelHint({
    awardPercent: nextLevel.awardPercent,
    awardSum: nextLevel.awardSum,
    bonusProgramType: type,
    leftToNextLevel: nextLevel.condition - currentSum,
    frequencyType: frequency,
    currencySymbol,
  });

  return (
    <>
      <NextLevelHint>{hint}</NextLevelHint>
      <AchievementsContainter>
        <ProgressBar progress={progress} />
        {milestones}
      </AchievementsContainter>
    </>
  );
}
