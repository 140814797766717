// custom-instance.ts

import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import mem from 'mem';

import { getIdentity } from './api/services/identity/orval/axios/client';
import { isResponseStatusError } from './lib/http/is-error-with-message';
import { selectAccessToken, selectRefreshToken } from './redux/selectors';
import {
  clearSessionCredentials,
  setSessionCredentials,
} from './redux/slices/auth/authSessionSlice';
import {
  clearCredentials,
  setCredentials,
} from './redux/slices/auth/authSlice';
import { store } from './redux/store';

export const AXIOS_INSTANCE = Axios.create({ baseURL: `${__API_BASE__}` }); // use your own URL here or environment variable

const refreshTokenFn = async () => {
  const currentRefreshToken = selectRefreshToken(store.getState());

  const rememberUser = Boolean(store.getState().auth.accessToken);

  const { postApiIdentityV1UsersRefreshTokens } = getIdentity();

  try {
    const response = await postApiIdentityV1UsersRefreshTokens({
      headers: {
        Authorization: `Bearer ${currentRefreshToken}`,
      },
    });

    const { accessToken, refreshToken } = response;

    if (accessToken && refreshToken) {
      const creds = {
        accessToken,
        refreshToken,
      };

      rememberUser
        ? store.dispatch(setCredentials(creds))
        : store.dispatch(setSessionCredentials(creds));
    } else {
      store.dispatch(clearCredentials());
      store.dispatch(clearSessionCredentials());
    }

    return { accessToken, refreshToken };
  } catch (error) {
    store.dispatch(clearCredentials());
    store.dispatch(clearSessionCredentials());
  }
};

const maxAge = 10000;

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});

//

AXIOS_INSTANCE.interceptors.request.use((config) => {
  const token = selectAccessToken(store.getState());

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return AXIOS_INSTANCE(config);
    }
    return Promise.reject(error);
  }
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

// export type BodyType<BodyData> = BodyData;

// // Or, in case you want to wrap the body type (optional)
// // (if the custom instance is processing data before sending it, like changing the case for example)
// export type BodyType<BodyData> = CamelCase<BodyData>;
