import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import {
  useGetChargePoint,
  useGetEvents,
  useGetEventsCount,
} from 'shared/api/services/chargepoint/rtk';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';
import { getApiChargepointV1EventsQueryParams } from 'shared/api/services/chargepoint/orval/zod/schemas';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  name: getApiChargepointV1EventsQueryParams.shape.Name.optional().catch(
    undefined
  ),
  type: getApiChargepointV1EventsQueryParams.shape.Type.optional().catch(
    undefined
  ),
  // chargePointId: z.string().optional(),
  connectorId: z.string().optional(),
  transactionId: z.string().optional(),
  sessionId: z.string().optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(), // catch?
});

type Props = {
  chargePointId: string;
};

export function TableContainer({ chargePointId }: Props) {
  const isWindowFocused = useWindowFocus();

  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const eventsQuery = useGetEvents(
    {
      ...queryParams,
      chargePointId,
      offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const eventsCountQuery = useGetEventsCount(
    { ...queryParams, chargePointId },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const chargePointQuery = useGetChargePoint(chargePointId);

  const isLoading =
    eventsQuery.isLoading ||
    eventsCountQuery.isLoading ||
    chargePointQuery.isLoading;

  const isFetching =
    (eventsQuery.isFetching || eventsCountQuery.isFetching) &&
    (!eventsQuery.currentData || !eventsCountQuery.currentData);

  const apiResponseError = [eventsQuery.data, eventsCountQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [eventsQuery.error, eventsCountQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !eventsQuery?.data?.data ||
    !eventsCountQuery.data ||
    eventsCountQuery.data.data === undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={eventsQuery.data.data}
      totalCount={eventsCountQuery.data.data}
      connectors={chargePointQuery.data?.data?.connectors || []}
    />
  );
}
