import { Spin } from 'antd';

import { GetAcquiringBillingVm } from 'shared/api/services/analytics/rtk/generated';
import { FORMAT_TO_MINUTES, showTimeString, toFixedDecimals } from 'shared/lib';
import { BankDto } from 'shared/api/services/payment/rtk/generated';
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTfoot,
  StyledTh,
  StyledThead,
  StyledTr,
} from './styles';

type Props = {
  banks: BankDto[];
  billings: GetAcquiringBillingVm[];
  isLoading: boolean;
  tableRef?: React.MutableRefObject<null>;
};

export function ReportTable({ billings, banks, isLoading, tableRef }: Props) {
  // const currencyText = useMemo(() => {
  //   const text = banks.find((bank) => String(bank.bankId) === bankIdParam)
  //     ?.country.currencyText;

  //   return text ? `(${text})` : '';
  // }, [bankIdParam]);

  const currencyText = '';

  const paymentSummary = toFixedDecimals(
    billings.reduce((acc, el) => {
      return (acc * 1000 + (el.invoiceSum ?? 0) * 1000) / 1000;
    }, 0),
    2
  );

  return (
    <Spin spinning={isLoading}>
      <StyledTable ref={tableRef}>
        <StyledThead>
          <StyledTr>
            <StyledTh>Дата оплаты</StyledTh>
            <StyledTh>Пользователь</StyledTh>
            <StyledTh>{`Сумма операций (${currencyText}.)`}</StyledTh>
            <StyledTh>Номер ЭЗС</StyledTh>
            <StyledTh>Адрес</StyledTh>
            <StyledTh>ИНН Агента</StyledTh>
          </StyledTr>
        </StyledThead>

        <StyledTbody>
          {billings.map((el) => {
            const {
              chargePointAddress,
              chargePointName,
              companyINN,
              paymentDate,
              invoiceSum,
              userLogin,
            } = el;
            return (
              <StyledTr>
                <StyledTd>
                  {paymentDate
                    ? showTimeString(paymentDate, FORMAT_TO_MINUTES)
                    : ''}
                </StyledTd>
                <StyledTd>{userLogin}</StyledTd>
                <StyledTd>
                  {invoiceSum === undefined ? '' : invoiceSum}
                </StyledTd>
                <StyledTd>{chargePointName}</StyledTd>
                <StyledTd>{chargePointAddress}</StyledTd>
                <StyledTd>{companyINN}</StyledTd>
              </StyledTr>
            );
          })}
        </StyledTbody>

        <StyledTfoot>
          <StyledTr>
            <StyledTd style={{ gridColumn: 'span 2' }}>Итого</StyledTd>
            <StyledTd>{paymentSummary}</StyledTd>
            <StyledTd
              style={{ gridColumn: 'span 3' }}
            >{`Всего операций: ${billings.length}`}</StyledTd>
          </StyledTr>
        </StyledTfoot>
      </StyledTable>
    </Spin>
  );
}
