import { TargetsServicesListsDto } from 'shared/api/services/billing/rtk/generated';

export const getServicesListId = (
  targetServicesLists: TargetsServicesListsDto[]
) => {
  const servicesList = targetServicesLists[0];

  if (!servicesList) {
    return undefined;
  }

  return servicesList.servicesListId;
};
