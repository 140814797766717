import { COLOR } from 'shared/consts';
import {
  ChargePointProtocol,
  ChargePointStatus,
  CountryCode,
  SpeedType,
} from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { postApiChargepointV1ChargePointsBody } from 'shared/api/services/chargepoint/orval/zod/schemas';

export const ADDRESS_MAX_LENGTH = 45;

export const CITY_MIN_LENGTH = 3;

export const CHARGE_POINT_SPEED_TYPE: Record<SpeedType, string> = {
  HIGH_SPEED_DC: 'Быстрая',
  LOW_SPEED_AC: 'Медленная',
  UNDEFINED: 'Не определено',
};

export const CHARGE_POINT_STATUS: Record<ChargePointStatus, string> = {
  AVAILABLE: 'Свободна, Доступна',
  UNAVAILABLE: 'Не доступна',
  NO_CONNECT: 'Нет связи',
  FAULTED: 'Неисправна',
};

export const CHARGE_POINT_PROTOCOL: Record<ChargePointProtocol, string> = {
  OCPP16: 'OCPP16',
  OCPP16J: 'OCPP16J',
  OCPP20: 'OCPP20',
};

export const CHARGE_POINT_STATUS_COLOR: Record<ChargePointStatus, string> = {
  NO_CONNECT: COLOR.uninitialized,
  AVAILABLE: COLOR.greenMenu,
  UNAVAILABLE: COLOR.red,
  FAULTED: COLOR.red,
};

export const COUNTRY_CODE: Record<CountryCode, string> = {
  AM: 'AM',
  BY: 'BY',
  KG: 'KG',
  KZ: 'KZ',
  RU: 'RU',
};

export const CHARGE_POINT_SPEED_TYPE_BADGE: Record<
  Exclude<SpeedType, 'UNDEFINED'>,
  string
> = {
  HIGH_SPEED_DC: 'DC',
  LOW_SPEED_AC: 'AC',
};

export const CHARGE_POINT_SPEED_TYPE_COLOR: Record<
  Exclude<SpeedType, 'UNDEFINED'>,
  string
> = {
  LOW_SPEED_AC: COLOR.yellow,
  HIGH_SPEED_DC: COLOR.greenMenu,
};

export const OCPP_CONFIG_REGEX = /^[\w]+=[\w\"]+(\s[\w]+=[\w\"]+)*$/;

const ERROR_TEXT = 'Формат данных: пара ключ=значение на отдельной строке';

export const speedType = postApiChargepointV1ChargePointsBody.shape.speedType;
export const status = postApiChargepointV1ChargePointsBody.shape.status;
export const chargePointProtocol =
  postApiChargepointV1ChargePointsBody.shape.chargePointProtocol;
export const countryCode =
  postApiChargepointV1ChargePointsBody.shape.countryCode;

export const METRICS_POLLING_INTERVAL = 60000;

export type InfrastructureInfo =
  | 'cafe'
  | 'closeAccess'
  | 'hotel'
  | 'hour24'
  | 'park'
  | 'parking'
  | 'shop'
  | 'shoppingMall'
  | 'wc'
  | 'wifi';

export const INFRASTRUCTURE_INFO: Record<InfrastructureInfo, string> = {
  cafe: 'кафе',
  hotel: 'отель',
  closeAccess: 'закрытый доступ',
  hour24: 'круглосуточный режим',
  park: 'парк',
  parking: 'парковка',
  shop: 'магазин',
  shoppingMall: 'торговый центр',
  wc: 'туалет',
  wifi: 'Wi-Fi',
};

// Фото

export const MAX_FILE_SIZE_MB = 1;
