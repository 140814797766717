import { z } from 'zod';

import { requiredNumber, requiredString } from 'shared/lib/schema';

export const FormSchema = z.object({
  sum: requiredNumber,
  countryId: requiredString,
  reason: z.string(),
  type: z.enum(['debit', 'credit']),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
