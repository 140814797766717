export function ActionIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#23C990" fill-opacity="0.2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0725 14.7855L22.8654 14.0513C23.1693 13.7699 23.6438 13.7882 23.9253 14.0921C24.2067 14.396 24.1885 14.8706 23.8845 15.152L21.6864 17.1873L21.1361 17.6969L20.6265 17.1466L18.5912 14.9485C18.3097 14.6445 18.328 14.17 18.6319 13.8886C18.9358 13.6072 19.4104 13.6254 19.6918 13.9293L20.5709 14.8787C20.5902 14.1459 20.4549 13.4138 20.1698 12.7313C19.7724 11.78 19.1018 10.968 18.2428 10.3978C17.3839 9.82764 16.3751 9.52499 15.3442 9.52812C14.3132 9.53126 13.3063 9.84004 12.4509 10.4154L12.0323 9.79308L12.4509 10.4154C11.5954 10.9908 10.9298 11.8069 10.5381 12.7606C10.1465 13.7143 10.0465 14.7627 10.2507 15.7732C10.4549 16.7838 10.9541 17.711 11.6854 18.4378C12.4166 19.1646 13.3469 19.6582 14.3586 19.8563C14.7651 19.9359 15.0302 20.3299 14.9506 20.7364C14.871 21.1429 14.477 21.4079 14.0705 21.3284C12.7676 21.0733 11.5696 20.4376 10.6279 19.5017C9.6863 18.5658 9.04335 17.3717 8.78038 16.0703C8.51742 14.769 8.64624 13.4189 9.15058 12.1908C9.65491 10.9627 10.5121 9.9117 11.6137 9.17075C12.7154 8.4298 14.012 8.03217 15.3396 8.02813C16.6672 8.02409 17.9663 8.41383 19.0724 9.14807C20.1785 9.8823 21.0421 10.9281 21.5539 12.1531C21.9038 12.9906 22.0785 13.8863 22.0725 14.7855Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  );
}
