import styled from 'styled-components';

export const StyledButton = styled.button`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

  color: rgba(0, 0, 0, 0.5);

  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  cursor: pointer;
  padding: 12px 8px;
`;
