import styled from 'styled-components';

export const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormFieldsGroup = styled.div``;

export const FormFieldsGroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 16px;
`;

export const ServiceTitle = styled.h4`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ControlsContainer = styled.div`
  margin-left: 12px;
  display: flex;
  gap: 20px;
`;

export const ControlButton = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const Label = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 6px;
`;
