import { Col, Spin, UploadProps } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { FormRow } from 'shared/ui/form';
import { GetPhotoVm } from 'shared/api/services/chargepoint/rtk/generated/photos';
import { CustomUploadDragger } from 'shared/ui/form/custom-upload-dragger';
import { UploadDraggerButton } from 'shared/ui/ui-kit/upload-dragger/button';

import { accepted, UploadingPhotoPreview } from 'entities/charge-point-photo';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';
import { useUpdatePhoto } from '../hooks/use-update-photo';

type Props = {
  photo: GetPhotoVm;
  chargePointId: string;
  closeModal: () => void;
};

export function Form({ chargePointId, closeModal, photo }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { priority: photo.priority },
  });

  const { handleUpdate, isLoading } = useUpdatePhoto();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ chargePointId, closeModal, data, photoId: photo.id });
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const props: UploadProps<File> = {
    maxCount: 1,
    multiple: false,
    accept: accepted,
    beforeUpload: (_file, _list) => {
      return false;
    },
    listType: 'picture',
    itemRender: (originNode, file, _fileList, actions) => {
      if (!file.originFileObj) {
        return 'Нет оригинального файла';
      }

      const errorMsg = errors.file?.message;

      return (
        <UploadingPhotoPreview
          fileName={file.name}
          imageSrc={URL.createObjectURL(file.originFileObj)}
          handleDelete={() => actions.remove()}
          errorMsg={errorMsg}
        />
      );
    },
  };

  const hint = (
    <div style={{ marginBottom: '15px' }}>
      <p style={{ marginBottom: '5px' }}>Поддерживаемые форматы:</p>
      <p>{accepted}</p>
    </div>
  );

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="priority" label="Приоритет" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomUploadDragger<FormInput>
            {...props}
            name="file"
            label="Фотография"
          >
            <>
              {hint}
              <UploadDraggerButton />
            </>
          </CustomUploadDragger>
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
