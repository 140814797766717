import { z } from 'zod';

import { postApiBillingTariffsBody } from 'shared/api/services/billing/orval/zod/schemas';
import { emptyStringToUndefined } from 'shared/lib/schema';

const type = postApiBillingTariffsBody.shape.type;

const FormResultSchema = z.object({
  name: z.string().min(1),
  countryId: z.string().min(1),
  price: z.number().gte(0),
  type: type,
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    countryId: z.string().transform(emptyStringToUndefined),
    price: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    type: z.string().transform(emptyStringToUndefined),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  countryId: '',
  price: '',
  type: '',
};
