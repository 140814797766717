import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiIdentityV1UsersLogin: build.mutation<
      PostApiIdentityV1UsersLoginApiResponse,
      PostApiIdentityV1UsersLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/Login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiIdentityV1UsersRefreshTokens: build.mutation<
      PostApiIdentityV1UsersRefreshTokensApiResponse,
      PostApiIdentityV1UsersRefreshTokensApiArg
    >({
      query: () => ({
        url: `/Api/Identity/V1/Users/RefreshTokens`,
        method: 'POST',
      }),
    }),
    postApiIdentityV1UsersByLoginLockoutUser: build.mutation<
      PostApiIdentityV1UsersByLoginLockoutUserApiResponse,
      PostApiIdentityV1UsersByLoginLockoutUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}/LockoutUser`,
        method: 'POST',
      }),
    }),
    postApiIdentityV1UsersByLoginUnlockUser: build.mutation<
      PostApiIdentityV1UsersByLoginUnlockUserApiResponse,
      PostApiIdentityV1UsersByLoginUnlockUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}/UnlockUser`,
        method: 'POST',
      }),
    }),
    getApiIdentityV1UsersByUserName: build.query<
      GetApiIdentityV1UsersByUserNameApiResponse,
      GetApiIdentityV1UsersByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Users/${queryArg}` }),
    }),
    deleteApiIdentityV1UsersByUserName: build.mutation<
      DeleteApiIdentityV1UsersByUserNameApiResponse,
      DeleteApiIdentityV1UsersByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityV1Users: build.query<
      GetApiIdentityV1UsersApiResponse,
      GetApiIdentityV1UsersApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Users` }),
    }),
    postApiIdentityV1Users: build.mutation<
      PostApiIdentityV1UsersApiResponse,
      PostApiIdentityV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Users: build.mutation<
      PatchApiIdentityV1UsersApiResponse,
      PatchApiIdentityV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    postApiIdentityV1UserRoles: build.mutation<
      PostApiIdentityV1UserRolesApiResponse,
      PostApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    putApiIdentityV1UserRoles: build.mutation<
      PutApiIdentityV1UserRolesApiResponse,
      PutApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserRoles: build.mutation<
      DeleteApiIdentityV1UserRolesApiResponse,
      DeleteApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserRolesByUserName: build.query<
      GetApiIdentityV1UserRolesByUserNameApiResponse,
      GetApiIdentityV1UserRolesByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserRoles/${queryArg}` }),
    }),
    postApiIdentityV1UserGroups: build.mutation<
      PostApiIdentityV1UserGroupsApiResponse,
      PostApiIdentityV1UserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserGroups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserGroups: build.mutation<
      DeleteApiIdentityV1UserGroupsApiResponse,
      DeleteApiIdentityV1UserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserGroups`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserClaimsByUserName: build.query<
      GetApiIdentityV1UserClaimsByUserNameApiResponse,
      GetApiIdentityV1UserClaimsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserClaims/${queryArg}` }),
    }),
    postApiIdentityV1UserClaims: build.mutation<
      PostApiIdentityV1UserClaimsApiResponse,
      PostApiIdentityV1UserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserClaims: build.mutation<
      DeleteApiIdentityV1UserClaimsApiResponse,
      DeleteApiIdentityV1UserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1RolesByRoleName: build.query<
      GetApiIdentityV1RolesByRoleNameApiResponse,
      GetApiIdentityV1RolesByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Roles/${queryArg}` }),
    }),
    deleteApiIdentityV1RolesByRoleName: build.mutation<
      DeleteApiIdentityV1RolesByRoleNameApiResponse,
      DeleteApiIdentityV1RolesByRoleNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityV1Roles: build.query<
      GetApiIdentityV1RolesApiResponse,
      GetApiIdentityV1RolesApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Roles` }),
    }),
    postApiIdentityV1Roles: build.mutation<
      PostApiIdentityV1RolesApiResponse,
      PostApiIdentityV1RolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Roles: build.mutation<
      PatchApiIdentityV1RolesApiResponse,
      PatchApiIdentityV1RolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getApiIdentityV1RoleClaimsByRoleName: build.query<
      GetApiIdentityV1RoleClaimsByRoleNameApiResponse,
      GetApiIdentityV1RoleClaimsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/RoleClaims/${queryArg}` }),
    }),
    postApiIdentityV1RoleClaims: build.mutation<
      PostApiIdentityV1RoleClaimsApiResponse,
      PostApiIdentityV1RoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1RoleClaims: build.mutation<
      DeleteApiIdentityV1RoleClaimsApiResponse,
      DeleteApiIdentityV1RoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserRightsByUserName: build.query<
      GetApiIdentityV1UserRightsByUserNameApiResponse,
      GetApiIdentityV1UserRightsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserRights/${queryArg}` }),
    }),
    getApiIdentityV1RoleRightsByRoleName: build.query<
      GetApiIdentityV1RoleRightsByRoleNameApiResponse,
      GetApiIdentityV1RoleRightsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/RoleRights/${queryArg}` }),
    }),
    postApiIdentityV1SetUserRights: build.mutation<
      PostApiIdentityV1SetUserRightsApiResponse,
      PostApiIdentityV1SetUserRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/SetUserRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiIdentityV1SetRoleRights: build.mutation<
      PostApiIdentityV1SetRoleRightsApiResponse,
      PostApiIdentityV1SetRoleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/SetRoleRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiIdentityV1GroupsByGroupName: build.query<
      GetApiIdentityV1GroupsByGroupNameApiResponse,
      GetApiIdentityV1GroupsByGroupNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Groups/${queryArg}` }),
    }),
    getApiIdentityV1Groups: build.query<
      GetApiIdentityV1GroupsApiResponse,
      GetApiIdentityV1GroupsApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Groups` }),
    }),
    postApiIdentityV1Groups: build.mutation<
      PostApiIdentityV1GroupsApiResponse,
      PostApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Groups: build.mutation<
      PatchApiIdentityV1GroupsApiResponse,
      PatchApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1Groups: build.mutation<
      DeleteApiIdentityV1GroupsApiResponse,
      DeleteApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'DELETE',
        params: {
          GroupId: queryArg.groupId,
          GroupName: queryArg.groupName,
        },
      }),
    }),
    headApiIdentityV1SystemActionsLogOutAllUsers: build.mutation<
      HeadApiIdentityV1SystemActionsLogOutAllUsersApiResponse,
      HeadApiIdentityV1SystemActionsLogOutAllUsersApiArg
    >({
      query: () => ({
        url: `/Api/Identity/V1/System/Actions/LogOutAllUsers`,
        method: 'HEAD',
      }),
    }),
    postApiIdentityV1SystemActionsGetSicretKey: build.mutation<
      PostApiIdentityV1SystemActionsGetSicretKeyApiResponse,
      PostApiIdentityV1SystemActionsGetSicretKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/System/Actions/GetSicretKey`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiIdentityV1UsersLoginApiResponse =
  /** status 200 OK */ IdentityServiceClientLibResponseLoginResponse;
export type PostApiIdentityV1UsersLoginApiArg =
  IdentityServiceClientLibRequestLoginRequest;
export type PostApiIdentityV1UsersRefreshTokensApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseRefreshTokensResponse;
export type PostApiIdentityV1UsersRefreshTokensApiArg = void;
export type PostApiIdentityV1UsersByLoginLockoutUserApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UsersByLoginLockoutUserApiArg = string;
export type PostApiIdentityV1UsersByLoginUnlockUserApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UsersByLoginUnlockUserApiArg = string;
export type GetApiIdentityV1UsersByUserNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetUserResponse;
export type GetApiIdentityV1UsersByUserNameApiArg = string;
export type DeleteApiIdentityV1UsersByUserNameApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1UsersByUserNameApiArg = string;
export type GetApiIdentityV1UsersApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetUsersResponse;
export type GetApiIdentityV1UsersApiArg = void;
export type PostApiIdentityV1UsersApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UsersApiArg =
  IdentityServiceWebApiRequestAddUserRequest;
export type PatchApiIdentityV1UsersApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PatchApiIdentityV1UsersApiArg =
  IdentityServiceWebApiRequestUpdateUserRequest;
export type PostApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UserRolesApiArg =
  IdentityServiceWebApiRequestAddUserRoleRequest;
export type PutApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PutApiIdentityV1UserRolesApiArg =
  IdentityServiceWebApiRequestUpdateUserRoleRequest;
export type DeleteApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1UserRolesApiArg =
  IdentityServiceWebApiRequestDeleteUserRoleRequest;
export type GetApiIdentityV1UserRolesByUserNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetUserRolesResponse;
export type GetApiIdentityV1UserRolesByUserNameApiArg = string;
export type PostApiIdentityV1UserGroupsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UserGroupsApiArg =
  IdentityServiceWebApiRequestAddUserGroupRequest;
export type DeleteApiIdentityV1UserGroupsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1UserGroupsApiArg =
  IdentityServiceWebApiRequestDeleteUserGroupRequest;
export type GetApiIdentityV1UserClaimsByUserNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityV1UserClaimsByUserNameApiArg = string;
export type PostApiIdentityV1UserClaimsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1UserClaimsApiArg =
  IdentityServiceWebApiRequestAddUserClaimRequest;
export type DeleteApiIdentityV1UserClaimsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1UserClaimsApiArg =
  IdentityServiceWebApiRequestDeleteUserClaimRequest;
export type GetApiIdentityV1RolesByRoleNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRoleResponse;
export type GetApiIdentityV1RolesByRoleNameApiArg = string;
export type DeleteApiIdentityV1RolesByRoleNameApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1RolesByRoleNameApiArg = string;
export type GetApiIdentityV1RolesApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRolesResponse;
export type GetApiIdentityV1RolesApiArg = void;
export type PostApiIdentityV1RolesApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseAddRoleResponse;
export type PostApiIdentityV1RolesApiArg =
  IdentityServiceWebApiRequestAddRoleRequest;
export type PatchApiIdentityV1RolesApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseUpdateRoleResponse;
export type PatchApiIdentityV1RolesApiArg =
  IdentityServiceWebApiRequestUpdateRoleRequest;
export type GetApiIdentityV1RoleClaimsByRoleNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityV1RoleClaimsByRoleNameApiArg = string;
export type PostApiIdentityV1RoleClaimsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1RoleClaimsApiArg =
  IdentityServiceWebApiRequestAddRoleClaimRequest;
export type DeleteApiIdentityV1RoleClaimsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1RoleClaimsApiArg =
  IdentityServiceWebApiRequestDeleteRoleClaimRequest;
export type GetApiIdentityV1UserRightsByUserNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRightsResponse;
export type GetApiIdentityV1UserRightsByUserNameApiArg = string;
export type GetApiIdentityV1RoleRightsByRoleNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetRightsResponse;
export type GetApiIdentityV1RoleRightsByRoleNameApiArg = string;
export type PostApiIdentityV1SetUserRightsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1SetUserRightsApiArg =
  IdentityServiceWebApiRequestSetUserRightsRequest;
export type PostApiIdentityV1SetRoleRightsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1SetRoleRightsApiArg =
  IdentityServiceWebApiRequestSetRoleRightsRequest;
export type GetApiIdentityV1GroupsByGroupNameApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetGroupResponse;
export type GetApiIdentityV1GroupsByGroupNameApiArg = string;
export type GetApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseGetGroupsResponse;
export type GetApiIdentityV1GroupsApiArg = void;
export type PostApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type PostApiIdentityV1GroupsApiArg =
  IdentityServiceWebApiRequestAddGroupRequest;
export type PatchApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ IdentityServiceWebApiResponseUpdateGroupResponse;
export type PatchApiIdentityV1GroupsApiArg =
  IdentityServiceWebApiRequestUpdateGroupRequest;
export type DeleteApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type DeleteApiIdentityV1GroupsApiArg = {
  groupId?: string;
  groupName?: string;
};
export type HeadApiIdentityV1SystemActionsLogOutAllUsersApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase;
export type HeadApiIdentityV1SystemActionsLogOutAllUsersApiArg = void;
export type PostApiIdentityV1SystemActionsGetSicretKeyApiResponse =
  /** status 200 OK */ string;
export type PostApiIdentityV1SystemActionsGetSicretKeyApiArg =
  IdentityServiceClientLibRequestLoginRequest;
export type CommonsContractsModelsEnumsStatus =
  | 'SUCCESS'
  | 'ERROR'
  | 'PART_UNAVAILABLE';
export type CommonsContractsModelsEnumsUnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type IdentityServiceClientLibResponseLoginResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  accessToken: string;
  refreshToken: string;
};
export type IdentityServiceClientLibRequestLoginRequest = {
  login: string;
  password: string;
};
export type IdentityServiceWebApiResponseRefreshTokensResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  accessToken: string;
  refreshToken: string;
};
export type CommonsContractsModelsResponseBase = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
};
export type IdentityServiceApplicationDtoClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type IdentityServiceApplicationDtoUserDto = {
  id?: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  email?: string | null;
  groups?: string[] | null;
  roles?: string[] | null;
  claims?: IdentityServiceApplicationDtoClaimDto[] | null;
  lockoutEnd?: string | null;
  userLockoutEnd?: string | null;
  isSystem?: boolean;
};
export type IdentityServiceWebApiResponseGetUserResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  user: IdentityServiceApplicationDtoUserDto;
};
export type IdentityServiceWebApiResponseGetUsersResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  users: IdentityServiceApplicationDtoUserDto[];
};
export type IdentityServiceWebApiRequestAddUserRequest = {
  userName?: string | null;
  email: string;
  password: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type IdentityServiceWebApiRequestUpdateUserRequest = {
  id: string;
  userName?: string | null;
  email?: string | null;
  newPassword?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
};
export type IdentityServiceWebApiRequestAddUserRoleRequest = {
  userName: string;
  roles: string[];
};
export type IdentityServiceWebApiRequestUpdateUserRoleRequest = {
  userName?: string | null;
  roles?: string[] | null;
};
export type IdentityServiceWebApiRequestDeleteUserRoleRequest = {
  userName: string;
  roleName: string;
};
export type IdentityServiceWebApiDtoRoleDto = {
  id: string;
  name: string;
};
export type IdentityServiceWebApiResponseGetUserRolesResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  roles: IdentityServiceWebApiDtoRoleDto[];
};
export type IdentityServiceWebApiRequestAddUserGroupRequest = {
  userName: string;
  groupName: string;
};
export type IdentityServiceWebApiRequestDeleteUserGroupRequest = {
  userName: string;
  groupId?: string | null;
  groupName?: string | null;
};
export type IdentityServiceWebApiDtoClaimDto = {
  type: string;
  value: string;
};
export type IdentityServiceWebApiResponseGetRoleClaimsResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  claims: IdentityServiceWebApiDtoClaimDto[];
};
export type IdentityServiceWebApiRequestAddUserClaimRequest = {
  userName: string;
  claimType: string;
  claimValue: string;
};
export type IdentityServiceWebApiRequestDeleteUserClaimRequest = {
  userName: string;
  claimType: string;
  claimValue: string;
};
export type IdentityServiceApplicationDtoUserReduceInfo = {
  id?: string;
  userName?: string | null;
};
export type IdentityServiceApplicationDtoRoleWithUsers = {
  id: string;
  name: string;
  users: IdentityServiceApplicationDtoUserReduceInfo[];
};
export type IdentityServiceWebApiResponseGetRoleResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  role: IdentityServiceApplicationDtoRoleWithUsers;
};
export type IdentityServiceWebApiDtoRoleWithUsersDto = {
  id: string;
  name: string;
  users: IdentityServiceApplicationDtoUserReduceInfo[];
};
export type IdentityServiceWebApiResponseGetRolesResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  roles: IdentityServiceWebApiDtoRoleWithUsersDto[];
};
export type IdentityServiceWebApiResponseAddRoleResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
};
export type IdentityServiceWebApiRequestAddRoleRequest = {
  name: string;
};
export type IdentityServiceApplicationIdentityEntityRole = {
  id?: string;
  name?: string | null;
  normalizedName?: string | null;
  concurrencyStamp?: string | null;
};
export type IdentityServiceWebApiResponseUpdateRoleResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  role: IdentityServiceApplicationIdentityEntityRole;
};
export type IdentityServiceWebApiRequestUpdateRoleRequest = {
  id: string;
  name: string;
};
export type IdentityServiceWebApiRequestAddRoleClaimRequest = {
  roleName: string;
  claimType: string;
  claimValue: string;
};
export type IdentityServiceWebApiRequestDeleteRoleClaimRequest = {
  roleName: string;
  claimType: string;
  claimValue: string;
};
export type IdentityServiceApplicationModelsControllerRweRight = {
  controllerName: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type IdentityServiceWebApiResponseGetRightsResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  rights: IdentityServiceApplicationModelsControllerRweRight[];
};
export type IdentityServiceWebApiRequestSetUserRightsRequest = {
  userName: string;
  controllerRights: IdentityServiceApplicationModelsControllerRweRight[];
};
export type IdentityServiceWebApiRequestSetRoleRightsRequest = {
  roleName: string;
  controllerRights: IdentityServiceApplicationModelsControllerRweRight[];
};
export type IdentityServiceWebApiDtoUserReduceInfoDto = {
  id?: string;
  userName?: string | null;
};
export type IdentityServiceWebApiDtoGroupDto = {
  id: string;
  name: string;
  users: IdentityServiceWebApiDtoUserReduceInfoDto[];
};
export type IdentityServiceWebApiResponseGetGroupResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  group: IdentityServiceWebApiDtoGroupDto;
};
export type IdentityServiceWebApiResponseGetGroupsResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  groups: IdentityServiceWebApiDtoGroupDto[];
};
export type IdentityServiceWebApiRequestAddGroupRequest = {
  name: string;
};
export type IdentityServiceApplicationIdentityEntityUser = {
  id?: string;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  groups?: IdentityServiceApplicationIdentityEntityGroup[] | null;
  userLockoutEnd?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type IdentityServiceApplicationIdentityEntityGroup = {
  id: string;
  name: string;
  users: IdentityServiceApplicationIdentityEntityUser[];
};
export type IdentityServiceWebApiResponseUpdateGroupResponse = {
  status?: CommonsContractsModelsEnumsStatus;
  statusMessage?: string | null;
  unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  group: IdentityServiceApplicationIdentityEntityGroup;
};
export type IdentityServiceWebApiRequestUpdateGroupRequest = {
  id: string;
  name: string;
};
export const {
  usePostApiIdentityV1UsersLoginMutation,
  usePostApiIdentityV1UsersRefreshTokensMutation,
  usePostApiIdentityV1UsersByLoginLockoutUserMutation,
  usePostApiIdentityV1UsersByLoginUnlockUserMutation,
  useGetApiIdentityV1UsersByUserNameQuery,
  useDeleteApiIdentityV1UsersByUserNameMutation,
  useGetApiIdentityV1UsersQuery,
  usePostApiIdentityV1UsersMutation,
  usePatchApiIdentityV1UsersMutation,
  usePostApiIdentityV1UserRolesMutation,
  usePutApiIdentityV1UserRolesMutation,
  useDeleteApiIdentityV1UserRolesMutation,
  useGetApiIdentityV1UserRolesByUserNameQuery,
  usePostApiIdentityV1UserGroupsMutation,
  useDeleteApiIdentityV1UserGroupsMutation,
  useGetApiIdentityV1UserClaimsByUserNameQuery,
  usePostApiIdentityV1UserClaimsMutation,
  useDeleteApiIdentityV1UserClaimsMutation,
  useGetApiIdentityV1RolesByRoleNameQuery,
  useDeleteApiIdentityV1RolesByRoleNameMutation,
  useGetApiIdentityV1RolesQuery,
  usePostApiIdentityV1RolesMutation,
  usePatchApiIdentityV1RolesMutation,
  useGetApiIdentityV1RoleClaimsByRoleNameQuery,
  usePostApiIdentityV1RoleClaimsMutation,
  useDeleteApiIdentityV1RoleClaimsMutation,
  useGetApiIdentityV1UserRightsByUserNameQuery,
  useGetApiIdentityV1RoleRightsByRoleNameQuery,
  usePostApiIdentityV1SetUserRightsMutation,
  usePostApiIdentityV1SetRoleRightsMutation,
  useGetApiIdentityV1GroupsByGroupNameQuery,
  useGetApiIdentityV1GroupsQuery,
  usePostApiIdentityV1GroupsMutation,
  usePatchApiIdentityV1GroupsMutation,
  useDeleteApiIdentityV1GroupsMutation,
  useHeadApiIdentityV1SystemActionsLogOutAllUsersMutation,
  usePostApiIdentityV1SystemActionsGetSicretKeyMutation,
} = injectedRtkApi;
