import {
  useBillingActionFreeze,
  useBillingActionUnfreeze,
} from 'shared/api/services/billing/rtk/enhanced';
import { enhancedApi } from 'shared/api/services/identity/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

export const useToggleFreeze = (balanceId: string, userId: string) => {
  const [freezeTrigger, { isLoading: isFreezeLoading }] =
    useBillingActionFreeze();
  const [unfreezeTrigger, { isLoading: isUnfreezeLoading }] =
    useBillingActionUnfreeze();

  const dispatch = useAppDispatch();

  const toggleFreeze = async (value: boolean) => {
    const SUCCESS_MSG =
      value === true ? 'Баланс заблокирован' : 'Баланс разблокирован';
    const ERROR_MSG =
      value === true
        ? 'Не удалось заблокировать баланс'
        : 'Не удалось разблокировать баланс';

    try {
      if (value === true) {
        await freezeTrigger({ balanceId });
      } else {
        await unfreezeTrigger({ balanceId });
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'BillingBalances', id: userId },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return { toggleFreeze, isLoading: isFreezeLoading || isUnfreezeLoading };
};
