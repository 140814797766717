import { enhancedApi as api } from '../generated/commands';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {},
});

export const {
  usePostApiChargepointV1CommandsRestartMutation: useCommandRestart,
  usePostApiChargepointV1CommandsLoadFirmwareMutation: useCommandLoadFirmware,
  usePostApiChargepointV1CommandsConfigureMutation: useCommandConfigure,
  usePostApiChargepointV1CommandsDiagnosticMutation: useCommandDiagnostic,
  usePostApiChargepointV1CommandsChangeAvailabilityMutation:
    useCommandChangeAvailability,
  usePostApiChargepointV1CommandsStopMutation: useCommandStop,
} = enhancedApi;
