import { REPORTS } from '../names';

export const ACQUIRING = 'acquiring';
// export const CONSUME = 'consume';
// export const PAYMENTS = 'payments';
// export const DEBIT = 'debit';

const ROOT = `/${REPORTS}`;

export const REPORT_ROUES = {
  LIST: ROOT,
  ACQUIRING: `${ROOT}/${ACQUIRING}`,
};
