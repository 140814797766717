import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiChargepointV1CommandsStart: build.mutation<
      PostApiChargepointV1CommandsStartApiResponse,
      PostApiChargepointV1CommandsStartApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/START`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsStop: build.mutation<
      PostApiChargepointV1CommandsStopApiResponse,
      PostApiChargepointV1CommandsStopApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/STOP`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsSetMaxPower: build.mutation<
      PostApiChargepointV1CommandsSetMaxPowerApiResponse,
      PostApiChargepointV1CommandsSetMaxPowerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/SET_MAX_POWER`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsRestart: build.mutation<
      PostApiChargepointV1CommandsRestartApiResponse,
      PostApiChargepointV1CommandsRestartApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/RESTART`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsLoadFirmware: build.mutation<
      PostApiChargepointV1CommandsLoadFirmwareApiResponse,
      PostApiChargepointV1CommandsLoadFirmwareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/LOAD_FIRMWARE`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsConfigure: build.mutation<
      PostApiChargepointV1CommandsConfigureApiResponse,
      PostApiChargepointV1CommandsConfigureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/CONFIGURE`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsDiagnostic: build.mutation<
      PostApiChargepointV1CommandsDiagnosticApiResponse,
      PostApiChargepointV1CommandsDiagnosticApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/DIAGNOSTIC`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargepointV1CommandsChangeAvailability: build.mutation<
      PostApiChargepointV1CommandsChangeAvailabilityApiResponse,
      PostApiChargepointV1CommandsChangeAvailabilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Commands/CHANGE_AVAILABILITY`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiChargepointV1CommandsStartApiResponse =
  /** status 200 Success */ StartCommandVmBaseResponse;
export type PostApiChargepointV1CommandsStartApiArg =
  /** Параметры */ StartCommandRequest;
export type PostApiChargepointV1CommandsStopApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsStopApiArg =
  /** Параметры */ StopCommandRequest;
export type PostApiChargepointV1CommandsSetMaxPowerApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsSetMaxPowerApiArg =
  SetMaxPowerCommandRequest;
export type PostApiChargepointV1CommandsRestartApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsRestartApiArg = RestartCommandRequest;
export type PostApiChargepointV1CommandsLoadFirmwareApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsLoadFirmwareApiArg =
  LoadFirmwareCommandRequest;
export type PostApiChargepointV1CommandsConfigureApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsConfigureApiArg =
  ConfigureCommandRequest;
export type PostApiChargepointV1CommandsDiagnosticApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsDiagnosticApiArg =
  DiagnosticCommandRequest;
export type PostApiChargepointV1CommandsChangeAvailabilityApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargepointV1CommandsChangeAvailabilityApiArg =
  ChangeAvailabilityCommandRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type StartCommandVm = {
  sessionId?: string;
};
export type StartCommandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: StartCommandVm;
};
export type StartCommandRequest = {
  userId: string;
  chargePointId: string;
  connectorId: string;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type StopCommandRequest = {
  chargePointId: string;
  connectorId: string;
};
export type SetMaxPowerCommandRequest = {
  chargePointId: string;
  maxPower: number;
};
export type ResetType = 'HARD' | 'SOFT';
export type RestartCommandRequest = {
  chargePointId?: string;
  type?: ResetType;
};
export type LoadFirmwareCommandRequest = {
  chargePointId?: string;
  downloadUrl?: string | null;
  retrieveDate?: string;
};
export type ConfigureCommandRequest = {
  chargePointId?: string;
  key?: string | null;
  newValue?: string | null;
};
export type DiagnosticCommandRequest = {
  chargePointId?: string;
  uploadUrl?: string | null;
};
export type AvailabilityChangeType = 'INOPERATIVE' | 'OPERATIVE';
export type ChangeAvailabilityCommandRequest = {
  chargePointId?: string;
  innerConnectorId?: number;
  type?: AvailabilityChangeType;
};
export const {
  usePostApiChargepointV1CommandsStartMutation,
  usePostApiChargepointV1CommandsStopMutation,
  usePostApiChargepointV1CommandsSetMaxPowerMutation,
  usePostApiChargepointV1CommandsRestartMutation,
  usePostApiChargepointV1CommandsLoadFirmwareMutation,
  usePostApiChargepointV1CommandsConfigureMutation,
  usePostApiChargepointV1CommandsDiagnosticMutation,
  usePostApiChargepointV1CommandsChangeAvailabilityMutation,
} = injectedRtkApi;
