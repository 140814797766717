import styled from 'styled-components';
import { Input, Button } from 'antd';

import { COLOR } from 'shared/consts';

// из формы коннектора
export const StyledInput = styled(Input)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  padding: 7px;
  border: 0.5px solid ${COLOR.inputGrey};
  border-radius: 2px;
  // height: 29px;
`;

//
export const InputContainer = styled.div`
  margin-bottom: 10px;
`;

export const InputLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
  margin-bottom: 5px;
`;

//

export const StyledDraggerFormItem = styled.div`
  & .ant-upload.ant-upload-drag {
    margin-bottom: 10px;
  }

  & .ant-upload-list.ant-upload-list-text > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ClickableSpan = styled.span`
  color: ${COLOR.greenMenu};
  cursor: pointer;
`;

export const StyledAddButton = styled.button`
  cursor: pointer;
  width: 19px;
`;
