import { DeleteOutlined } from '@ant-design/icons';

import { COLOR } from 'shared/consts';

import {
  ErrorText,
  Filename,
  ControlsContainer,
  StyledContainer,
  PhotoContainer,
  StyledImage,
} from './styles';

type Props = {
  fileName: string;
  imageSrc: string;
  handleDelete: () => void;
  errorMsg?: string;
};

export function UploadingPhotoPreview({
  fileName,
  imageSrc,
  handleDelete,
  errorMsg,
}: Props) {
  const isError = Boolean(errorMsg);

  const deleteIconColor = isError ? COLOR.red : COLOR.black;

  return (
    <StyledContainer>
      <PhotoContainer isError={isError}>
        <StyledImage style={{ backgroundImage: `url("${imageSrc}")` }} />
        <Filename>{fileName}</Filename>
        <ControlsContainer>
          <DeleteOutlined
            onClick={handleDelete}
            style={{ color: deleteIconColor }}
          />
        </ControlsContainer>
      </PhotoContainer>
      {isError && <ErrorText>{errorMsg}</ErrorText>}
    </StyledContainer>
  );
}
