import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'shared/redux/types';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useUpdateChargePointMutation } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import {
  useDeleteServicesListLinksByTargetId,
  usePutServicesListLinks,
} from 'shared/api/services/billing/rtk/enhanced';
import { enhancedApi } from 'shared/api/services/chargepoint/rtk/enhanced/metrics';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'ЭЗС обновлена';
const ERROR_MSG = 'Не удалось обновить ЭЗС';

export const useUpdateChargePoint = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [updateChargePoint] = useUpdateChargePointMutation();
  const [setServicesListLinks] = usePutServicesListLinks();
  const [deleteServicesListLinks] = useDeleteServicesListLinksByTargetId();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleUpdate = async ({
    chargePoint,
    data,
    currentServicesListId,
  }: {
    data: FormOutput;
    chargePoint: GetChargePointVm;
    currentServicesListId: string | undefined;
  }) => {
    const { id } = chargePoint;

    const {
      coordinates,
      ocppConfig,
      infrastructureInfo,
      servicesListId,
      ...rest
    } = data;

    const [latitude, longitude] = coordinates;

    try {
      setIsLoading(true);

      const updateBody = {
        ...rest,
        latitude,
        longitude,
        ocppConfig: buildOcppConfigObject(ocppConfig),
        infrastructureInfo: infrastructureInfo.length
          ? JSON.stringify(
              infrastructureInfo.reduce((acc, el) => {
                acc[el] = 'true';

                return acc;
              }, {} as Record<string, string>)
            )
          : null,
      };

      const updateRes = await updateChargePoint({
        id: chargePoint.id,
        updateChargePointRequest: updateBody,
      }).unwrap();

      if (!updateRes.data) {
        throw 'Неизвестная ошибка';
      }

      if (currentServicesListId && !servicesListId) {
        const deleteRes = await deleteServicesListLinks(id);
      } else if (servicesListId && currentServicesListId !== servicesListId) {
        if (currentServicesListId) {
          const deleteRes = await deleteServicesListLinks(id);
        }

        const setServicesListLinksRes = await setServicesListLinks({
          servicesListsLinks: [
            {
              servicesListId: servicesListId,
              targetName: 'CHARGE_POINT',
              targetId: id,
            },
          ],
        });
      }

      const { group } = updateRes.data;

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'ChargePoints', id: 'LIST' }])
      );
      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'ChargePoints', id }])
      );
      if (servicesListId && servicesListId !== currentServicesListId) {
        await dispatch(
          enhancedApi.util.invalidateTags([
            { type: 'ServicesListLinks', id: servicesListId },
          ])
        );
      }

      navigate(
        `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group?.id ?? null}/${id}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
};
