import { z } from 'zod';

import { LATITUDE, LONGITUDE } from 'shared/lib/schema';
import { postApiChargepointV1HubsBody } from 'shared/api/services/chargepoint/orval/zod/schemas';

export const FormSchema = postApiChargepointV1HubsBody
  .omit({
    latitude: true,
    longitude: true,
  })
  .extend({
    latitude: LATITUDE,
    longitude: LONGITUDE,
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  address: '',
  latitude: '',
  longitude: '',
};
