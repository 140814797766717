import { useNavigate } from 'react-router-dom';

import { useAddChargePointGroup } from 'shared/api/services/chargepoint/rtk';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { FormOutput } from '../consts/schema';

const ADD_SUCCESS = 'Группа ЭЗС добавлена';
const ADD_ERROR = 'Не удалось добавить группу ЭЗС';

export function useCreateChargePointGroup() {
  const [trigger, { isLoading }] = useAddChargePointGroup();

  const navigate = useNavigate();

  const handleCreate = async (data: FormOutput) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${CHARGE_POINT_ROUTES.CHARGE_POINTS_GROUPS_LIST}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return {
    handleCreate,
    isLoading,
  };
}
