import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { REPORTS } from 'shared/consts/names';
import { ACQUIRING } from 'shared/consts/routes/report';

import { RequireAuth } from 'entities/authorization';
import { ReportLayout } from 'entities/report';

import { ReportsMenu } from 'features/report/menu';
import { AcquiringReport } from 'features/report/acquiring';

// import {
//   AcquiringReportPage,
//   // PaymentsReportPage,
//   // DebitReportPage,
//   // ConsumeReportPage,
// } from 'pages/report';

export const routes = (
  <Route element={<RequireAuth controllerName="Report" rightType="Read" />}>
    <Route path={REPORTS} element={<SectionLayout menu={<ReportsMenu />} />}>
      <Route element={<ReportLayout />}>
        <Route path={ACQUIRING} element={<AcquiringReport />} />
        {/* <Route path={CONSUME} element={<ConsumeReportPage />} />
        <Route path={PAYMENTS} element={<PaymentsReportPage />} />
        <Route path={DEBIT} element={<DebitReportPage />} /> */}
      </Route>
    </Route>
  </Route>
);
