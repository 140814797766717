import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import {
  FormSchema,
  FormInput,
  FormOutput,
  DEFAULT_VALUES,
} from '../consts/schema';
import { FORM_TITLE } from '../consts';
import { useCreateChargePointGroup } from '../hooks/use-create-cp-group';

type Props = {
  groups: GetGroupVm[];
  mobileApps: MobileAppVm[];
};

export function Form({ groups, mobileApps }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const { handleCreate, isLoading } = useCreateChargePointGroup();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleCreate(data);
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields groups={groups} mobileApps={mobileApps} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  groups: GetGroupVm[];
  mobileApps: MobileAppVm[];
};

Form.Fields = function Fields({ groups, mobileApps }: FieldsProps) {
  const {
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const parentGroup = watch('parentId');
  console.log('parentGroup', parentGroup);

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="parentId"
            options={groups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Родительский узел"
            allowClear
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput> name="name" label="Наименование" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomTextarea<FormInput> name="comment" label="Описание" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="mobileAppIds"
            options={mobileApps.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Мобильные приложения"
            mode="multiple"
            allowClear
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput> name="isDefault" label="isDefault" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
