import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetTariffs } from 'shared/api/services/billing/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { useGetChargePoints } from 'shared/api/services/chargepoint/rtk';

import { Form } from './form';

export function FormContainer() {
  const tariffsQuery = useGetTariffs({});
  const countriesQuery = useGetCountries(undefined);
  const chargePointsQuery = useGetChargePoints({ limit: 10 * 1000 });

  const isLoading =
    tariffsQuery.isFetching ||
    countriesQuery.isFetching ||
    chargePointsQuery.isFetching;

  const apiResponseError = [
    tariffsQuery.data,
    countriesQuery.data,
    chargePointsQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [
    tariffsQuery.error,
    countriesQuery.error,
    chargePointsQuery.error,
  ].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !tariffsQuery?.data?.data ||
    !countriesQuery.data?.data ||
    !chargePointsQuery.data?.data
  ) {
    return <EmptyData />;
  }

  return (
    <Form
      tariffs={tariffsQuery.data.data}
      countries={countriesQuery.data.data}
      chargePoints={chargePointsQuery.data.data}
    />
  );
}
