import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { getErrorMessage } from 'shared/lib/http';
import { useBillingActionClose } from 'shared/api/services/billing/rtk/enhanced';

const SUCCESS_MSG = 'Документ биллинга принудительно закрыт';

type Props = {
  billingId: string;
};

export const Modal = NiceModal.create(({ billingId }: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useBillingActionClose();

  const handleAction = async () => {
    try {
      const res = await trigger({ billingId }).unwrap();

      openSuccessNotification(SUCCESS_MSG);
    } catch (err) {
      const errorText = getErrorMessage(err);

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title="Вы точно уверены, что хотите принудительно закрыть документ билинга?"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={handleAction}
        loading={isLoading}
      />
    </UiModal>
  );
});
