import { Image } from 'antd';

import { useGetChargePointPhotos } from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { getServerErrorText } from 'shared/lib';

import { StyledCard, CardTitle, GridLayout } from './styles';

type Props = {
  chargePointId: string;
};

export function PhotoListCard({ chargePointId }: Props) {
  const { data, isLoading, error, isSuccess } =
    useGetChargePointPhotos(chargePointId);

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <div>Loading....</div>;
  } else if (error) {
    content = <div>{getServerErrorText(error)}</div>;
  } else if (isSuccess) {
    const photos = data.data;

    if (photos?.length) {
      content = (
        <GridLayout>
          <Image.PreviewGroup>
            {photos.map((photo) => (
              <Image
                key={photo.id}
                width={101}
                height={91}
                src={photo.url}
                style={{
                  backgroundSize: '100% auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            ))}
          </Image.PreviewGroup>
        </GridLayout>
      );
    } else {
      content = <div>Нет загруженных фото</div>;
    }
  }

  return (
    <StyledCard>
      <CardTitle>Фото</CardTitle>
      {content}
    </StyledCard>
  );
}
