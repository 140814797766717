import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useAbility } from 'shared/lib/ability/context';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';
import { useUpdateChargePointGroup } from '../hooks/use-update-cp-group';

const getDefaultValues = (group: GetGroupVm): FormInput => {
  const { isDefault, name, comment, mobileAppIds, parentId } = group;

  return {
    name,
    isDefault,
    mobileAppIds,
    parentId,
    comment,
  };
};

type Props = {
  groups: GetGroupVm[];
  group: GetGroupVm;
  mobileApps: MobileAppVm[];
};

export function Form({ groups, group, mobileApps }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(group),
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const { handleUpdate, isLoading } = useUpdateChargePointGroup();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, id: group.id });
  });

  return (
    <UiCard>
      <UiCard.Header>{group.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                isDisabled={isDisabled}
                groups={groups.filter((el) => el.id !== group.id)}
                mobileApps={mobileApps}
              />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  groups: GetGroupVm[];
  mobileApps: MobileAppVm[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ groups, isDisabled, mobileApps }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="parentId"
            options={groups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Родительский узел"
            allowClear
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomTextarea<FormInput>
            name="comment"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="mobileAppIds"
            options={mobileApps.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Мобильные приложения"
            mode="multiple"
            allowClear
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput>
            name="isDefault"
            label="isDefault"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'Group')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
