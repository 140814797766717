import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'shared/redux/types';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import {
  enhancedApi,
  useCreateChargePointMutation,
} from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import {
  enhancedApi as billingApi,
  usePutServicesListLinks,
} from 'shared/api/services/billing/rtk/enhanced';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'ЭЗС добавлена';
const ERROR_MSG = 'Не удалось добавить ЭЗС';

export const useCreateChargePoint = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addChargePoint] = useCreateChargePointMutation();
  const [setServicesListLinks] = usePutServicesListLinks();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleCreate = async (data: FormOutput) => {
    setIsLoading(true);

    const {
      coordinates,
      ocppConfig,
      infrastructureInfo,
      servicesListId,
      ...rest
    } = data;

    const [latitude, longitude] = coordinates;

    try {
      const createBody = {
        ...rest,
        latitude,
        longitude,
        ocppConfig: buildOcppConfigObject(ocppConfig),
        infrastructureInfo: infrastructureInfo.length
          ? JSON.stringify(
              infrastructureInfo.reduce((acc, el) => {
                acc[el] = 'true';

                return acc;
              }, {} as Record<string, string>)
            )
          : null,
      };

      const createRes = await addChargePoint(createBody).unwrap();

      if (!createRes.data) {
        throw 'Неизвестная ошибка';
      }

      const { id, group } = createRes.data;

      if (servicesListId) {
        const setServicesListLinksRes = await setServicesListLinks({
          servicesListsLinks: [
            {
              servicesListId: servicesListId,
              targetName: 'CHARGE_POINT',
              targetId: id,
            },
          ],
        });
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'ChargePoints', id: 'LIST' }])
      );

      if (data.groupId) {
        await dispatch(
          enhancedApi.util.invalidateTags([
            { type: 'ChargePointGroups', id: 'LIST' },
          ])
        );
      }

      if (servicesListId) {
        await dispatch(
          enhancedApi.util.invalidateTags([
            { type: 'ServicesListLinks', id: servicesListId },
          ])
        );
      }

      navigate(
        `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group?.id ?? null}/${id}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreate,
    isLoading,
  };
};
