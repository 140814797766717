import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';

import { BILLING_ACTION_TYPE } from 'entities/billing';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { useManageBalance } from '../hooks/use-manage-balance';

type Props = {
  closeModal: () => void;
  balanceId: string;
  userId: string;
};

export function Form({ closeModal, balanceId, userId }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {},
  });

  const { handleManage, isLoading } = useManageBalance({
    closeModal,
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    handleManage({ data, balanceId, userId });
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons onCancel={closeModal} />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="value" label="Сумма" required />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="type"
            label="Операция"
            options={Object.entries(BILLING_ACTION_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  onCancel: () => void;
};

Form.Buttons = function Buttons({ onCancel }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton text="Применить" />
        <UiCancelFormButton onClick={onCancel} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
