export function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="auto"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 8.00012V18.0001C5 20.2093 6.79086 22.0001 9 22.0001H15C17.2091 22.0001 19 20.2093 19 18.0001V8.00012M14 11.0001V17.0001M10 11.0001L10 17.0001M16 5.00012L14.5937 2.89072C14.2228 2.33432 13.5983 2.00012 12.9296 2.00012H11.0704C10.4017 2.00012 9.7772 2.33432 9.40627 2.89072L8 5.00012M16 5.00012H8M16 5.00012H21M8 5.00012H3"
        stroke="black"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
