import { SectionMenu } from 'shared/ui/section-menu';
import { REPORTS } from 'shared/consts/names';
import { ACQUIRING, REPORT_ROUES } from 'shared/consts/routes/report';

const items = [
  { id: ACQUIRING, name: 'Эквайринг' },
  // { id: 'consume', name: 'Потребление' },
  // { id: 'payments', name: 'Выручка' },
  // { id: 'debit', name: 'Задолженность' },
];

export function ReportsMenu() {
  return (
    <SectionMenu
      loading={false}
      items={items}
      menuKey={REPORTS}
      absolutePath={REPORT_ROUES.LIST}
      label="Отчеты"
    />
  );
}
