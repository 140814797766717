import { BonusProgramFrequency, BonusProgramType } from '../consts';

const getFrequencyTypeText = (type: BonusProgramFrequency) => {
  switch (type) {
    case 'DAY':
      return 'сегодня';

    case 'WEEK':
      return 'на этой неделе';

    case 'MONTH':
      return 'в этом месяце';
  }
};

const getBonusTypeProgramText = (
  type: BonusProgramType,
  value: number,
  currencySymbol: string
) => {
  switch (type) {
    case 'SpendMoney':
      return `Осталось зарядиться на ${value} ${currencySymbol}`;

    case 'ChargedByStations':
      return `Осталось зарядиться на ${value} разных станциях`;

    case 'ChargedByCapacity':
      return `Осталось зарядиться на ${value} кВт`;
  }
};

const getAwardText = (
  value: number,
  isCashback: boolean,
  currencySymbol: string
) => {
  if (isCashback) {
    return `${value}% кэшбэка баллами`;
  }

  return `${value} ${currencySymbol} кэшбэка`;
};

type Props = {
  bonusProgramType: BonusProgramType;
  currencySymbol: string;
  leftToNextLevel: number;
  awardPercent: number;
  awardSum: number;
  frequencyType: BonusProgramFrequency;
};

export const getNextLevelHint = ({
  bonusProgramType,
  currencySymbol,
  leftToNextLevel,
  awardPercent,
  awardSum,
  frequencyType,
}: Props) => {
  if (leftToNextLevel <= 0) {
    return 'Вы получили максимальный кэшбэк';
  }

  const condition = getBonusTypeProgramText(
    bonusProgramType,
    leftToNextLevel,
    currencySymbol
  );

  const award = getAwardText(
    awardPercent || awardSum,
    awardPercent > 0,
    currencySymbol
  );

  const frequency = getFrequencyTypeText(frequencyType);

  return `${condition}, чтобы получить ${award} ${frequency}`;
};
