import { AddPhotosButton } from 'features/charge-point-photo/add-photos';

import { Container } from '../list/container';
import { StyledContainer } from './styles';

type Props = {
  chargePointId: string;
};

export function Layout({ chargePointId }: Props) {
  return (
    <StyledContainer>
      <AddPhotosButton chargePointId={chargePointId} />
      <Container chargePointId={chargePointId} />
    </StyledContainer>
  );
}
