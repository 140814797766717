import { getExtensionsString } from 'shared/lib';

export const IMAGE_EXTENSION_ERROR = 'Недопустимый формат файла';
export const IMAGE_SIZE_ERROR = 'Максимальный размер файла - 1 Мб';

const ACCEPTED_CP_PHOTO_EXTENSIONS = [
  'jpg',
  'jpeg',
  'jpe',
  'bmp',
  'gif',
  'png',
];

export const accepted = getExtensionsString(ACCEPTED_CP_PHOTO_EXTENSIONS);
