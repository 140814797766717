import { enhancedApi } from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { useDeletePhotoMutation } from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { getErrorMessage } from 'shared/lib/http';
import { useAppDispatch } from 'shared/redux/types';

const SUCCESS_MSG = 'Фотография удалена';

export const useDeletePhoto = () => {
  const [trigger, { isLoading }] = useDeletePhotoMutation();

  const dispatch = useAppDispatch();

  const handleDelete = async ({
    chargePointId,
    id,
    closeModal,
  }: {
    id: string;
    chargePointId: string;
    closeModal: () => void;
  }) => {
    try {
      const res = await trigger(id).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([
          { type: 'ChargePointPhotos', id: chargePointId },
        ])
      );
    } catch (err) {
      const errorText = getErrorMessage(err);

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return { handleDelete, isLoading };
};
