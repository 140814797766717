import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1GroupsById: build.query<
      GetApiChargepointV1GroupsByIdApiResponse,
      GetApiChargepointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/chargepoint/V1/Groups/${queryArg}` }),
    }),
    patchApiChargepointV1GroupsById: build.mutation<
      PatchApiChargepointV1GroupsByIdApiResponse,
      PatchApiChargepointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Groups/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequest,
      }),
    }),
    deleteApiChargepointV1GroupsById: build.mutation<
      DeleteApiChargepointV1GroupsByIdApiResponse,
      DeleteApiChargepointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1Groups: build.query<
      GetApiChargepointV1GroupsApiResponse,
      GetApiChargepointV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Groups`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiChargepointV1Groups: build.mutation<
      PostApiChargepointV1GroupsApiResponse,
      PostApiChargepointV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1GroupsCount: build.query<
      GetApiChargepointV1GroupsCountApiResponse,
      GetApiChargepointV1GroupsCountApiArg
    >({
      query: () => ({ url: `/api/chargepoint/V1/Groups/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1GroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type GetApiChargepointV1GroupsByIdApiArg = string;
export type PatchApiChargepointV1GroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PatchApiChargepointV1GroupsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateGroupRequest: UpdateGroupRequest;
};
export type DeleteApiChargepointV1GroupsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1GroupsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargepointV1GroupsApiResponse =
  /** status 200 Success */ GetGroupVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1GroupsApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiChargepointV1GroupsApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PostApiChargepointV1GroupsApiArg = AddGroupRequest;
export type GetApiChargepointV1GroupsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargepointV1GroupsCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetGroupVm = {
  id: string;
  name: string;
  comment?: string | null;
  children?: any[] | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
  createdDate: string;
  lastUpdate: string;
};
export type GetGroupVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm;
};
export type UpdateGroupRequest = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetGroupVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type AddGroupRequest = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargepointV1GroupsByIdQuery,
  usePatchApiChargepointV1GroupsByIdMutation,
  useDeleteApiChargepointV1GroupsByIdMutation,
  useGetApiChargepointV1GroupsQuery,
  usePostApiChargepointV1GroupsMutation,
  useGetApiChargepointV1GroupsCountQuery,
} = injectedRtkApi;
