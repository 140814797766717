/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ChargePointService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1ChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1ChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number().nullish(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      supportContact: zod.string().nullish(),
      companyId: zod.string().uuid().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      infrastructureInfo: zod.string().nullish(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Обновить запись
 */
export const patchApiChargepointV1ChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1ChargePointsIdBody = zod.object({
  number: zod.number().nullish(),
  speedType: zod
    .enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC'])
    .optional(),
  multiconnectorSupport: zod.boolean().nullish(),
  name: zod.string().nullish(),
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']).optional(),
  status: zod
    .enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE'])
    .optional(),
  isVisible: zod.boolean().nullish(),
  maxPower: zod.number().optional(),
  commentary: zod.string().nullish(),
  publicDescription: zod.string().nullish(),
  userLocationCheckDistance: zod.number().nullish(),
  warningText: zod.string().nullish(),
  workingTime: zod.string().nullish(),
  countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  city: zod.string().nullish(),
  address: zod.string().nullish(),
  latitude: zod.number().nullish(),
  longitude: zod.number().nullish(),
  model: zod.string().nullish(),
  manufacturer: zod.string().nullish(),
  softwareRevision: zod.string().nullish(),
  ocppConfig: zod.string().nullish(),
  ipAddress: zod.string().nullish(),
  simNumber: zod.string().nullish(),
  dipLinkCharby: zod.string().nullish(),
  chargeBoxIdentity: zod.string().nullish(),
  supportContact: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  companyId: zod.string().uuid().nullish(),
  hubId: zod.string().uuid().nullish(),
  isTestCp: zod.boolean().nullish(),
  infrastructureInfo: zod.string().nullish(),
});

export const patchApiChargepointV1ChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      companyId: zod.string().uuid().nullish(),
      supportContact: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      infrastructureInfo: zod.string().nullish(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1ChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1ChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1ChargePointsQueryParams = zod.object({
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  Name: zod.string().optional(),
  ConnectorId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  NotСompaniesId: zod.array(zod.string().uuid()).optional(),
  ConnectorStatus: zod
    .array(
      zod.enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
    )
    .optional(),
  Ids: zod.array(zod.string().uuid()).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Statuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1ChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        number: zod.number().nullish(),
        name: zod.string().min(1),
        speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
        multiconnectorSupport: zod.boolean(),
        chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
        status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
        isVisible: zod.boolean(),
        maxPower: zod.number(),
        commentary: zod.string().nullish(),
        publicDescription: zod.string().nullish(),
        userLocationCheckDistance: zod.number().nullish(),
        warningText: zod.string().nullish(),
        workingTime: zod.string().nullish(),
        countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        city: zod.string().min(1),
        address: zod.string().min(1),
        latitude: zod.number(),
        longitude: zod.number(),
        model: zod.string().nullish(),
        dipLinkCharby: zod.string().nullish(),
        manufacturer: zod.string().nullish(),
        softwareRevision: zod.string().nullish(),
        ocppConfig: zod.string().nullish(),
        ipAddress: zod.string().nullish(),
        simNumber: zod.string().nullish(),
        chargeBoxIdentity: zod.string().min(1),
        supportContact: zod.string().nullish(),
        companyId: zod.string().uuid().nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        isTestCp: zod.boolean(),
        isBlocked: zod.boolean(),
        ocppConnectionBlockDate: zod.string().datetime(),
        infrastructureInfo: zod.string().nullish(),
        group: zod
          .object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
          })
          .optional(),
        hub: zod
          .object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            description: zod.string().nullish(),
            address: zod.string().min(1),
            latitude: zod.number(),
            longitude: zod.number(),
          })
          .optional(),
        connectors: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              type: zod.enum([
                'SCHUKO',
                'TYPE2',
                'CCS2',
                'CHADEMO',
                'GBTDC',
                'GBTAC',
                'CCS1',
                'TYPE1',
                'TESLA',
              ]),
              format: zod.enum(['SOCKET', 'CABLE']),
              status: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'PREPARING',
                'CHARGING',
                'SUSPENDED_EVSE',
                'SUSPENDED_EV',
                'FINISHING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              statusForUser: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'CHARGING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              innerConnectorId: zod.number(),
              maxPower: zod.number(),
              maxAmperage: zod.number(),
              maxVoltage: zod.number().nullish(),
              manualBlock: zod.boolean(),
              lastUpdate: zod.string().datetime(),
              liter: zod.string().nullish(),
            })
          )
          .nullish(),
        photos: zod
          .array(
            zod.object({
              url: zod.string().nullish(),
              priority: zod.number(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1ChargePointsBody = zod.object({
  number: zod.number().nullish(),
  speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
  multiconnectorSupport: zod.boolean(),
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  name: zod.string().min(1),
  status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
  isVisible: zod.boolean(),
  maxPower: zod.number(),
  commentary: zod.string().nullish(),
  publicDescription: zod.string().nullish(),
  userLocationCheckDistance: zod.number().nullish(),
  warningText: zod.string().nullish(),
  workingTime: zod.string().nullish(),
  countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  city: zod.string().min(1),
  address: zod.string().min(1),
  latitude: zod.number(),
  longitude: zod.number(),
  model: zod.string().nullish(),
  manufacturer: zod.string().nullish(),
  softwareRevision: zod.string().nullish(),
  ocppConfig: zod.string().nullish(),
  ipAddress: zod.string().nullish(),
  simNumber: zod.string().nullish(),
  chargeBoxIdentity: zod.string().min(1),
  supportContact: zod.string().nullish(),
  dipLinkCharby: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  companyId: zod.string().uuid().nullish(),
  hubId: zod.string().uuid().nullish(),
  isTestCp: zod.boolean(),
  infrastructureInfo: zod.string().nullish(),
});

export const postApiChargepointV1ChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number().nullish(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      supportContact: zod.string().nullish(),
      companyId: zod.string().uuid().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      infrastructureInfo: zod.string().nullish(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Получить количество
 */
export const getApiChargepointV1ChargePointsCountQueryParams = zod.object({
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  Name: zod.string().optional(),
  ConnectorId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  NotСompaniesId: zod.array(zod.string().uuid()).optional(),
  ConnectorStatus: zod
    .array(
      zod.enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
    )
    .optional(),
  Ids: zod.array(zod.string().uuid()).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Statuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1ChargePointsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить представление - все статусы, расположения ЭЗС и их коннекторов
 */
export const getApiChargepointV1ChargePointsCpStatusesQueryParams = zod.object({
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  WithVisibleCp: zod.boolean().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargepointV1ChargePointsCpStatusesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
        multiconnectorSupport: zod.boolean(),
        name: zod.string().min(1),
        maxPower: zod.number(),
        city: zod.string().min(1),
        address: zod.string().min(1),
        number: zod.number().nullish(),
        warningText: zod.string().nullish(),
        dipLinkCharby: zod.string().nullish(),
        speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
        groupId: zod.string().uuid().nullish(),
        hubId: zod.string().uuid().nullish(),
        latitude: zod.number(),
        longitude: zod.number(),
        lastUpdate: zod.string().datetime(),
        connectors: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              status: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'PREPARING',
                'CHARGING',
                'SUSPENDED_EVSE',
                'SUSPENDED_EV',
                'FINISHING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              type: zod.enum([
                'SCHUKO',
                'TYPE2',
                'CCS2',
                'CHADEMO',
                'GBTDC',
                'GBTAC',
                'CCS1',
                'TYPE1',
                'TESLA',
              ]),
              innerConnectorId: zod.number(),
              statusForUser: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'CHARGING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              maxPower: zod.number(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить информацию о зарядных станций
 */
export const getApiChargepointV1ChargePointsInfoQueryParams = zod.object({
  chargeBoxIdentity: zod.string().optional(),
});

export const getApiChargepointV1ChargePointsInfoResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      chargePointId: zod.string().uuid(),
      isBlocked: zod.boolean(),
    })
    .optional(),
});

export const getApiChargepointV1ChargePointsInfoByConnectorsQueryParams =
  zod.object({
    connectorIds: zod.array(zod.string().uuid()).optional(),
  });

export const getApiChargepointV1ChargePointsInfoByConnectorsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          chargePointId: zod.string().uuid(),
          chargePointName: zod.string().min(1),
          companyId: zod.string().uuid().nullish(),
          connectorId: zod.string().uuid(),
          connectorType: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
          address: zod.string().min(1),
        })
      )
      .nullish(),
  });

export const getApiChargepointV1ChargePointsChargePointsIdsByReadRightsQueryParams =
  zod.object({
    UserName: zod.string().optional(),
    UserRole: zod.string().optional(),
  });

export const getApiChargepointV1ChargePointsChargePointsIdsByReadRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        chargePointIds: zod.array(zod.string().uuid()),
        isAllCpRight: zod.boolean(),
      })
      .optional(),
  });

/**
 * @summary Поулчить последнюю активность станции
 */
export const getApiChargepointV1ChargePointsLastActivityIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1ChargePointsLastActivityIdResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        date: zod.string().datetime().optional(),
        eventName: zod.string().nullish(),
      })
      .optional(),
  }
);

/**
 * @summary Выполняет старт зарядной станции
 */
export const postApiChargepointV1CommandsSTARTBody = zod.object({
  userId: zod.string().uuid(),
  chargePointId: zod.string().uuid(),
  connectorId: zod.string().uuid(),
});

export const postApiChargepointV1CommandsSTARTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      sessionId: zod.string().uuid().optional(),
    })
    .optional(),
});

/**
 * @summary Выполняет стоп зарядной станции
 */
export const postApiChargepointV1CommandsSTOPBody = zod.object({
  chargePointId: zod.string().uuid(),
  connectorId: zod.string().uuid(),
});

export const postApiChargepointV1CommandsSTOPResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsSETMAXPOWERBody = zod.object({
  chargePointId: zod.string().uuid(),
  maxPower: zod.number(),
});

export const postApiChargepointV1CommandsSETMAXPOWERResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsRESTARTBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  type: zod.enum(['HARD', 'SOFT']).optional(),
});

export const postApiChargepointV1CommandsRESTARTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsLOADFIRMWAREBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  downloadUrl: zod.string().nullish(),
  retrieveDate: zod.string().datetime().optional(),
});

export const postApiChargepointV1CommandsLOADFIRMWAREResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsCONFIGUREBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  key: zod.string().nullish(),
  newValue: zod.string().nullish(),
});

export const postApiChargepointV1CommandsCONFIGUREResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsDIAGNOSTICBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  uploadUrl: zod.string().nullish(),
});

export const postApiChargepointV1CommandsDIAGNOSTICResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1CommandsCHANGEAVAILABILITYBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  innerConnectorId: zod.number().optional(),
  type: zod.enum(['INOPERATIVE', 'OPERATIVE']).optional(),
});

export const postApiChargepointV1CommandsCHANGEAVAILABILITYResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1ChargePointsChargePointsIdConnectorIdParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const getApiChargepointV1ChargePointsChargePointsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Обновить коннектор
 */
export const patchApiChargepointV1ChargePointsChargePointsIdConnectorIdParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const patchApiChargepointV1ChargePointsChargePointsIdConnectorIdBody =
  zod.object({
    type: zod
      .enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ])
      .optional(),
    format: zod.enum(['SOCKET', 'CABLE']).optional(),
    status: zod
      .enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
      .optional(),
    innerConnectorId: zod.number().nullish(),
    maxPower: zod.number().nullish(),
    maxAmperage: zod.number().nullish(),
    manualBlock: zod.boolean().nullish(),
    maxVoltage: zod.number().nullish(),
    liter: zod.string().nullish(),
  });

export const patchApiChargepointV1ChargePointsChargePointsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Удалить коннектор
 */
export const deleteApiChargepointV1ChargePointsChargePointsIdConnectorIdParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const deleteApiChargepointV1ChargePointsChargePointsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Добавить коннектор
 */
export const postApiChargepointV1ChargePointsChargePointsIdConnectorParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
  });

export const postApiChargepointV1ChargePointsChargePointsIdConnectorBody =
  zod.object({
    type: zod.enum([
      'SCHUKO',
      'TYPE2',
      'CCS2',
      'CHADEMO',
      'GBTDC',
      'GBTAC',
      'CCS1',
      'TYPE1',
      'TESLA',
    ]),
    format: zod.enum(['SOCKET', 'CABLE']),
    status: zod.enum([
      'UNDEFINED',
      'AVAILABLE',
      'PREPARING',
      'CHARGING',
      'SUSPENDED_EVSE',
      'SUSPENDED_EV',
      'FINISHING',
      'RESERVED',
      'UNAVAILABLE',
      'FAULTED',
    ]),
    innerConnectorId: zod.number(),
    maxPower: zod.number(),
    manualBlock: zod.boolean().nullish(),
    maxAmperage: zod.number().nullish(),
    maxVoltage: zod.number().nullish(),
    liter: zod.string().nullish(),
  });

export const postApiChargepointV1ChargePointsChargePointsIdConnectorResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Получить все имеющиеся типы конекторов
 */
export const getApiChargepointV1ConnectorsTypesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.array(zod.string()).nullish(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1EventsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1EventsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      name: zod.enum([
        'BOOT_NOTIFICATION',
        'REMOTE_START_TRANSACTION',
        'START_TRANSACTION',
        'REMOTE_STOP_TRANSACTION',
        'STOP_TRANSACTION',
        'STATUS_NOTIFICATION',
        'METER_VALUES',
        'RESET',
        'CHANGE_CONFIGURATION',
        'UNLOCK_CONNECTOR',
        'CHANGE_AVAILABILITY',
        'DATA_TRANSFER',
        'TRANSACTION_EVENT',
        'NOTIFY_EVENT',
      ]),
      type: zod.enum(['REQUEST', 'CONFIRMATION']),
      chargePointId: zod.string().uuid(),
      createdDate: zod.string().datetime(),
      connectorId: zod.string().uuid().nullish(),
      connectorStatus: zod
        .enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ])
        .optional(),
      remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']).optional(),
      transactionId: zod.string().nullish(),
      idempotencyKey: zod.string().uuid(),
      sessionId: zod.string().uuid().nullish(),
      instantPower: zod.number().nullish(),
      instantCurrent: zod.number().nullish(),
      reason: zod.string().nullish(),
      chargingPercentage: zod.number().nullish(),
      countTotalEnergy: zod.number().nullish(),
      meterStop: zod.number().nullish(),
      meterStart: zod.number().nullish(),
      data: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1EventsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1EventsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1EventsQueryParams = zod.object({
  Name: zod
    .enum([
      'BOOT_NOTIFICATION',
      'REMOTE_START_TRANSACTION',
      'START_TRANSACTION',
      'REMOTE_STOP_TRANSACTION',
      'STOP_TRANSACTION',
      'STATUS_NOTIFICATION',
      'METER_VALUES',
      'RESET',
      'CHANGE_CONFIGURATION',
      'UNLOCK_CONNECTOR',
      'CHANGE_AVAILABILITY',
      'DATA_TRANSFER',
      'TRANSACTION_EVENT',
      'NOTIFY_EVENT',
    ])
    .optional(),
  Type: zod.enum(['REQUEST', 'CONFIRMATION']).optional(),
  ChargePointId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  TransactionId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1EventsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
        name: zod.enum([
          'BOOT_NOTIFICATION',
          'REMOTE_START_TRANSACTION',
          'START_TRANSACTION',
          'REMOTE_STOP_TRANSACTION',
          'STOP_TRANSACTION',
          'STATUS_NOTIFICATION',
          'METER_VALUES',
          'RESET',
          'CHANGE_CONFIGURATION',
          'UNLOCK_CONNECTOR',
          'CHANGE_AVAILABILITY',
          'DATA_TRANSFER',
          'TRANSACTION_EVENT',
          'NOTIFY_EVENT',
        ]),
        type: zod.enum(['REQUEST', 'CONFIRMATION']),
        chargePointId: zod.string().uuid(),
        createdDate: zod.string().datetime(),
        connectorId: zod.string().uuid().nullish(),
        connectorStatus: zod
          .enum([
            'UNDEFINED',
            'AVAILABLE',
            'PREPARING',
            'CHARGING',
            'SUSPENDED_EVSE',
            'SUSPENDED_EV',
            'FINISHING',
            'RESERVED',
            'UNAVAILABLE',
            'FAULTED',
          ])
          .optional(),
        remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']).optional(),
        transactionId: zod.string().nullish(),
        idempotencyKey: zod.string().uuid(),
        sessionId: zod.string().uuid().nullish(),
        instantPower: zod.number().nullish(),
        instantCurrent: zod.number().nullish(),
        reason: zod.string().nullish(),
        chargingPercentage: zod.number().nullish(),
        countTotalEnergy: zod.number().nullish(),
        meterStop: zod.number().nullish(),
        meterStart: zod.number().nullish(),
        data: zod.string().nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить кол-во сущностей
 */
export const getApiChargepointV1EventsCountQueryParams = zod.object({
  Name: zod
    .enum([
      'BOOT_NOTIFICATION',
      'REMOTE_START_TRANSACTION',
      'START_TRANSACTION',
      'REMOTE_STOP_TRANSACTION',
      'STOP_TRANSACTION',
      'STATUS_NOTIFICATION',
      'METER_VALUES',
      'RESET',
      'CHANGE_CONFIGURATION',
      'UNLOCK_CONNECTOR',
      'CHANGE_AVAILABILITY',
      'DATA_TRANSFER',
      'TRANSACTION_EVENT',
      'NOTIFY_EVENT',
    ])
    .optional(),
  Type: zod.enum(['REQUEST', 'CONFIRMATION']).optional(),
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  TransactionId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiChargepointV1EventsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

export const postApiChargepointV1EventsDisconnectEventBody = zod.object({
  reason: zod.enum([
    'UNSPECIFIED',
    'CONNECTION_CLOSED_BY_CONTROLLER',
    'CONNECTION_CLOSED_BY_STATION',
    'MAX_MESSAGE_FREQUENCY_EXCEEDED',
  ]),
  chargePointId: zod.string().uuid(),
});

export const postApiChargepointV1EventsDisconnectEventResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1EventsBOOTNOTIFICATIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsBOOTNOTIFICATIONREQUESTBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  }
);

export const postApiChargepointV1EventsBOOTNOTIFICATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        date: zod.string().datetime(),
        isBlocked: zod.boolean(),
      })
      .optional(),
  });

export const postApiChargepointV1EventsBOOTNOTIFICATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsBOOTNOTIFICATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsBOOTNOTIFICATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
    remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']),
  });

export const postApiChargepointV1EventsREMOTESTARTTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTARTTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTARTTRANSACTIONREQUESTBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    innerConnectorId: zod.number(),
    meterStart: zod.number(),
    data: zod.string().min(1),
  }
);

export const postApiChargepointV1EventsSTARTTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTARTTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTARTTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargepointV1EventsSTARTTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']),
  });

export const postApiChargepointV1EventsREMOTESTOPTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTOPTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTOPTRANSACTIONREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
  meterStop: zod.number(),
  reason: zod.string().min(1),
  transactionId: zod.string().min(1),
  instantPower: zod.number().nullish(),
  chargingPercentage: zod.number().nullish(),
  countTotalEnergy: zod.number().nullish(),
});

export const postApiChargepointV1EventsSTOPTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTOPTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTOPTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsSTOPTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    status: zod.enum([
      'UNDEFINED',
      'AVAILABLE',
      'PREPARING',
      'CHARGING',
      'SUSPENDED_EVSE',
      'SUSPENDED_EV',
      'FINISHING',
      'RESERVED',
      'UNAVAILABLE',
      'FAULTED',
    ]),
    data: zod.string().min(1),
    innerConnectorId: zod.number(),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsSTATUSNOTIFICATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsMETERVALUESREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargepointV1EventsMETERVALUESREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
  innerConnectorId: zod.number(),
  transactionId: zod.string().nullish(),
  instantPower: zod.number(),
  instantCurrent: zod.number(),
  chargingPercentage: zod.number(),
  countTotalEnergy: zod.number(),
});

export const postApiChargepointV1EventsMETERVALUESREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1EventsMETERVALUESCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsMETERVALUESCONFIRMATIONBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  }
);

export const postApiChargepointV1EventsMETERVALUESCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsCHANGECONFIGURATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsUNLOCKCONNECTORREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsUNLOCKCONNECTORREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargepointV1EventsUNLOCKCONNECTORREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsUNLOCKCONNECTORCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsUNLOCKCONNECTORCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsUNLOCKCONNECTORCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsCHANGEAVAILABILITYCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsDATATRANSFERREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargepointV1EventsDATATRANSFERREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargepointV1EventsDATATRANSFERREQUESTResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  }
);

export const postApiChargepointV1EventsDATATRANSFERCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsDATATRANSFERCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsDATATRANSFERCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsRESETREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargepointV1EventsRESETREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargepointV1EventsRESETREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1EventsRESETCONFIRMATIONHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargepointV1EventsRESETCONFIRMATIONBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargepointV1EventsRESETCONFIRMATIONResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1EventsTRANSACTIONEVENTREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsTRANSACTIONEVENTREQUESTBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  }
);

export const postApiChargepointV1EventsTRANSACTIONEVENTREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsTRANSACTIONEVENTCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsTRANSACTIONEVENTCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargepointV1EventsTRANSACTIONEVENTCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargepointV1EventsNOTIFYEVENTREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargepointV1EventsNOTIFYEVENTREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargepointV1EventsNOTIFYEVENTREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargepointV1EventsNOTIFYEVENTCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargepointV1EventsNOTIFYEVENTCONFIRMATIONBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  }
);

export const postApiChargepointV1EventsNOTIFYEVENTCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить прошивку
 */
export const getApiChargepointV1FirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1FirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность прошивки
 */
export const patchApiChargepointV1FirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1FirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Удалить файл
 */
export const deleteApiChargepointV1FirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1FirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить все прошивки
 */
export const getApiChargepointV1FirmwaresResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        url: zod.string().min(1),
        objectStorageName: zod.string().min(1),
        formatUrl: zod.enum(['SOAP', 'JSON']),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить прошивку
 */
export const postApiChargepointV1FirmwaresResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить количество прошивок
 */
export const getApiChargepointV1FirmwaresCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить конкретную сущность
 */
export const getApiChargepointV1GroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1GroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1GroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1GroupsIdBody = zod.object({
  name: zod.string().nullish(),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean().nullish(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiChargepointV1GroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1GroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1GroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1GroupsQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1GroupsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        comment: zod.string().nullish(),
        children: zod.array(zod.any()).nullish(),
        parentId: zod.string().uuid().nullish(),
        isDefault: zod.boolean(),
        mobileAppIds: zod.array(zod.string().uuid()).nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить группу
 */
export const postApiChargepointV1GroupsBody = zod.object({
  name: zod.string().min(1),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const postApiChargepointV1GroupsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить количество сущностей
 */
export const getApiChargepointV1GroupsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1HubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1HubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1HubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1HubsIdBody = zod.object({
  name: zod.string().nullish(),
  description: zod.string().nullish(),
  address: zod.string().nullish(),
  latitude: zod.number().nullish(),
  longitude: zod.number().nullish(),
});

export const patchApiChargepointV1HubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Удалить сущности
 */
export const deleteApiChargepointV1HubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1HubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1HubsQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiChargepointV1HubsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        address: zod.string().min(1),
        latitude: zod.number(),
        longitude: zod.number(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1HubsBody = zod.object({
  name: zod.string().min(1),
  description: zod.string().nullish(),
  address: zod.string().min(1),
  latitude: zod.number(),
  longitude: zod.number(),
});

export const postApiChargepointV1HubsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Количество сущностей
 */
export const getApiChargepointV1HubsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

export const getApiChargepointV1MetricsCHARGEPOINTSQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
  chargePointStatuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  notСompanyIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiChargepointV1MetricsCHARGEPOINTSResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
      of: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargepointV1MetricsCONNECTORSQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  notСompanyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
  chargePointStatuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  connectorStatuses: zod
    .array(
      zod.enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
    )
    .optional(),
});

export const getApiChargepointV1MetricsCONNECTORSResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
      of: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargepointV1MetricsSESSIONCHARGINGQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  notСompanyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
});

export const getApiChargepointV1MetricsSESSIONCHARGINGResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargepointV1MetricsGraphSUCCESSFULSESSIONSQueryParams =
  zod.object({
    companyIds: zod.array(zod.string().uuid()).optional(),
    notСompanyIds: zod.array(zod.string().uuid()).optional(),
    isTest: zod.boolean().optional(),
    dateFrom: zod.string().datetime().optional(),
    dateTo: zod.string().datetime().optional(),
    groupByPeriod: zod.enum(['DAY', 'MONTH']).optional(),
    chargePointIds: zod.array(zod.string().uuid()).optional(),
  });

export const getApiChargepointV1MetricsGraphSUCCESSFULSESSIONSResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          date: zod.string().datetime().nullish(),
          value: zod.number().optional(),
        })
      )
      .nullish(),
  });

export const getApiChargepointV1MetricsGraphAMOUNTOFCONSUMEDENERGYQueryParams =
  zod.object({
    companyIds: zod.array(zod.string().uuid()).optional(),
    notСompanyIds: zod.array(zod.string().uuid()).optional(),
    isTest: zod.boolean().optional(),
    dateFrom: zod.string().datetime().optional(),
    dateTo: zod.string().datetime().optional(),
    groupByPeriod: zod.enum(['DAY', 'MONTH']).optional(),
    chargePointIds: zod.array(zod.string().uuid()).optional(),
  });

export const getApiChargepointV1MetricsGraphAMOUNTOFCONSUMEDENERGYResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          date: zod.string().datetime().nullish(),
          value: zod.number().optional(),
        })
      )
      .nullish(),
  });

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1PhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1PhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      priority: zod.number(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1PhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1PhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      priority: zod.number(),
    })
    .optional(),
});

/**
 * @summary Удалить файл
 */
export const deleteApiChargepointV1PhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1PhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargepointV1PhotosQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
});

export const getApiChargepointV1PhotosResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        url: zod.string().min(1),
        objectStorageName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        priority: zod.number(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить фото
 */
export const postApiChargepointV1PhotosResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        url: zod.string().min(1),
        objectStorageName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        priority: zod.number(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить количество сущностей
 */
export const getApiChargepointV1PhotosCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1RoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1RoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1RoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1RoleCpRightsIdBody = zod.object({
  roleName: zod.string().nullish(),
  chargePointId: zod.string().uuid().nullish(),
  read: zod.boolean().nullish(),
  write: zod.boolean().nullish(),
  execute: zod.boolean().nullish(),
});

export const patchApiChargepointV1RoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1RoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1RoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargepointV1RoleCpRightsQueryParams = zod.object({
  roleName: zod.string().optional(),
});

export const getApiChargepointV1RoleCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        roleName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1RoleCpRightsBody = zod.object({
  roleName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargepointV1RoleCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargepointV1RoleCpRightsSetRoleCpRightsBody = zod.object({
  roleName: zod.string().min(1),
  allCpRight: zod.boolean().nullish(),
  cpRights: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        read: zod.boolean().nullish(),
        write: zod.boolean().nullish(),
        execute: zod.boolean().nullish(),
      })
    )
    .nullish(),
});

export const postApiChargepointV1RoleCpRightsSetRoleCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1RoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1RoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1RoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1RoleGroupCpRightsIdBody = zod.object({
  roleName: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  read: zod.boolean().nullish(),
  write: zod.boolean().nullish(),
  execute: zod.boolean().nullish(),
});

export const patchApiChargepointV1RoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1RoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1RoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1RoleGroupCpRightsQueryParams = zod.object({
  roleName: zod.string().optional(),
});

export const getApiChargepointV1RoleGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        roleName: zod.string().min(1),
        groupId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1RoleGroupCpRightsBody = zod.object({
  roleName: zod.string().min(1),
  groupId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargepointV1RoleGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsBody =
  zod.object({
    roleName: zod.string().min(1),
    allCpRight: zod.boolean().nullish(),
    groupCpRights: zod
      .array(
        zod.object({
          groupId: zod.string().uuid(),
          read: zod.boolean().nullish(),
          write: zod.boolean().nullish(),
          execute: zod.boolean().nullish(),
        })
      )
      .nullish(),
  });

export const postApiChargepointV1RoleGroupCpRightsSetRoleGroupCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить сессию
 */
export const getApiChargepointV1SessionsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1SessionsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      chargePoint: zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        groupId: zod.string().uuid().nullish(),
        companyId: zod.string().uuid().nullish(),
      }),
      connector: zod.object({
        id: zod.string().uuid(),
        liter: zod.string().min(1),
        innerConnectorId: zod.number(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
      }),
      userId: zod.string().uuid(),
      status: zod.enum([
        'NEW',
        'PREPARING',
        'CHARGING',
        'COMPLETED_WITH_SUCCESS',
        'COMPLETED_WITH_ERROR',
      ]),
      meterStart: zod.number(),
      meterStop: zod.number().nullish(),
      energy: zod.number(),
      instantPower: zod.number(),
      instantCurrent: zod.number(),
      startChargingPercentage: zod.number().nullish(),
      chargingPercentage: zod.number().nullish(),
      maxPower: zod.number(),
      maxCurrent: zod.number(),
      stopReason: zod.string().nullish(),
      transactionId: zod.string().min(1),
      errorText: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить список сессий
 */
export const getApiChargepointV1SessionsQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'PREPARING',
      'CHARGING',
      'COMPLETED_WITH_SUCCESS',
      'COMPLETED_WITH_ERROR',
    ])
    .optional(),
  TransactionId: zod.string().optional(),
  CompanyId: zod.string().uuid().optional(),
  DateCreateFrom: zod.string().datetime().optional(),
  DateCreateTo: zod.string().datetime().optional(),
  EndDateFrom: zod.string().datetime().optional(),
  EndDateTo: zod.string().datetime().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  ChargePointGroupId: zod.string().uuid().optional(),
  SearchField: zod.string().optional(),
  SearchText: zod.string().optional(),
  MeterStart: zod.number().optional(),
  MeterStop: zod.number().optional(),
  Energy: zod.number().optional(),
  InstantPower: zod.number().optional(),
  InstantCurrent: zod.number().optional(),
  StartChargingPercentage: zod.number().optional(),
  ChargingPercentage: zod.number().optional(),
  MaxPower: zod.number().optional(),
  MaxCurrent: zod.number().optional(),
  StopReason: zod.string().optional(),
  CreatedDate: zod.string().datetime().optional(),
  ClosedDate: zod.string().datetime().optional(),
  LastUpdate: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1SessionsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        chargePoint: zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          groupId: zod.string().uuid().nullish(),
          companyId: zod.string().uuid().nullish(),
        }),
        connector: zod.object({
          id: zod.string().uuid(),
          liter: zod.string().min(1),
          innerConnectorId: zod.number(),
          type: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
        }),
        userId: zod.string().uuid(),
        status: zod.enum([
          'NEW',
          'PREPARING',
          'CHARGING',
          'COMPLETED_WITH_SUCCESS',
          'COMPLETED_WITH_ERROR',
        ]),
        meterStart: zod.number(),
        meterStop: zod.number().nullish(),
        energy: zod.number(),
        instantPower: zod.number(),
        instantCurrent: zod.number(),
        startChargingPercentage: zod.number().nullish(),
        chargingPercentage: zod.number().nullish(),
        maxPower: zod.number(),
        maxCurrent: zod.number(),
        stopReason: zod.string().nullish(),
        transactionId: zod.string().min(1),
        errorText: zod.string().nullish(),
        createdDate: zod.string().datetime(),
        closedDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить кол-во сессий
 */
export const getApiChargepointV1SessionsCountQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'PREPARING',
      'CHARGING',
      'COMPLETED_WITH_SUCCESS',
      'COMPLETED_WITH_ERROR',
    ])
    .optional(),
  TransactionId: zod.string().optional(),
  CompanyId: zod.string().uuid().optional(),
  DateCreateFrom: zod.string().datetime().optional(),
  DateCreateTo: zod.string().datetime().optional(),
  EndDateFrom: zod.string().datetime().optional(),
  EndDateTo: zod.string().datetime().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  ChargePointGroupId: zod.string().uuid().optional(),
  SearchField: zod.string().optional(),
  SearchText: zod.string().optional(),
  MeterStart: zod.number().optional(),
  MeterStop: zod.number().optional(),
  Energy: zod.number().optional(),
  InstantPower: zod.number().optional(),
  InstantCurrent: zod.number().optional(),
  StartChargingPercentage: zod.number().optional(),
  ChargingPercentage: zod.number().optional(),
  MaxPower: zod.number().optional(),
  MaxCurrent: zod.number().optional(),
  StopReason: zod.string().optional(),
  CreatedDate: zod.string().datetime().optional(),
  ClosedDate: zod.string().datetime().optional(),
  LastUpdate: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiChargepointV1SessionsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить список точек
 */
export const getApiChargepointV1SessionsIdGraphMeterValuesParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1SessionsIdGraphMeterValuesQueryParams =
  zod.object({
    DateFrom: zod.string().datetime().optional(),
  });

export const getApiChargepointV1SessionsIdGraphMeterValuesResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          date: zod.string().datetime(),
          power: zod.number(),
          chargingPercentage: zod.number(),
          energy: zod.number(),
        })
      )
      .nullish(),
  }
);

/**
 * @summary Принудительно прерывает(завершает) сессию (только запись в БД, саму зарядку не останавливает)
 */
export const postApiChargepointV1SessionsTerminateIdParams = zod.object({
  id: zod.string().uuid(),
});

export const postApiChargepointV1SessionsTerminateIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargepointV1UserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1UserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1UserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1UserCpRightsIdBody = zod.object({
  userName: zod.string().nullish(),
  chargePointId: zod.string().uuid().optional(),
  read: zod.boolean().nullish(),
  write: zod.boolean().nullish(),
  execute: zod.boolean().nullish(),
});

export const patchApiChargepointV1UserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1UserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1UserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1UserCpRightsQueryParams = zod.object({
  userName: zod.string().optional(),
});

export const getApiChargepointV1UserCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1UserCpRightsBody = zod.object({
  userName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargepointV1UserCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargepointV1UserCpRightsSetUserCpRightsBody = zod.object({
  userName: zod.string().min(1),
  allCpRight: zod.boolean().nullish(),
  cpRights: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        read: zod.boolean().nullish(),
        write: zod.boolean().nullish(),
        execute: zod.boolean().nullish(),
      })
    )
    .nullish(),
});

export const postApiChargepointV1UserCpRightsSetUserCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить сущность
 */
export const getApiChargepointV1UserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargepointV1UserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargepointV1UserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargepointV1UserGroupCpRightsIdBody = zod.object({
  userName: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  read: zod.boolean().nullish(),
  write: zod.boolean().nullish(),
  execute: zod.boolean().nullish(),
});

export const patchApiChargepointV1UserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargepointV1UserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargepointV1UserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargepointV1UserGroupCpRightsQueryParams = zod.object({
  userName: zod.string().optional(),
});

export const getApiChargepointV1UserGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userName: zod.string().min(1),
        groupId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargepointV1UserGroupCpRightsBody = zod.object({
  userName: zod.string().min(1),
  groupId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargepointV1UserGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsBody =
  zod.object({
    userName: zod.string().min(1),
    allCpRight: zod.boolean().nullish(),
    groupCpRights: zod
      .array(
        zod.object({
          groupId: zod.string().uuid(),
          read: zod.boolean().nullish(),
          write: zod.boolean().nullish(),
          execute: zod.boolean().nullish(),
        })
      )
      .nullish(),
  });

export const postApiChargepointV1UserGroupCpRightsSetUserGroupCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });
