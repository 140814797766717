import { z } from 'zod';
import { UploadFile } from 'antd';

import { requiredNumber } from 'shared/lib/schema';

import { MAX_FILE_SIZE_MB } from 'entities/charge-point/consts';

export const FormSchema = z.object({
  priority: requiredNumber,
  file: z
    .array(z.custom<UploadFile>())
    .refine((files) => {
      return files.every((file) => file.originFileObj instanceof File);
    })
    .transform((files) => files[0])
    .transform((val, ctx) => {
      if (!val.originFileObj) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Missing original files',
        });

        return z.NEVER;
      }

      return val.originFileObj;
    })
    .refine((file) => {
      const { size } = file;

      return size <= MAX_FILE_SIZE_MB * 1024 * 1024;
    }, `Max file size is ${MAX_FILE_SIZE_MB}MB.`)
    .optional(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
