import styled from 'styled-components';

import { DayjsDatePicker } from 'shared/ui/dayjs-date-picker';
import { COLOR } from 'shared/consts';

const { RangePicker } = DayjsDatePicker;

export const StyledDaysRangePicker = styled(RangePicker)`
  border: 1px solid rgba(0, 0, 0, 0.3);

  &.ant-picker-disabled {
    border-color: rgba(0, 0, 0, 0.3);
  }

  & .ant-picker-input > input[disabled] {
    color: ${COLOR.black};
  }
`;
