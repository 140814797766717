import { Controller as TempController } from '../model';

export const SUPERUSER_ROLE = 'Admin';

// Список контроллеров, для которых права на чтение есть у всех юзеров
export const READ_FOR_ALL_CONTROLLERS: TempController[] = [
  'Bank',
  'Country',
  'MobileApp',
];

export const CONTROLLERS = [
  'ChargePointServiceChargePoint',
  'ChargePointServiceChargePointRights',
  'ChargePointServiceTariff',
  'ChargePointServiceConnector',
  'ChargePointServiceHubs',
  'ChargePointServiceFirmware',
  'ChargePointServiceGroup',
  'AnalyticsService',
  'ArmeniaPay',
  'BalancingService',
  'BankOfGeorgiaConsumers',
  'BankOfGeorgia',
  'Bff',
  'BillingService',
  'BonusService',
  'CompanyService',
  'CpSoapController',
  'EpayPayService',
  'FiscalBusinessRu',
  'FiscalOnlineHdMam',
  'FiscalService',
  'IdentityService',
  'InformationService',
  'MtsConsumers',
  'MtsGate',
  'OcpIService',
  'PaymentService',
  'PushNotificationService',
  'ReserveService',
  'SmsSender',
  'SoapRestEmulator',
  'UserService',
] as const;

type Controller = typeof CONTROLLERS[number];

export const CONTROLLER: Array<{ name: Controller; label: string }> = [
  {
    name: 'ChargePointServiceChargePoint',
    label: 'ChargePointServiceChargePoint',
  },
  {
    name: 'ChargePointServiceChargePointRights',
    label: 'ChargePointServiceChargePointRights',
  },
  { name: 'ChargePointServiceTariff', label: 'ChargePointServiceTariff' },
  { name: 'ChargePointServiceConnector', label: 'ChargePointServiceConnector' },
  { name: 'ChargePointServiceHubs', label: 'ChargePointServiceHubs' },
  { name: 'ChargePointServiceFirmware', label: 'ChargePointServiceFirmware' },
  { name: 'ChargePointServiceGroup', label: 'ChargePointServiceGroup' },
  { name: 'AnalyticsService', label: 'AnalyticsService' },
  { name: 'ArmeniaPay', label: 'ArmeniaPay' },
  { name: 'BalancingService', label: 'BalancingService' },
  {
    name: 'BankOfGeorgiaConsumers',
    label: 'BankOfGeorgiaConsumers',
  },
  {
    name: 'BankOfGeorgia',
    label: 'BankOfGeorgia',
  },
  {
    name: 'Bff',
    label: 'Bff',
  },
  { name: 'BillingService', label: 'BillingService' },
  { name: 'BonusService', label: 'BonusService' },
  {
    name: 'CompanyService',
    label: 'CompanyService',
  },
  {
    name: 'CpSoapController',
    label: 'CpSoapController',
  },
  { name: 'EpayPayService', label: 'EpayPayService' },
  { name: 'FiscalBusinessRu', label: 'FiscalBusinessRu' },
  { name: 'FiscalOnlineHdMam', label: 'FiscalOnlineHdMam' },
  { name: 'FiscalService', label: 'FiscalService' },
  {
    name: 'IdentityService',
    label: 'IdentityService',
  },
  {
    name: 'InformationService',
    label: 'InformationService',
  },
  {
    name: 'MtsConsumers',
    label: 'MtsConsumers',
  },
  {
    name: 'MtsGate',
    label: 'MtsGate',
  },
  {
    name: 'OcpIService',
    label: 'OcpIService',
  },
  {
    name: 'PaymentService',
    label: 'PaymentService',
  },
  {
    name: 'PushNotificationService',
    label: 'PushNotificationService',
  },
  {
    name: 'ReserveService',
    label: 'ReserveService',
  },
  {
    name: 'SmsSender',
    label: 'SmsSender',
  },
  {
    name: 'SoapRestEmulator',
    label: 'SoapRestEmulator',
  },
  {
    name: 'UserService',
    label: 'UserService',
  },
];

export const RIGHTS = ['read', 'write', 'execute'] as const;
