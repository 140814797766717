import { z } from 'zod';

import {
  useGetChargePoints,
  useGetChargePointsCount,
} from 'shared/api/services/chargepoint/rtk';
import { getServerErrorText } from 'shared/lib';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { useGetServicesLists } from 'shared/api/services/billing/rtk/enhanced';

import { getCompaniesMapping } from 'entities/company';

const pageQueryParamsSchema = z.object({
  // ownersId: z.array(z.string()).optional().catch(undefined),
  // groupId: z.string().optional(),
  // hubId: z.string().optional(),
  // withTestCp: z.boolean().optional(),
  mobileAppId: z.string().optional(),
  dateFrom: z.string().optional().catch(undefined),
  dateTo: z.string().optional().catch(undefined),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
});

type Props = {
  hubId?: string;
  groupId?: string;
  //
  ownersId?: string[];
  withTestCp?: boolean;
  status?: string[];
  ownerTypes?: string[];
};

export function TableContainer({
  groupId,
  ownersId,
  status,
  ownerTypes,
  withTestCp,
}: Props) {
  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    data: chargePoints,
    error: chargePointsError,
    isLoading: isChargePointsLoading,
    isFetching: isChargePointsFetching,
  } = useGetChargePoints({
    ...queryParams,
    offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
    groupId,
    ownersId,
    status,
    ownerTypes,
    withTestCp,
  });

  const {
    data: chargePointsCount,
    error: chargePointsCountError,
    isLoading: isChargePointsCountLoading,
    isFetching: isChargePointsCountFetching,
  } = useGetChargePointsCount({
    ...queryParams,
    groupId,
    ownersId,
    // status,
    ownerTypes,
    withTestCp,
  });

  const companiesQuery = useGetCompanies(undefined);

  const isLoading =
    isChargePointsLoading ||
    isChargePointsCountLoading ||
    companiesQuery.isLoading;
  const isFetching = isChargePointsFetching || isChargePointsCountFetching;

  const apiResponseError = [chargePoints, chargePointsCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [chargePointsError, chargePointsCountError].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !chargePoints?.data ||
    !chargePointsCount ||
    chargePointsCount.data === undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      data={chargePoints?.data || []}
      loading={isFetching}
      totalCount={chargePointsCount.data}
      companiesMapping={getCompaniesMapping(companiesQuery.data?.data || [])}
    />
  );
}
