import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargepointV1FirmwaresById: build.query<
      GetApiChargepointV1FirmwaresByIdApiResponse,
      GetApiChargepointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Firmwares/${queryArg}`,
      }),
    }),
    patchApiChargepointV1FirmwaresById: build.mutation<
      PatchApiChargepointV1FirmwaresByIdApiResponse,
      PatchApiChargepointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Firmwares/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    deleteApiChargepointV1FirmwaresById: build.mutation<
      DeleteApiChargepointV1FirmwaresByIdApiResponse,
      DeleteApiChargepointV1FirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Firmwares/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargepointV1Firmwares: build.query<
      GetApiChargepointV1FirmwaresApiResponse,
      GetApiChargepointV1FirmwaresApiArg
    >({
      query: () => ({ url: `/api/chargepoint/V1/Firmwares` }),
    }),
    postApiChargepointV1Firmwares: build.mutation<
      PostApiChargepointV1FirmwaresApiResponse,
      PostApiChargepointV1FirmwaresApiArg
    >({
      query: (queryArg) => ({
        url: `/api/chargepoint/V1/Firmwares`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargepointV1FirmwaresCount: build.query<
      GetApiChargepointV1FirmwaresCountApiResponse,
      GetApiChargepointV1FirmwaresCountApiArg
    >({
      query: () => ({ url: `/api/chargepoint/V1/Firmwares/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargepointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type GetApiChargepointV1FirmwaresByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargepointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PatchApiChargepointV1FirmwaresByIdApiArg = {
  /** Идентификатор */
  id: string;
  body: {
    Name?: string;
    Description?: string;
    FormatUrl?: FormatUrl;
    File?: Blob;
  };
};
export type DeleteApiChargepointV1FirmwaresByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargepointV1FirmwaresByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargepointV1FirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmIReadOnlyCollectionBaseResponse;
export type GetApiChargepointV1FirmwaresApiArg = void;
export type PostApiChargepointV1FirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PostApiChargepointV1FirmwaresApiArg = {
  Name: string;
  Description?: string;
  FormatUrl: FormatUrl;
  File: Blob;
};
export type GetApiChargepointV1FirmwaresCountApiResponse =
  /** status 200 Success */ UInt32BaseResponse;
export type GetApiChargepointV1FirmwaresCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type FormatUrl = 'SOAP' | 'JSON';
export type GetFirmwareVm = {
  id: string;
  name: string;
  description?: string | null;
  url: string;
  objectStorageName: string;
  formatUrl: FormatUrl;
  lastUpdate: string;
};
export type GetFirmwareVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetFirmwareVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm[] | null;
};
export type UInt32BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargepointV1FirmwaresByIdQuery,
  usePatchApiChargepointV1FirmwaresByIdMutation,
  useDeleteApiChargepointV1FirmwaresByIdMutation,
  useGetApiChargepointV1FirmwaresQuery,
  usePostApiChargepointV1FirmwaresMutation,
  useGetApiChargepointV1FirmwaresCountQuery,
} = injectedRtkApi;
