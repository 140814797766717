import { StageWithTariffDto } from 'shared/api/services/billing/rtk/generated';

export const getStageNextSerialNumber = (
  stages: StageWithTariffDto[] | undefined
) => {
  if (!stages) {
    return 0;
  }

  let maxNumber: number = 0;

  stages.forEach((el) => {
    if (el.serialNumber > maxNumber) {
      maxNumber = el.serialNumber;
    }
  });

  return maxNumber + 1;
};
