import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.div`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
`;

export const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
`;
