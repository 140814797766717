import styled from 'styled-components';

export const StyledTable = styled.table`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  border-collapse: collapse;
  border: 1px solid rgba(0, 0, 0, 0.15);

  & thead,
  tbody,
  tfoot,
  tr {
    display: contents;
  }
`;

export const StyledThead = styled.thead``;

export const StyledTbody = styled.tbody``;

export const StyledTfoot = styled.tfoot`
  & td {
    font-weight: 500;
  }
`;

export const StyledTr = styled.tr``;

export const StyledTh = styled.th`
  background-color: rgb(246, 246, 246);
  text-align: center;
  vertical-align: middle;
  padding: 17px 19px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-collapse: collapse;
`;

export const StyledTd = styled.td`
  padding: 7px 19px;
  background-color: rgb(250, 250, 250);
  text-align: center;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-collapse: collapse;
`;
