import { useGetBillingBalances } from 'shared/api/services/billing/rtk/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';
import { getErrorMessage } from 'shared/lib/http';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { ListLayout } from './layout';

type Props = {
  userId: string;
};

export function Container({ userId }: Props) {
  const balancesQuery = useGetBillingBalances({ userId });
  const countriesQuery = useGetCountries(undefined);

  const isLoading = balancesQuery.isLoading || countriesQuery.isLoading;
  const error = balancesQuery.error || countriesQuery.error;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getErrorMessage(error)} />;
  }

  if (!balancesQuery.data?.data || !countriesQuery.data?.data) {
    return <EmptyData />;
  }

  return (
    <ListLayout
      balances={balancesQuery.data.data}
      countries={countriesQuery.data.data}
    />
  );
}
