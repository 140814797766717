import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

import { format, type, status } from 'entities/connector/consts';

const FormResultSchema = z.object({
  type: type,
  format: format,
  status: status,
  innerConnectorId: z.number(),
  maxPower: z.number().gte(0),
  manualBlock: z.boolean(),
  maxAmperage: z.number().gte(0).nullish(),
  maxVoltage: z.number().gte(0).nullish(),
  liter: z.string().nullish(),
});

export const FormSchema = z
  .object({
    type: z.string().transform(emptyStringToUndefined),
    format: z.string().transform(emptyStringToUndefined),
    status: z.string().transform(emptyStringToUndefined),
    innerConnectorId: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    maxPower: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    manualBlock: z.boolean(),
    maxAmperage: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    maxVoltage: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined))
      .nullish(),
    liter: z.string().nullish(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
