import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiFiscalV1ActionsFiscalize: build.mutation<
      PostApiFiscalV1ActionsFiscalizeApiResponse,
      PostApiFiscalV1ActionsFiscalizeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/V1/Actions/fiscalize`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiFiscalV1ChecksById: build.query<
      GetApiFiscalV1ChecksByIdApiResponse,
      GetApiFiscalV1ChecksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Fiscal/V1/Checks/${queryArg}` }),
    }),
    getApiFiscalV1Checks: build.query<
      GetApiFiscalV1ChecksApiResponse,
      GetApiFiscalV1ChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/V1/Checks`,
        params: {
          BillingId: queryArg.billingId,
          Status: queryArg.status,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiFiscalV1ChecksCount: build.query<
      GetApiFiscalV1ChecksCountApiResponse,
      GetApiFiscalV1ChecksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/V1/Checks/count`,
        params: {
          requestDto: queryArg,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiFiscalV1ActionsFiscalizeApiResponse =
  /** status 200 Success */ CheckDtoResponseBase;
export type PostApiFiscalV1ActionsFiscalizeApiArg = FiscalizeRequestDto;
export type GetApiFiscalV1ChecksByIdApiResponse =
  /** status 200 Success */ CheckDtoResponseBase;
export type GetApiFiscalV1ChecksByIdApiArg = string;
export type GetApiFiscalV1ChecksApiResponse =
  /** status 200 Success */ CheckDtoIReadOnlyCollectionResponseBase;
export type GetApiFiscalV1ChecksApiArg = {
  billingId?: string;
  status?: CheckStatus;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiFiscalV1ChecksCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiFiscalV1ChecksCountApiArg =
  | GetChecksCountByFilterRequestDto
  | undefined;
export type CheckStatus =
  | 'UNDEFINED'
  | 'ERROR'
  | 'TIME_OUT_ERROR'
  | 'IN_PROCESS'
  | 'FISCALIZED';
export type CheckErrorReason =
  | 'UNDEFINED'
  | 'INNER_FISCAL_SERVICE_ERROR'
  | 'FISCAL_SERVICE_IS_UNAVAILABLE'
  | 'FISCAL_RESPONSE_ERROR'
  | 'TIMEOUT_ERROR'
  | 'UNKNOWN_ERROR';
export type CheckDto = {
  id: string;
  billingId: string;
  countryId: string;
  docNumber: string;
  totalAmount: number;
  email?: string | null;
  phoneNumber?: string | null;
  agentInn?: string | null;
  agentLegalName?: string | null;
  agentPhoneNumber?: string | null;
  status: CheckStatus;
  errorReason?: CheckErrorReason;
  errorText?: string | null;
  responseFiscalService?: string | null;
  retryErrorFiscalizeCount: number;
  receiptUrl?: string | null;
  fiscalResponse?: string | null;
  fiscalDate?: string | null;
  createdDate: string;
  lastUpdate: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CheckDtoResponseBase = {
  data?: CheckDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UserInfo = {
  email?: string | null;
  phoneNumber?: string | null;
};
export type AgentInfo = {
  inn: string;
  legalName: string;
  phoneNumber: string;
};
export type GoodDto = {
  name: string;
  amount: number;
};
export type FiscalizeRequestDto = {
  billingId: string;
  totalAmount: number;
  countryId: string;
  userInfo: UserInfo;
  agentInfo?: AgentInfo;
  goods: GoodDto[];
};
export type CheckDtoIReadOnlyCollectionResponseBase = {
  data?: CheckDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChecksCountByFilterRequestDto = object;
export const {
  usePostApiFiscalV1ActionsFiscalizeMutation,
  useGetApiFiscalV1ChecksByIdQuery,
  useGetApiFiscalV1ChecksQuery,
  useGetApiFiscalV1ChecksCountQuery,
} = injectedRtkApi;
