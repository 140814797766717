import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isAxiosError } from 'axios';

export const getRtkFromAxiosError = (
  err: unknown
): { error: FetchBaseQueryError | SerializedError } => {
  if (isAxiosError(err)) {
    if (err.response) {
      return {
        error: {
          status: err.response.status,
          data: err.response.data,
        },
      };
    }
  }

  if (err instanceof Error) {
    return {
      error: {
        error: err.message,
        status: 'CUSTOM_ERROR',
      },
    };
  }

  return {
    error: {
      error: 'Неизвестная ошибка',
      status: 'CUSTOM_ERROR',
    },
  };
};
