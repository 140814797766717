import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Spin, Tooltip } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
  ButtonsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { CustomDateTimePicker } from 'shared/ui/form/custom-datetimepicker';
import { LabelWithHint } from 'shared/ui/styles';
import {
  BonusProgram,
  BonusProgramLevel,
} from 'shared/api/services/bonus/rtk/generated';

import {
  BONUS_PROGRAM_TYPE,
  BONUS_PROGRAM_FREQUENCY,
} from 'entities/bonus-program';

import { AddBonusLevelButton } from 'features/bonus-program/add-level';
import { LevelList } from 'features/bonus-program/levels/ui/list';

import { FormOutput, FormInput, FormSchema } from '../consts/schema';
import { useUpdateBonusProgram } from '../hooks/use-update-bonus-program';

const getDefaultValues = (bonusProgram: BonusProgram): FormInput => {
  const {
    isDeleted,
    id,
    lastUpdate,
    dateStart,
    dateStop,
    description,
    ...rest
  } = bonusProgram;

  return {
    ...rest,
    dateStart: dateStart ? dayjs(dateStart) : undefined,
    dateStop: dateStop ? dayjs(dateStop) : undefined,
    description: description ?? '',
  };
};

type Props = {
  countries: GetCountryVm[];
  bonusProgram: BonusProgram;
  levels: BonusProgramLevel[];
};

export function Form({ bonusProgram, countries, levels }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(bonusProgram),
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  useEffect(() => {
    form.reset({ ...getDefaultValues(bonusProgram) });
  }, [bonusProgram]);

  const { handleUpdate, isMutationLoading } = useUpdateBonusProgram();

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate(data, bonusProgram.id);
  });

  return (
    <UiCard>
      <UiCard.Header>{bonusProgram.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isMutationLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields countries={countries} isDisabled={isDisabled} />
              <LevelList levels={levels} bonusProgramId={bonusProgram.id} />
              <AddBonusLevelButton bonusProgramId={bonusProgram.id} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ countries, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomDateTimePicker<FormInput>
            name="dateStart"
            label="Дата старта"
            showTime
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <CustomDateTimePicker<FormInput>
            name="dateStop"
            label="Дата завершения"
            showTime
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="type"
            label="Тип"
            options={Object.entries(BONUS_PROGRAM_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="frequencyValue"
            label="frequencyValue"
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="frequency"
            label="Искать сессии зарядки за последние"
            options={Object.entries(BONUS_PROGRAM_FREQUENCY).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="executionCron"
            label={
              <LabelWithHint>
                Запуск проверки условий (по формату CRON)
                <Tooltip
                  title={
                    <>
                      <p>
                        cron-формат состоит из пяти полей, разделенных
                        пробелами:
                      </p>
                      <p>Минуты Часы Дни_месяца Месяцы Дни_недели Годы</p>
                    </>
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
