import {
  enhancedApi as api,
  GetApiChargepointV1GroupsApiResponse,
} from '../generated/groups';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargepointV1Groups: (endpoint) => {
      endpoint.providesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
      endpoint.transformResponse = (
        response: GetApiChargepointV1GroupsApiResponse
      ) => {
        if (response.data) {
          const data = [...response.data].sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          return { ...response, data };
        }

        return response;
      };
    },
    getApiChargepointV1GroupsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: arg },
      ];
    },
    postApiChargepointV1Groups: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    patchApiChargepointV1GroupsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: arg.id,
        },
      ];
    },
    deleteApiChargepointV1GroupsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargepointV1GroupsQuery: useGetChargePointGroups,
  useGetApiChargepointV1GroupsCountQuery: useGetChargePointGroupsCount,
  useGetApiChargepointV1GroupsByIdQuery: useGetChargePointGroup,
  usePostApiChargepointV1GroupsMutation: useAddChargePointGroup,
  usePatchApiChargepointV1GroupsByIdMutation: useEditChargePointGroup,
  useDeleteApiChargepointV1GroupsByIdMutation: useDeleteChargePointGroup,
} = enhancedApi;
