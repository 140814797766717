import { useSessionTerminate } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { StyledButton } from './styles';

const SUCCESS_MSG = 'Команда отправлена';
const ERROR_MSG = 'Не удалось отправить команду';

type Props = {
  sessionId: string;
};

export function SessionTerminateButton({ sessionId }: Props) {
  const [trigger] = useSessionTerminate();

  const onClick = async () => {
    try {
      const res = await trigger(sessionId).unwrap();

      openSuccessNotification(SUCCESS_MSG);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return (
    <StyledButton onClick={onClick} type="button">
      Закрыть сессию
    </StyledButton>
  );
}
