import { useCommandStop } from 'shared/api/services/chargepoint/rtk/enhanced/commands';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { StyledButton } from './styles';

const SUCCESS_MSG = 'Команда отправлена';
const ERROR_MSG = 'Не удалось отправить команду';

type Props = {
  chargePointId: string;
  connectorId: string;
};

export function CommandStopButton(props: Props) {
  const [trigger] = useCommandStop();

  const onClick = async () => {
    try {
      const res = await trigger({ ...props }).unwrap();

      openSuccessNotification(SUCCESS_MSG);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return (
    <StyledButton onClick={onClick} type="button">
      Остановить сессию на ЭЗС
    </StyledButton>
  );
}
