import NiceModal from '@ebay/nice-modal-react';

import { GetPhotoVm } from 'shared/api/services/chargepoint/rtk/generated/photos';
import { DeleteIcon } from 'shared/icons/delete';
import { EditIcon } from 'shared/icons/edit';
import { StyledText } from 'shared/ui';

import { DeletePhotoModal } from 'features/charge-point-photo/delete';
import { EditPhotoModal } from 'features/charge-point-photo/edit';

import {
  ControlButtons,
  StyledButton,
  StyledFooter,
  StyledContainer,
  ImageContainer,
} from './styles';

type Props = {
  photo: GetPhotoVm;
  chargePointId: string;
};

export function PhotoListItem({ photo, chargePointId }: Props) {
  const { id, url, objectStorageName } = photo;

  const showDeleteModal = () => {
    NiceModal.show(DeletePhotoModal, {
      chargePointId,
      photoId: id,
    });
  };

  const showEditModal = () => {
    NiceModal.show(EditPhotoModal, {
      chargePointId,
      photo,
    });
  };

  return (
    <StyledContainer>
      <ImageContainer style={{ backgroundImage: `url("${url}")` }} />
      <StyledFooter>
        <StyledText value={objectStorageName} />
        <ControlButtons>
          <StyledButton onClick={showDeleteModal} type="button">
            <DeleteIcon />
          </StyledButton>
          <StyledButton onClick={showEditModal} type="button">
            <EditIcon />
          </StyledButton>
        </ControlButtons>
      </StyledFooter>
    </StyledContainer>
  );
}
