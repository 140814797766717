import {
  enhancedApi,
  useManualCredit,
  useManualDebit,
} from 'shared/api/services/bonus/rtk/enhanced';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';

import { FormOutput } from '../consts/schema';

const SUCCESS_MSG = 'Баланс бонусов обновлен';
const ERROR_MSG = 'Не удалось обновить баланс бонусов';

type Props = {
  closeModal: () => void;
};

export const useManageBonuses = ({ closeModal }: Props) => {
  const [creditTrigger, { isLoading: isCreditLoading }] = useManualCredit();
  const [debitTrigger, { isLoading: isDebitLoading }] = useManualDebit();

  const dispatch = useAppDispatch();

  const handleManage = async ({
    data,
    userId,
  }: {
    data: FormOutput;
    userId: string;
  }) => {
    const { type, sum, ...rest } = data;

    try {
      if (type === 'credit') {
        const req = { ...rest, userId, creditSum: sum };

        await creditTrigger(req).unwrap();
      } else {
        const req = { ...rest, userId, debitSum: sum };

        await debitTrigger(req).unwrap();
      }

      openSuccessNotification(SUCCESS_MSG);

      await dispatch(
        enhancedApi.util.invalidateTags([{ type: 'BonusBalances', id: userId }])
      );

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return {
    handleManage,
    isLoading: isCreditLoading || isDebitLoading,
  };
};
