import { z } from 'zod';

import { LATITUDE, LONGITUDE } from 'shared/lib/schema';
import { patchApiChargepointV1HubsIdBody } from 'shared/api/services/chargepoint/orval/zod/schemas';

export const FormSchema = patchApiChargepointV1HubsIdBody
  .omit({
    latitude: true,
    longitude: true,
  })
  .extend({
    latitude: LATITUDE,
    longitude: LONGITUDE,
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;
