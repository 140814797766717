import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

const FormResultSchema = z.object({
  value: z.number().gte(0),
  type: z.enum(['debit', 'credit']),
});

export const FormSchema = z
  .object({
    value: z
      .string()
      .transform(emptyStringToUndefined)
      .transform((value) => (value ? Number(value) : undefined)),
    type: z.enum(['debit', 'credit']),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
