import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;

  box-shadow: 0px 0px 14px 0px rgba(24, 30, 28, 0.15);
  padding: 20px;
`;

export const CardTitle = styled.h3`
  color: #000;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  margin-bottom: 8px;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 1fr) 150px 400px minmax(50px, 1fr)
    minmax(50px, 1fr);
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 1fr) 150px 400px minmax(50px, 1fr)
    minmax(50px, 1fr);
  grid-column: 1 / -1;

  &:nth-child(even) {
    background-color: #fafafa;
  }
`;

export const HeaderCell = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  padding-block: 9px;
`;

export const BodyCell = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  padding-block: 9px;
`;

export const StyledLink = styled.span`
  color: #23c990;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
`;
