import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import { baseApi } from 'shared/api/base';
import userGroupsReducer from 'shared/redux/slices/user-groups/slice';
import usersReducer from 'shared/redux/slices/users/slice';
import chargePointsReducer from 'shared/redux/slices/charge-points/slice';
import chargePointGroupsReducer from 'shared/redux/slices/charge-point-groups/slice';
import eventsReducer from 'shared/redux/slices/events/slice';
import sessionsReducer from 'shared/redux/slices/sessions/slice';
import carModelsReducer from 'shared/redux/slices/car-models/slice';
import bankCardsReducer from 'shared/redux/slices/bank-cards/slice';
import identityUsersReducer from 'shared/redux/slices/identity-users/slice';
import identityRolesReducer from 'shared/redux/slices/identity-roles/slice';
import identityRoleUsersReducer from 'shared/redux/slices/identity-role-users/slice';
import identityGroupUsersReducer from 'shared/redux/slices/identity-group-users/slice';
import identityGroupsReducer from 'shared/redux/slices/identity-groups/slice';
import reserveSessionsReducer from 'shared/redux/slices/reserve-sessions/slice';
import batchesReducer from 'shared/redux/slices/batches/slice';
import billingsReducer from 'shared/redux/slices/billings/slice';
import bonusProgramsReducer from 'shared/redux/slices/bonus-programs/slice';
import transactionsReducer from 'shared/redux/slices/transactions/slice';
import bindingLogsReducer from 'features/user/binding-logs-table/store/slice';

import filtersReducer from 'features/charge-point/filters/store/slice';

import { monitoringPageSettingsReducer } from 'pages/monitoring';

import authReducer from './slices/auth/authSlice';
import authSessionReducer from './slices/auth/authSessionSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'monitoringPageSettings', 'chargePointsFilters'],
};
const authSessionPersistConfig = {
  key: 'authSession',
  storage: storageSession,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  authSession: persistReducer(authSessionPersistConfig, authSessionReducer),
  monitoringPageSettings: monitoringPageSettingsReducer,
  chargePointFilters: filtersReducer,
  userGroups: userGroupsReducer,
  users: usersReducer,
  chargePoints: chargePointsReducer,
  chargePointGroups: chargePointGroupsReducer,
  events: eventsReducer,
  sessions: sessionsReducer,
  reserveSessions: reserveSessionsReducer,
  carModels: carModelsReducer,
  bankCards: bankCardsReducer,
  identityUsers: identityUsersReducer,
  identityRoles: identityRolesReducer,
  identityRoleUsers: identityRoleUsersReducer,
  identityGroups: identityGroupsReducer,
  identityGroupUsers: identityGroupUsersReducer,
  batches: batchesReducer,
  billings: billingsReducer,
  bonusPrograms: bonusProgramsReducer,
  transactions: transactionsReducer,
  bindingLogs: bindingLogsReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);
