import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { UiModal } from 'shared/ui';

import { Form } from './form';

const FORM_TITLE = 'Изменить бонусный счет';

type Props = {
  userId: string;
  countries: GetCountryVm[];
};

export const Modal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title={FORM_TITLE}
    >
      <Form {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
