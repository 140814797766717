import { useGetChargePointPhotos } from 'shared/api/services/chargepoint/rtk/enhanced/photos';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { PhotoList } from './list';

type Props = {
  chargePointId: string;
};

export function Container({ chargePointId }: Props) {
  const photosQuery = useGetChargePointPhotos(chargePointId);

  if (photosQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (photosQuery.error) {
    return <ErrorMessage text={getServerErrorText(photosQuery.error)} />;
  }

  if (photosQuery.data?.status === 'ERROR') {
    return <ErrorMessage text={photosQuery.data?.statusMessage} />;
  }

  if (!photosQuery.data?.data) {
    return <EmptyData />;
  }

  return (
    <PhotoList photos={photosQuery.data.data} chargePointId={chargePointId} />
  );
}
