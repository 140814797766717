import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BindingLogsTable } from 'features/user/binding-logs-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UsersBindingLogsPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <BindingLogsTable userId={userId} />;
}
