import { useState } from 'react';
import { Col, Spin, UploadProps } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  getExtensionsString,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomUploadDragger } from 'shared/ui/form/custom-upload-dragger';
import { GetCarBrandVm } from 'shared/api/services/information/rtk/generated/car-brands';
import { useUpdateCarBrand } from 'shared/api/services/information/rtk';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { UploadDraggerButton } from 'shared/ui/ui-kit/upload-dragger/button';

import { CarBrandLogo } from 'entities/car';
import { ACCEPTED_IMAGE_TYPES } from 'entities/car-brand/consts';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  carBrand: GetCarBrandVm;
};

export function Form({ carBrand }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: carBrand.name,
    },
  });

  const [trigger, { isLoading }] = useUpdateCarBrand();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: carBrand.id,
        ...data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  const currentLogo = <CarBrandLogo carBrand={carBrand} />;

  return (
    <UiCard>
      <UiCard.Header>{carBrand.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields isDisabled={isDisabled} currentLogo={currentLogo} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  isDisabled: boolean;
  currentLogo: JSX.Element;
};

Form.Fields = function Fields({ isDisabled, currentLogo }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const props: UploadProps<File> = {
    maxCount: 1,
    multiple: false,
    accept: getExtensionsString(ACCEPTED_IMAGE_TYPES),
    beforeUpload: (_file, _list) => {
      return false;
    },
    listType: 'picture',
  };

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      {currentLogo}
      <FormRow>
        <Col span={24}>
          <CustomUploadDragger<FormInput>
            {...props}
            name="file"
            label="Файл логотипа"
            required
            disabled={isDisabled}
          >
            <UploadDraggerButton disabled={isDisabled} />
          </CustomUploadDragger>
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
