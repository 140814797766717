import styled from 'styled-components';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledLabel = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;

  margin-bottom: 4px;
`;
