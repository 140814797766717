import NiceModal from '@ebay/nice-modal-react';

import { UiPrimaryButton } from 'shared/ui/buttons';

import { Modal } from '../modal';

type Props = {
  balanceId: string;
  userId: string;
};

export function ManageButton(props: Props) {
  const onClick = () => {
    NiceModal.show(Modal, { ...props });
  };

  return (
    <UiPrimaryButton onClick={onClick} style={{ padding: '7px 28px' }}>
      Изменить
    </UiPrimaryButton>
  );
}
