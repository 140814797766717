import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Spin } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomInput } from 'shared/ui/form/custom-input';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
  ButtonsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { ServicesListDto } from 'shared/api/services/billing/rtk/generated';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';

import {
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_SPEED_TYPE,
  CHARGE_POINT_STATUS,
  COUNTRY_CODE,
  INFRASTRUCTURE_INFO,
} from 'entities/charge-point/consts';
import { getOcppConfigFromString } from 'entities/charge-point/lib/get-ocpp-config-string';

import { ChargePointPhotosWidget } from 'widgets/charge-point/photos';

import { FormOutput, FormInput, FormSchema } from '../../consts/schema';
import { StyledTitle } from './styles';
import { useUpdateChargePoint } from '../../hooks/use-update-cp';

const getInfrastructureDefaultValue = (
  infrastructureInfo: string | null | undefined
): string[] => {
  if (!infrastructureInfo) {
    return [];
  }

  try {
    const o = JSON.parse(infrastructureInfo);

    return Object.keys(o).filter((key) => o[key] === 'true');
  } catch (e) {
    return [];
  }
};

const getDefaultValues = (
  chargePoint: GetChargePointVm,
  servicesListId: string | undefined
): FormInput => {
  const {
    latitude,
    longitude,
    ocppConfig,
    infrastructureInfo,
    hub,
    maxPower,
    number,
    userLocationCheckDistance,
    ipAddress,
    ...rest
  } = chargePoint;

  return {
    ...rest,
    coordinates: `${latitude}, ${longitude}`,
    ocppConfig: ocppConfig ? getOcppConfigFromString(ocppConfig) : '',
    infrastructureInfo: getInfrastructureDefaultValue(infrastructureInfo),
    servicesListId: servicesListId ?? '',
    hubId: hub?.id,
    maxPower: String(maxPower),
    number: number ? String(number) : '',
    userLocationCheckDistance: userLocationCheckDistance
      ? String(userLocationCheckDistance)
      : '',
    ipAddress: ipAddress ?? '',
  };
};

type Props = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  companies: CompanyDto[];
  chargePoint: GetChargePointVm;
  servicesLists: ServicesListDto[];
  countries: GetCountryVm[];
  servicesListId: string | undefined;
};

export function Form({ chargePoint, servicesListId, ...rest }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(() => {
      return getDefaultValues(chargePoint, servicesListId);
    }, [chargePoint, servicesListId]),
  });

  useEffect(() => {
    form.reset({ ...getDefaultValues(chargePoint, servicesListId) });
  }, [chargePoint, servicesListId]);

  const { handleUpdate, isLoading } = useUpdateChargePoint();

  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = form.handleSubmit(async (data) => {
    handleUpdate({ data, chargePoint, currentServicesListId: servicesListId });
  });

  return (
    <UiCard>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields {...rest} isDisabled={isDisabled} />
              <ChargePointPhotosWidget chargePointId={chargePoint.id} />
              <Form.Buttons
                isDisabled={isDisabled}
                enableForm={() => setIsDisabled(false)}
              />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = Omit<Props, 'chargePoint' | 'servicesListId'> & {
  isDisabled: boolean;
};

Form.Fields = function Fields({
  companies,
  groups,
  hubs,
  servicesLists,
  countries,
  isDisabled,
}: FieldsProps) {
  return (
    <>
      <FormRow gutter={40}>
        <Col span={12}>
          <StyledTitle>О станции</StyledTitle>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="name"
                label="Название"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="coordinates"
                label="Координаты"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="address"
                label="Адрес"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="maxPower"
                label="Максимальная мощность"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="city"
                label="Город"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="status"
                label="Статус"
                options={Object.entries(CHARGE_POINT_STATUS).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="groupId"
                label="Группа"
                options={groups.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="companyId"
                label="Владелец"
                options={companies.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="hubId"
                label="Хаб"
                options={hubs.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="servicesListId"
                label="Тариф"
                options={servicesLists.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="infrastructureInfo"
                label="Что рядом"
                options={Object.entries(INFRASTRUCTURE_INFO).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                mode="multiple"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Макс. % оплаты баллами"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="workingTime"
                label="Время работы"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Доступны скидки"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="publicDescription"
                label="Публичное описание"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="countryCode"
                label="Код страны"
                options={countries.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
        </Col>

        <Col span={12}>
          <StyledTitle>Техническая информация</StyledTitle>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="speedType"
                label="Тип станции"
                options={Object.entries(CHARGE_POINT_SPEED_TYPE).map(
                  (entry) => {
                    const [key, label] = entry;

                    return {
                      value: key,
                      label,
                    };
                  }
                )}
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="simNumber"
                label="Номер SIM"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="manufacturer"
                label="Производитель"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="commentary"
                label="Внутреннее описание"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="number"
                label="Cерийный номер"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="chargeBoxIdentity"
                label="СhargeBoxIdentity"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="chargePointProtocol"
                label="Протокол ЭЗС"
                options={Object.entries(CHARGE_POINT_PROTOCOL).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="ipAddress"
                label="IP-адрес"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="softwareRevision"
                label="Версия ПО"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="userLocationCheckDistance"
                label="Макс. расстояние до пользователя"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="ocppConfig"
                label="OCPP конфиг"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="warningText"
                label="Аварийное сообщение в приложении"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="isVisible"
                label="Показывать на карте"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="isTestCp"
                label="Тестовая"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="multiconnectorSupport"
                label="Поддержка мультиконнектора (не убирать без причины)"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
