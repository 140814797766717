import { useNavigate } from 'react-router-dom';

import { useEditChargePointGroup } from 'shared/api/services/chargepoint/rtk/enhanced/groups';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { FormOutput } from '../consts/schema';

export const SUCCESS_MSG = 'Группа ЭЗС обновлена';
export const ERROR_MSG = 'Не удалось обновить группу ЭЗС';

export function useUpdateChargePointGroup() {
  const [trigger, { isLoading }] = useEditChargePointGroup();

  const navigate = useNavigate();

  const handleUpdate = async ({
    data,
    id,
  }: {
    id: string;
    data: FormOutput;
  }) => {
    // Потом добавятся скидки

    try {
      const res = await trigger({ id, updateGroupRequest: data }).unwrap();

      openSuccessNotification(SUCCESS_MSG);

      // navigate(`${CHARGE_POINT_ROUTES.CHARGE_POINTS_GROUPS_LIST}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ERROR_MSG;

      openErrorNotification(errorText);
    }
  };

  return {
    handleUpdate,
    isLoading,
  };
}
