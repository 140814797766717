import { isAxiosError } from 'axios';

import { getChargePointServiceWebApi } from '../../orval/axios/client';
import { PatchApiChargePointV1FirmwaresIdBody } from '../../orval/axios/schemas';
import {
  BaseResponse,
  enhancedApi as api,
  GetFirmwareVmBaseResponse,
  PostApiChargepointV1FirmwaresApiResponse,
} from '../generated/firmwares';

type GetFirstArgumentOfAnyFunction<T> = T extends (
  first: infer FirstArgument,
  ...args: any[]
) => any
  ? FirstArgument
  : never;

const { postApiChargePointV1Firmwares, patchApiChargePointV1FirmwaresId } =
  getChargePointServiceWebApi();

type AddFirmwareBody = GetFirstArgumentOfAnyFunction<
  typeof postApiChargePointV1Firmwares
>;

type UpdateFirmwareBody = GetFirstArgumentOfAnyFunction<
  typeof patchApiChargePointV1FirmwaresId
>;

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiChargepointV1Firmwares: (endpoint) => {
        endpoint.providesTags = [{ type: 'Firmwares', id: 'LIST' }];
      },
      getApiChargepointV1FirmwaresById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Firmwares', id: arg },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // customAddFirmware: builder.mutation<
      //   PostApiChargePointV1FirmwaresApiResponse,
      //   FormData
      // >({
      //   query: (body: FormData) => ({
      //     url: `/Api/ChargePoint/V1/Firmwares`,
      //     method: 'POST',
      //     body,
      //     formData: true,
      //   }),
      //   invalidatesTags: [{ type: 'Firmwares', id: 'LIST' }],
      // }),
      // customUpdateFirmware: builder.mutation<
      //   PostApiChargePointV1FirmwaresApiResponse,
      //   { body: FormData; id: string }
      // >({
      //   query: ({ body, id }) => ({
      //     url: `/Api/ChargePoint/V1/Firmwares/${id}`,
      //     method: 'PATCH',
      //     body,
      //     formData: true,
      //   }),
      //   invalidatesTags: (result, error, arg) => [
      //     { type: 'Firmwares', id: 'LIST' },
      //     {
      //       type: 'Firmwares',
      //       id: arg.id,
      //     },
      //   ],
      // }),
      customCreateFirmware: builder.mutation<
        GetFirmwareVmBaseResponse,
        {
          body: AddFirmwareBody;
          setUploadProgress: (val: number) => void;
        }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { postApiChargePointV1Firmwares } =
            getChargePointServiceWebApi();

          const { body, setUploadProgress } = args;

          try {
            const res = await postApiChargePointV1Firmwares(body, {
              onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total!) * 100); // TODO: typing for total

                setUploadProgress(percent);
              },
            });

            if (!res) {
              return {
                error: {
                  error: 'Неизвестная ошибка',
                  status: 'CUSTOM_ERROR',
                },
              };
            }

            return { data: res };
          } catch (err) {
            if (isAxiosError(err)) {
              if (err.response) {
                return {
                  status: err.response.status,
                  data: err.response.data,
                };
              } else if (err.request) {
                return {
                  error: err.request,
                };
              }
            }

            if (err instanceof Error) {
              return {
                error: err.message,
              };
            }

            return {
              error: {
                error: 'Неизвестная ошибка',
                status: 'CUSTOM_ERROR',
              },
            };
          }
        },
        invalidatesTags: [{ type: 'Firmwares', id: 'LIST' }],
      }),
      customUpdateFirmware: builder.mutation<
        GetFirmwareVmBaseResponse,
        {
          id: string;
          body: PatchApiChargePointV1FirmwaresIdBody;
          setUploadProgress: (val: number) => void;
        }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { patchApiChargePointV1FirmwaresId } =
            getChargePointServiceWebApi();

          const { id, body, setUploadProgress } = args;

          try {
            const res = await patchApiChargePointV1FirmwaresId(id, body, {
              onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total!) * 100); // TODO: typing for total

                setUploadProgress(percent);
              },
            });

            if (!res) {
              return {
                error: {
                  error: 'Неизвестная ошибка',
                  status: 'CUSTOM_ERROR',
                },
              };
            }

            return { data: res };
          } catch (err) {
            if (isAxiosError(err)) {
              if (err.response) {
                return {
                  status: err.response.status,
                  data: err.response.data,
                };
              } else if (err.request) {
                return {
                  error: err.request,
                };
              }
            }

            if (err instanceof Error) {
              return {
                error: err.message,
              };
            }

            return {
              error: {
                error: 'Неизвестная ошибка',
                status: 'CUSTOM_ERROR',
              },
            };
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Firmwares', id: arg.id },
          { type: 'Firmwares', id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetApiChargepointV1FirmwaresQuery: useGetFirmwares,
  useGetApiChargepointV1FirmwaresByIdQuery: useGetFirmware,
  // useCustomAddFirmwareMutation: useCustomAddFirmware,
  // useCustomUpdateFirmwareMutation: useCustomUpdateFirmware,
  useCustomCreateFirmwareMutation: useCustomCreateFirmwareMutation,
  useCustomUpdateFirmwareMutation: useCustomUpdateFirmwareMutation,
} = enhancedApi;
