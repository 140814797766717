import { z } from 'zod';

import { emptyStringToUndefined } from 'shared/lib/schema';

import { StageResultSchema, StageSchema } from 'entities/billing';

const FormResultSchema = z.object({
  name: z.string().min(1),
  reservingStages: z.array(StageResultSchema),
  chargingStages: z.array(StageResultSchema),
  chargePointIds: z.array(z.string().uuid()).nullish(),
});

export const FormSchema = z
  .object({
    name: z.string().transform(emptyStringToUndefined),
    reservingStages: z.array(StageSchema),
    chargingStages: z.array(StageSchema),
    chargePointIds: z.array(z.string().uuid()).nullish(),
  })
  .pipe(FormResultSchema);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  chargingStages: [],
  reservingStages: [],
  name: '',
};
