import { UploadOutlined } from '@ant-design/icons';

import { StyledButton } from './styles';

type Props = {
  disabled?: boolean;
};

export function UploadDraggerButton({ disabled = false }: Props) {
  return (
    <StyledButton
      htmlType="button"
      type="primary"
      icon={<UploadOutlined />}
      style={{ margin: '0 auto' }}
      disabled={disabled}
    >
      Выбрать файл
    </StyledButton>
  );
}
